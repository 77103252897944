<template>
    <cmpRec v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    { "key": "RoleId", "label": i18n.t('component.Privilege.RoleId.label') },
                    { "key": "ObjectName", "label": i18n.t('component.Privilege.ObjectName.label') },
                    { "key": "ObjectId", "label": i18n.t('component.Privilege.ObjectId.label') },
                    { "key": "RightCreate", "label": i18n.t('component.Privilege.RightCreate.label') },
                    { "key": "RightUpdate", "label": i18n.t('component.Privilege.RightUpdate.label') },
                    { "key": "RightDelete", "label": i18n.t('component.Privilege.RightDelete.label') },
                    { "key": "RightRead", "label": i18n.t('component.Privilege.RightRead.label') },
                    { "key": "RightImport", "label": i18n.t('component.Privilege.RightImport.label') },
                    { "key": "RightExport", "label": i18n.t('component.Privilege.RightExport.label') },
                    { "key": "RightPrint", "label": i18n.t('component.Privilege.RightPrint.label') }
                ]
            },
            header() {
                return i18n.t('component.Privilege.recHeader')
            }
        },
        data() {

            return {
                name: "Privilege",
                pkColumn: "PrivilegeId",
                parent: {}
            };
        }
    }
</script>