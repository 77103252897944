<template>
  <div style="text-align: center">
    <cmpLoading v-if="loading" v-bind:top="20"></cmpLoading>

    <div id="webpath">{{ $t("IOTUpload.Header.label") }}</div>

    <div>
      <table border="0" align="center" class="table2" style="width: 400px">
        <tr>
          <td align="left" valign="top">
            <span>
              <label for="DeviceId" class="col-form-label">{{
                $t("IOTUpload.DeviceId.label")
              }}</label>
            </span>
          </td>
          <td align="left">
            <cmpSelect v-bind:id="`device`" v-bind:options="deviceIdList" v-model="selectedDeviceId">
            </cmpSelect>
          </td>
        </tr>
        <tr>
          <td align="left" valign="top">
            <span>
              <label for="UploadStatus" class="col-form-label">{{
                $t("IOTUpload.UploadStatus.label")
              }}</label>
            </span>
          </td>
          <td align="left" valign="top">
            <div class="uploadContainer">
              <div class="scrollable">
                <div v-for="item in uploadContent" v-bind:key="item.fileName">
                  <span class="col-form-label">
                    {{ item.fileName }}
                  </span>
          <td align="left" v-if="item.progress != 100 && item.progress != -1">
            <span class="col-form-label">
              <progress id="UploadProgress" max="100" v-bind:value="item.progress"></progress>
            </span>
          </td>
    </div>
  </div>
  </div>
  </td>
  </tr>
  </table>

  <table border="0" align="center" class="table2">
    <tr>
      <td colspan="2" align="center">
        <br />
        <div class="btn9" @click.prevent="Upload">{{
            $t("IOTUpload.Upload.label")
          }}</div>
        <div class="btn9" @click.prevent="onCancel">{{
            $t("IOTUpload.GoBack.label")
          }}</div>
      </td>
    </tr>
  </table>
  </div>
  </div>
</template>



<script>

import cmpLoading from "@/components/common/cmpLoading";
import cmpSelect from "@/components/common/cmpSelect";
import { communicateMixin } from "@/communicateService";
import ApiService from "@/ApiService";
// server 端
//const api = new ApiService('UplodSererAPI');

var iotClient = undefined;

export default {
  data() {
    return {
      DeviceId: "",
      selectedDeviceId: "",
      deviceIdList: [],
      UploadStatus: "UploadStatus",
      uploadText: "",
      uploadContent: [],
      loading: false,
      showLogin: false,
    };
  },
  mixins: [communicateMixin],
  mounted() {
    this.getDeviceId();
  },
  components: {
    cmpLoading,
    cmpSelect,
  },
  methods: {
    messageProcessing(data) {
      console.log('received ', data);
      if (data.info != undefined && data.DeviceId == this.selectedDeviceId) {
        if (
          data.info != "" &&
          !this.uploadContent.some((item) => item.fileName == data.info)
        ) {
          this.uploadContent.push({
            fileName: data.info,
            progress: data.percent,
          });
        } else {
          this.uploadContent[this.uploadContent.length - 1].progress =
            data.percent;
        }
      }
    },
    async Upload() {
      let message = {
        CourseType: "UploadData",
        UploadPath: this.hostPath,
        DeviceId: this.selectedDeviceId,
      };
      this.publishMessage(this.selectedDeviceId, message);
      this.subscribeIOTChannel(this.selectedDeviceId);
    },
    async onCancel() {
      let routerName = this.$root.$data.store.getRouteName("loggedinHome");
      this.$router.push({ name: routerName });
    },
    async getDeviceId() {
      const api = new ApiService("deviceTables");
      const res = await api.getAll();
      const deviceIds = res.Data.map((item) => item.DeviceId);
      this.deviceIdList = deviceIds.map((deviceId) => ({
        value: deviceId,
        text: deviceId,
      }));
    },
  },
};
</script>