<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "CodeNo", "label": i18n.t('component.CodeDetail.CodeNo.label'), input: { type: "text" }, required: true },
                    { "key": "CodeDisplay", "label": i18n.t('component.CodeDetail.CodeDisplay.label'), input: { type: "text" }, required: true },
                    { "key": "Description", "label": i18n.t('component.CodeDetail.Description.label'), input: { type: "text" }, required: true }
                ]
            },
            header() {
                return i18n.t('component.CodeDetail.insHeader')
            }
        },
        data() {

            return {
                name: "CodeDetail",
                pkColumn: "CodeDetailId",
                parent: {
                    name: "Code",
                    key: "CodeId"
                }
            };
        }
    }
</script>