var render, staticRenderFns
import script from "./insProjectParticipant.vue?vue&type=script&lang=js&"
export * from "./insProjectParticipant.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./insProjectParticipant.vue?vue&type=custom&index=0&blockType=cmpCreate&ref=cmpCreate&v-bind%3Aname=name&v-bind%3ApkColumn=pkColumn&v-bind%3Afields=fields&v-bind%3Aparent=parent&v-bind%3Aheader=header&%40inputChanged=inputChanged"
if (typeof block0 === 'function') block0(component)

export default component.exports