plate>
    <cmpCreate ref="cmpCreate"
               v-bind:name="name"
               v-bind:pkColumn="pkColumn"
               v-bind:fields="fields"
               v-bind:parent="parent"
               v-bind:header="header"
               @inputChanged="inputChanged"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"
    import { codeName } from "@/util/constant"
    import { gmtToLocal } from '@/util/dateutil';

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "UserId", "label": i18n.t('component.ProjectParticipant.UserName.label'), input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", editDisplayField: "UserName", qryFields: ["Name"], displayFields: ["UserId", "Name"] } },
                    { "key": "ProjectRole", "label": i18n.t('component.ProjectParticipant.ProjectRoleName.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.ProjectRole } },
                    { "key": "ClassCode", "label": i18n.t('component.ProjectParticipant.ClassCode.label'), input: { type: "text" } },
                ]
            },
            header() {
                return i18n.t('component.ProjectParticipant.insHeader')
            }
        },
        data() {

            return {
                name: "ProjectParticipant",
                pkColumn: "ProjectParticipantId",
                parent: { "name": "Project", "key": "ProjectId" },
            };
        }
    }
</script>