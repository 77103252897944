<template>
    <cmpRec v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    { "key": "CourseName", "label": i18n.t('component.MotionData.CourseName.label')},
                    { "key": "MissionName", "label": i18n.t('component.MotionData.MissionName.label')},
                    { "key": "UserName", "label": i18n.t('component.MotionData.UserName.label') },
                    { "key": "DeviceId", "label": i18n.t('component.MotionData.DeviceId.label') },
                    { "key": "Memo", "label": i18n.t('component.MotionData.Memo.label'), input: { type: "textarea", style: "width:200px; height: 200px" } }
                ]
            },
            header() {
                return i18n.t('component.Mission.recHeader')
            }
        },
        data() {

            return {
                name: "MotionData",
                pkColumn: "MotionDataId",
                parent: { "name": "MotionData", "key": "ActivityId" },
            };
        }
    }
</script>