<template>
    <cmpTableWithMenu v-bind:name="name"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:header="header"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    { "key": "DeviceId", "label": i18n.t('component.DeviceTables.DeviceId.label'), sortable: true },
                    { "key": "HardwareDeviceId", "label": i18n.t('component.DeviceTables.HardwareDeviceId.label'), sortable: true },
                    { "key": "UnitId", "label": i18n.t('component.DeviceTables.UnitId.label'), sortable: true },                   
                ]
            },
            qryFields() {
                return [
                    { "key": "DeviceId", "label": i18n.t('component.DeviceTables.DeviceId.label'), input: { type: "text" } },
                    { "key": "HardwareDeviceId", "label": i18n.t('component.DeviceTables.HardwareDeviceId.label'), input: { type: "text" } },
                    { "key": "UnitId", "label": i18n.t('component.DeviceTables.UnitId.label'), input: { type: "text" } },
                ]
            }
            ,
            
            header() {
                return i18n.t('component.DeviceTables.lstHeader')
            }
            /*
            moreTableFuncs() {
                return [
                    {
                        key: "BtnCourseGoldenSampleFeature", icon: "IcnGo.png", tooltip: i18n.t('component.Course.ToolTip.GoldenSample'), function: { type: 'route', name: "lstGoldenSampleFeature", key: "CourseId" }
                    },
                    {
                        key: "BtnCourseMission", icon: "IcnCourse.png", tooltip: i18n.t('component.Course.ToolTip.Mission'), function: { type: 'route', name: "lstMission", key: "CourseId" }
                    },
                ]
            }*/
        },
        data() {

            return {
                name: "DeviceTables",
                pkColumn: "DeviceTableId",
                children: {},
                parent: {},
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete']
            };
        }
    }
</script>