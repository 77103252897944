
import axios from 'axios'

export default class {
    constructor(baseUrl) {
        this.client = axios.create({
            baseURL: baseUrl,
            json: true
        })
    }
    async execute(method, resource, data, headers) {
        
        if (method == "get") {
            return this.client({
                method,
                url: resource,
                params: data,
                headers: headers,
                timeout: 60000
            }).then(req => {
                return req
            })
        }
        else {
            return this.client({
                method,
                url: resource,
                data,
                headers: headers,
                timeout: 60000
            }).then(req => {
                return req
            })
        }
    }

    get(data, subPath) {
        var requestData = data ? data : {};

        if (subPath) {
            return this.execute('get', subPath, requestData)
        }
        else {
            return this.execute('get', '/', requestData)
        }
    }

    getWithHeader(data, subPath, headers) {
        var requestData = data ? data : {};

        if (subPath) {
            return this.execute('get', subPath, requestData, headers)
        }
        else {
            return this.execute('get', '/', requestData, headers)
        }
    }

    post(data, subPath) {
        if (subPath) {
            return this.execute('post', subPath, JSON.stringify(data), { 'Content-Type': 'application/json' })
        }
        else {
            return this.execute('post', '/', JSON.stringify(data), { 'Content-Type': 'application/json' })
        }
    }
    postWithHeader(data, subPath,headers) {
        if (subPath) {
            return this.execute('post', subPath, JSON.stringify(data), headers)
        }
        else {
            return this.execute('post', '/', JSON.stringify(data), headers)
        }
    }
}