<template>
    <cmpCreate v-bind:name="name"
               v-bind:pkColumn="pkColumn"
               v-bind:fields="fields"
               v-bind:parent="parent"
               v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"
    import ApiService from '@/ApiService';

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "MissionCode", "label": i18n.t('component.Mission.MissionCode.label'), input: { type: "text" } },
                    { "key": "MissionName", "label": i18n.t('component.Mission.MissionName.label'), input: { type: "text" }, required: true },
                    { "key": "MissionParameter", "label": i18n.t('component.Mission.MissionParameter.label'), input: { type: "form", fields: this.missionParameterFields } }
                ]
            },
            header() {
                return i18n.t('component.Mission.insHeader')
            },
            parent() {
                return {
                    "name": "Course",
                    "key": "CourseId",
                    "dataHeaders": [
                        { "key": "CourseName", "label": i18n.t('component.Course.CourseName.label') }
                    ]
                }
            }
        },
        data() {

            return {
                name: "Mission",
                pkColumn: "MissionId",
                missionParameterFields: [{ "key": "Mode", "label": "Mode", "input": { "type": "select", "content": ["Formal", "Teaching"] } }, { "key": "Game", "label": "Game", "input": { "type": "select", "content": ["CPT", "Audio", "WCST", "STROOP"] } }]
            };
        },
        async created() {
            let course = await this.getCourseParameter(this.$route.query.CourseId);
            let courseParameter = JSON.parse(course.CourseParameter);

            this.missionParameterFields = courseParameter.Parameter;

        },
        methods: {
            async getCourseParameter(courseId) {
                const apiCourse = new ApiService("Course");
                let course = await apiCourse.get(courseId, i18n.locale);

                return course;
            },
        }
    }
</script>