<template>
    <div style="width: 80%; margin: 0 auto">
        <div style="text-align: center">
            <div id="webpath">{{ header }}</div>
            <cmpLoading v-if="loading"></cmpLoading>

            <div class="mb-1">
                <cmpMenuButton v-if="showAddButton" v-bind:label="$t('form.deviceMapping')"
                    v-bind:imagePath="'assets/icons/Icniotmapping.png'" v-bind:hasPrivilege="this.privileges['Create']"
                    @click="$router.push(getInsActivityParticipantRouterLink())">                    
                </cmpMenuButton>

                <!-- <cmpMenuButton v-if="showAddButton" v-bind:label="$t('form.sensorImpedance')"
                               v-bind:imagePath="'assets/icons/IcnSensorImpedance.png'"
                               v-bind:hasPrivilege="this.privileges['Create']"
                               @click="$router.push(getSensorImpedanceRouterLink(data.item))">
                </cmpMenuButton> -->

                <cmpMenuButton v-bind:label="$t('form.search')" v-bind:imagePath="'assets/icons/IcnSearch.png'"
                    v-bind:hasPrivilege="this.privileges['Read']" v-bind:dataToggle="'collapse'"
                    v-bind:dataTarget="'#content_search_1'" @click="print()">
                </cmpMenuButton>

                <cmpMenuButton v-bind:label="$t('form.print')" v-bind:imagePath="'assets/icons/IcnPrint.png'"
                    v-bind:hasPrivilege="this.privileges['Print']" @click="print()">
                </cmpMenuButton>

                <cmpMenuButton v-bind:label="$t('form.import')" v-bind:imagePath="'assets/icons/IcnUp.png'"
                    v-bind:hasPrivilege="this.privileges['Import']" @click="$refs.uploadFileComponent.show()">
                </cmpMenuButton>

                <cmpMenuButton v-bind:label="$t('form.export')" v-bind:imagePath="'assets/icons/IcnDown.png'"
                    v-bind:hasPrivilege="this.privileges['Export']" @click="exportRecords()">
                </cmpMenuButton>
            </div>

            <div class="collapse" id="content_search_1">
                <cmpSearch @submit="searchSubmit" @cancel="searchCancel" v-bind:qryFields="qryFields">
                </cmpSearch>
            </div>
        </div>

        <!-- Modal -->
        <cmpUploadFile ref="uploadFileComponent" @submit="importRecords">
        </cmpUploadFile>

        <br />

        <br />

        {{ $t("form.autoSave") }}：<toggle-button v-model="autoSave" v-bind:sync="true" v-bind:labels="true" />

        <table class="top-table" v-if="hasActivity()">
            <tr>
                <td style="width: 100px">
                    {{ $t("component.MotionData.ProjectType.label") }}
                </td>
                <td style="width: 120px">
                    {{ $t("component.MotionData.ActivityTypeName.label") }}
                </td>
                <td style="width: 120px">
                    {{ $t("component.MotionData.ProjectStageName.label") }}
                </td>
                <td style="width: 150px">
                    {{ $t("component.MotionData.ActivityTitle.label") }}
                </td>
                <td>{{ $t("component.MotionData.ActivityDate.label") }}</td>
            </tr>
            <tr>
                <td>{{ activityModel.ClassName }}</td>
                <td>{{ activityModel.ActivityTypeName }}</td>
                <td>{{ activityModel.ProjectStageName }}</td>
                <td>{{ activityModel.ActivityTitle }}</td>
                <td>
                    {{ formatDate(activityModel.ActivityStartDate) }} ~{{
                        formatDate(activityModel.ActivityEndDate)
                    }}
                </td>
            </tr>
        </table>
        <br />

        <table class="top-table">
            <tr>
                <td>{{ $t("component.MotionData.UserName.label") }}：</td>
                <td>
                    <cmpSelect v-model="filterModel['UserId']" v-bind:options="formBind['UserId']" v-bind:isMultiple="false"
                        @input="selectChanged()">
                    </cmpSelect>
                </td>
            </tr>
            <tr>
                <td>{{ $t("component.MotionData.MissionName.label") }}：</td>
                <td>
                    <cmpSelect v-model="filterModel['MissionId']" v-bind:options="formBind['MissionId']"
                        v-bind:isMultiple="false" v-bind:nullVaule="missionSelectNullValue" @input="selectChanged()">
                    </cmpSelect>
                </td>
            </tr>
        </table>

        <br /><br />
        <b-table id="tblRecords" :items="tableRecords" :fields="fields" :name="name" small sort-icon-left
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" thead-class="align-top"
            tbody-tr-class="align-top" ref="table">
            <template v-for="field in fields" v-slot:[`cell(${field.key})`]="data">
                <div v-if="field.key === 'Operation'" v-bind:key="field.key" style="min-width: 200px">
                    <cmpMenuButton v-if="tableFuncs.includes('copyNew')" v-bind:label="$t('form.copyNew')"
                        v-bind:imagePath="'assets/icons/IcnCopyNew.png'"
                        v-bind:hasPrivilege="privileges && privileges['Create']" @click="
                            $router.push(getCopyNewRouterLink(name, data.item[pkColumn]))
                            ">
                    </cmpMenuButton>

                    <cmpMenuButton v-if="tableFuncs.includes('rec')" v-bind:label="$t('form.inspect')"
                        v-bind:imagePath="'assets/icons/IcnGresses.png'"
                        v-bind:hasPrivilege="privileges && privileges['Read']"
                        @click="$router.push(getRecRouterLink(name, data.item[pkColumn]))">
                    </cmpMenuButton>

                    <cmpMenuButton v-if="tableFuncs.includes('edit')" v-bind:label="$t('form.modify')"
                        v-bind:imagePath="'assets/icons/IcnEdit.png'"
                        v-bind:hasPrivilege="privileges && privileges['Update']"
                        @click="$router.push(getUpdRouterLink(name, data.item[pkColumn]))">
                    </cmpMenuButton>

                    <cmpMenuButton v-if="tableFuncs.includes('delete')" v-bind:imagePath="'assets/icons/IcnTrash.png'"
                        v-bind:hasPrivilege="privileges && privileges['Delete']" v-bind:label="$t('form.delete')"
                        @click="deleteRecord(data.item[pkColumn])">
                    </cmpMenuButton>

                    <div v-if="moreTableFuncs && moreTableFuncs.length > 0" style="display: inline; position: relative">
                        <img v-bind:id="`flip${data.index}`" src="@/assets/icons/IcnMore.png"
                            @click="toggleMenu(`moreedit${data.index}`)" />
                        <div v-bind:id="`moreedit${data.index}`" class="moreedit">
                            <div v-for="child in moreTableFuncs" :key="child.icon + Math.random().toString(16).slice(2)"
                                class="tooltip_right">
                                <a style="cursor: pointer" @click="
                                    moreFuncClick(
                                        child.function,
                                        data.item[child.function.key],
                                        data.item
                                    )
                                    "><img :src="require(`@/assets/icons/${child.icon}`)" /></a>
                                <span class="tooltiptext_right">{{ child.tooltip }}</span>
                            </div>
                            <br /><br />
                        </div>
                    </div>
                </div>
                <div v-else-if="field.key === 'DeviceToggle'" v-bind:key="field.key">
                    <toggle-button v-model="data.item.DeviceToggle" @change="onDeviceToggle(data.item)"
                        v-bind:color="data.item.deviceColor" v-bind:disabled="data.item.ToggleButtonDisabled"
                        v-bind:sync="true" v-bind:labels="true" />
                </div>

                <div v-else-if="field.key === 'DeviceManage'" v-bind:key="field.key">
                    <div class="btn10"
                         @click="onDeviceManage(data.item)">
                        {{ $t("component.MotionData.DeviceStart.label") }}
                    </div>

                </div>
                <div v-else-if="field.key === 'DeleteBtn'" v-bind:key="field.key">
                    <toggle-button v-model="data.item.DeleteBtn" @change="onDelete(data.item)"
                        v-bind:color="data.item.deviceColor" v-bind:disabled="data.item.ToggleButtonDisabled"
                        v-bind:sync="true" v-bind:labels="true" />
                </div>
                <div v-else-if="field.key === 'Score'" v-bind:key="field.key">
                    <input type="text" v-model="data.item.Score" v-on:blur="updateMotionData(data.item)"
                        style="width: 50px" />
                </div>
                <div v-else-if="field.key === 'Memo'" v-bind:key="field.key">
                    {{ data.item.Memo }}
                    <!-- <textarea v-model="data.item.Memo" v-on:blur="updateMotionData(data.item)" style="height:18px; width:55px" /> -->
                </div>
                <div v-else-if="field.type === 'date'" v-bind:key="field.key">
                    {{ formatDate(data.value) }}
                </div>
                <div v-else-if="field.type === 'datetime'" v-bind:key="field.key">
                    {{ formatDate(data.value) }}
                </div>
                <div v-else-if="field.type === 'checkbox'" v-bind:key="field.key">
                    <input type="checkbox" v-bind:checked="data.value" onclick="return false;" />
                </div>
                <div v-else-if="field.type === 'MotionDataId'" v-bind:key="field.key">
                    {{ data.item.MotionDataId }}
                </div>
                <div v-else v-bind:key="field.key">
                    {{ data.value }}
                </div>
            </template>
        </b-table>

        <div align="center">
            <a href="#" @click.prevent="pageChangeHandler(1)"><img src="@/assets/icons/IcnPage1.png" /></a>
            <a href="#" @click.prevent="pageChangeHandler(currentPage - 1)"><img src="@/assets/icons/IcnPage2.png" /></a>
            <a href="#" @click.prevent="pageChangeHandler(currentPage + 1)"><img src="@/assets/icons/IcnPage3.png" /></a>
            <a href="#" @click.prevent="pageChangeHandler(totalPages)"><img src="@/assets/icons/IcnPage4.png" /></a>
            <br />
            <span>
                {{
                    $t("form.numberOfRecords")
                        .replace("@num@", numberOfRecords)
                        .replace("@maxnum@", numberOfRecords)
                }}
            </span>
        </div>

        <br /><br />

        <div style="text-align: center; margin: 0 auto">
            <div class="btn9" @click.prevent="submitRecord">
                ✔ {{ $t("form.submit") }}
            </div>
            <div class="btn9" v-if="parent && Object.keys(parent).length > 0">
                <router-link :to="getCancelRouterLink()">✘ {{ $t("form.goBack") }}</router-link>
            </div>
        </div>

    </div>
</template>

<style>
.top-table tr td {
    padding: 3px;
    vertical-align: top;
}
</style>

<script>
import i18n from "@/i18n";
import ApiService from "@/ApiService";
import cmpSearch from "@/components/common/cmpSearch";
import cmpUploadFile from "@/components/common/cmpUploadFile";
import cmpMenuButton from "@/components/common/cmpMenuButton";
import cmpLoading from "@/components/common/cmpLoading";
import cmpSelect from "@/components/common/cmpSelect";
import cookieManager from "@/util/cookieManager";
import { formatDatetime, gmtToLocal } from "@/util/dateutil";
import { communicateMixin } from "@/communicateService";


import $ from "jquery";
var api = undefined;
const apiActivity = new ApiService("Activity");

var cm = new cookieManager();


export default {
    components: {
        cmpSearch,
        cmpUploadFile,
        cmpMenuButton,
        cmpLoading,
        cmpSelect,
    },
    computed: {
        fields() {
            return [
                { key: "Operation", label: "" },
                { key: "No", label: "No." },
                {
                    key: "MotionDataId",
                    label: i18n.t("component.MotionData.MotionDataId.label"),
                },
                {
                    key: "UserName",
                    label: i18n.t("component.MotionData.UserName.label"),
                    sortable: true,
                },
                {
                    key: "DeviceId",
                    label: i18n.t("component.MotionData.DeviceId.label"),
                    sortable: true,
                },
                // {
                //     key: "ClassName",
                //     label: i18n.t("component.MotionData.ClassName.label"),
                //     sortable: true,
                // },
                {
                    key: "MissionName",
                    label: i18n.t("component.MotionData.MissionName.label"),
                    sortable: true,
                },
                {
                    key: "DeviceManage",
                    label: i18n.t("component.MotionData.DeviceManage.label"),
                    sortable: false,
                },
                {
                    key: "DeviceToggle",
                    label: i18n.t("component.MotionData.DeviceToggle.label"),
                    sortable: false,
                },
                {
                    key: "DeleteBtn",
                    label: i18n.t("component.MotionData.DeleteBtn.label"),
                    sortable: false,
                },
                // {
                //     key: "DeviceStatus",
                //     label: i18n.t("component.MotionData.DeviceStatus.label"),
                //     sortable: false,
                // },
                {
                    key: "Memo",
                    label: i18n.t("component.MotionData.Memo.label"),
                    sortable: true,
                },
            ];
        },
        qryFields() {
            return [
                {
                    key: "UserName",
                    label: i18n.t("component.MotionData.UserName.label"),
                    input: { type: "text" },
                },
                {
                    key: "ActivityStartDate",
                    label: i18n.t("component.MotionData.ActivityStartDate.label"),
                    input: { type: "datetime" },
                },
            ];
        },
        header() {
            return i18n.t("component.MotionData.insHeader");
        },
    },
    data() {
        return {
            name: "MotionData",
            pkColumn: "MotionDataId",
            tableFuncs: ["rec", "copyNew", "edit", "delete"],
            moreTableFuncs: [],
            parent: { name: "Activity", key: "ActivityId" },
            showAddButton: true,
            loading: false,
            records: [],
            tableRecords: [],
            pageSize: 0,
            totalPages: 0,
            currentPage: 1,
            numberOfRecords: 0,
            qryModel: {},
            sortBy: "",
            sortDesc: false,
            privileges: {},
            cookieQueryFields: [],
            activityModel: {},
            filterModel: {},
            formBind: {},
            missionSelectNullValue: i18n.t("form.pleaseSelect"),
            autoSave: true, // 自動储存
            isEnableClass: false, // 啟動課程
            timerList: {}, // 储存 每個DeviceId 的 timeout. DeviceId : setTimeout
            debugMode: true,

            // 後端紀錄ActivityLog代碼
            ActivityLog: {
                ActivityLogStage: {
                    CourseStart: "01-CourseStart",
                    SensorDataDeleted: "04-SensorDataDeleted"
                },
                ActivityLogStatus: {
                    FAIL: "0-Fail",
                    PASS: "1-Success",
                    NULL: "_-Null"
                }
            }
        };
    },
    mixins: [communicateMixin],

    async created() {
        //this.debugMode = process.env.VUE_APP_DEBUG_MODE == "true" ? true : false
        api = new ApiService(this.name);
        this.qryModel = JSON.parse(JSON.stringify(this.$route.query));

        // Get privilege
        const p = this.$root.$data.store.getPrivilege(this.name);

        if (p && p.length > 0) {
            this.privileges["Create"] = p[0]["Create"];
            this.privileges["Delete"] = p[0]["Delete"];
            this.privileges["Export"] = p[0]["Export"];
            this.privileges["Import"] = p[0]["Import"];
            this.privileges["Print"] = p[0]["Print"];
            this.privileges["Read"] = p[0]["Read"];
            this.privileges["Update"] = p[0]["Update"];
        }
        this.getAll();
    },
    methods: {
        messageProcessing(data) { },
        async importRecords(formData) {
            await api.uploadFile(formData);
            this.$refs.uploadFileComponent.cancel();
        },
        exportRecords() {
            //this.qryModel = JSON.parse(JSON.stringify(this.$route.query))
            api.exportExcel(JSON.parse(JSON.stringify(this.qryModel)));
        },
        getInsRouterLink(name) {
            var routerName = this.$root.$data.store.getRouteName(name, "ins");

            var result = { name: routerName, query: { action: "C" } };
            if (this.parent && Object.keys(this.parent).length > 0) {
                result["query"][this.parent.key] = this.qryModel[this.parent.key];
            }

            return result;
        },
        searchSubmit(queryModel) {
            // 存Parent ID
            //if (this.parent && this.parent.key in this.$route.query) {
            //    queryModel[this.parent.key] = this.$route.query[this.parent.key]
            //}
            //var routerName = this.$root.$data.store.getRouteName(this.name, "lst");
            var result = { name: this.$route.name };
            this.qryModel = queryModel;
            result["query"] = this.qryModel;
            this.$router.push(result).catch(() => { });
        },
        searchCancel() {
            $("#content_search_1").collapse("hide");
        },
        async getAll() {
            this.qryModel = JSON.parse(JSON.stringify(this.$route.query));
            var sort = "";
            if (this.sortBy) {
                sort += this.sortBy;
                if (this.sortDesc) {
                    sort += " desc";
                } else {
                    sort += " asc";
                }
            }

            if (this.query) {
                for (const key in this.query) {
                    const queryValue = this.query[key];

                    if (queryValue instanceof Array) {
                        this.qryModel[key] = this.query[key].join(",");
                    } else {
                        this.qryModel[key] = this.query[key];
                    }
                }
            }

            // 看要不要從 cookie 加搜尋條件
            for (let i = 0; i < this.cookieQueryFields.length; i++) {
                let cookieKey = this.cookieQueryFields[i];

                // 搜尋條件己經有了就不用再加了
                if (!(cookieKey in this.qryModel)) {
                    let cookieValue = this.$cookies.get(cookieKey);

                    if (cookieValue) {
                        this.qryModel[cookieKey] = cookieValue;
                    }
                }
            }

            this.qryModel["Sort"] = sort;
            this.qryModel["MaxResultCount"] = -1;
            this.loading = true;

            try {
                var res = await api.getAll(JSON.parse(JSON.stringify(this.qryModel)));
                this.records = res["Data"];
                this.pageSize = res["PageSize"];
                this.totalPages = res["TotalPages"];
                this.numberOfRecords = res["NumberOfRecords"];
                this.currentPage = res["PageNumber"];
                this.tableRecords = this.records;
                

                for (let r of this.records) {
                    r.deviceColor = "#75C791";
                }

                // 編號
                for (let i = 0; i < this.records.length; i++) {
                    this.records[i]["No"] = i + 1 + (this.currentPage - 1) * 10;
                    
                    this.records[i].DeleteBtn = this.GetMotionStatus(
                        this.records[i],
                        "D"
                    );
                    this.records[i].DeviceToggle = this.GetMotionStatus(
                        this.records[i],
                        "L"
                    );
                }

                // Get Activity
                if (this.$route.query.ActivityId) {
                    const activityId = this.$route.query.ActivityId;
                    this.activityModel = await apiActivity.get(activityId, i18n.locale);
                }

                // Get UserIds
                let userIds = this.records.map(function (row) {
                    return {
                        value: row["UserId"],
                        text: `${row["UserId"]} ${row["UserName"]}`,
                    };
                });

                // 把重複的資料移除
                var uniqueUserIds = userIds.reduce((unique, row) => {
                    if (
                        row["value"] &&
                        !unique.some((obj) => obj["value"] === row["value"])
                    ) {
                        unique.push({
                            value: row["value"],
                            text: row["text"],
                        });
                    }
                    return unique;
                }, []);

                this.$set(this.formBind, "UserId", uniqueUserIds);

                // Get Missions
                let uniqueMissions = [];
                let missions = this.records.map(function (row) {
                    return {
                        value: row["MissionId"],
                        text: row["MissionName"],
                    };
                });

                // 把重複的資料移除
                uniqueMissions = missions.reduce((unique, row) => {
                    if (
                        row["value"] &&
                        !unique.some((obj) => obj["value"] === row["value"])
                    ) {
                        unique.push({
                            value: row["value"],
                            text: row["text"],
                        });
                    }

                    return unique;
                }, []);

                if (uniqueMissions.length == 0) {
                    this.missionSelectNullValue = "   ";
                }
                this.$set(this.formBind, "MissionId", uniqueMissions);
            } finally {
                this.loading = false;
            }
        },
        async deleteRecord(id) {
            if (confirm(i18n.t("form.confirmDelete") + "?")) {
                await api.delete(id);
                await this.getAll();
            }
        },
        getCopyNewRouterLink(name, id) {
            var routerName = this.$root.$data.store.getRouteName("MotionData", "ins");
            var result = { name: routerName, query: { id: id, action: "C" } };

            if (this.parent && Object.keys(this.parent).length > 0) {
                result["query"][this.parent.key] = this.qryModel[this.parent.key];
            }
            return result;
        },
        getUpdRouterLink(name, id) {
            var routerName = this.$root.$data.store.getRouteName("MotionData", "ins");
            var result = { name: routerName, query: { id: id, action: "U" } };
            if (this.parent && Object.keys(this.parent).length > 0) {
                result["query"][this.parent.key] = this.qryModel[this.parent.key];
            }
            return result;
        },
        getRecRouterLink(name, id) {
            var routerName = this.$root.$data.store.getRouteName("MotionData", "rec");
            var result = { name: routerName, query: { id: id } };
            if (this.parent && Object.keys(this.parent).length > 0) {
                result["query"][this.parent.key] = this.qryModel[this.parent.key];
            }

            return result;
        },
        getInsActivityParticipantRouterLink() {
            var routerName = this.$root.$data.store.getRouteName(
                "ActivityParticipant",
                "ins",
                "Multi"
            );
            var result = { name: routerName, query: { action: "C" } };

            if (this.qryModel["ActivityId"])
                result["query"]["ActivityId"] = this.qryModel["ActivityId"];

            return result;
        },
        getSensorImpedanceRouterLink(item) {
            var routerName = this.$root.$data.store.getRouteName(
                "SensorImpedance",
                "lst"
            );
            var result = { name: routerName, query: {} };

            result["query"]["DeviceId"] = item.DeviceId;
            result["query"]["ActivityId"] = this.qryModel["ActivityId"];
            result["query"]["CourseId"] = item.CourseId;
            return result;
        },
        getCancelRouterLink() {
            var routerName = this.$root.$data.store.getRouteName(
                this.parent.name,
                "lst"
            );
            var result = { name: routerName };
            result["query"] = {};

            if (this.parent.backIncludeQuery) {
                for (const queryKey of this.parent.backIncludeQuery) {
                    result["query"][queryKey] = this.qryModel[queryKey];
                }
            }

            let cookieQuery = cm.getRouteQuery(routerName);
            ////console.log('cookieQuery', cookieQuery);
            if (cookieQuery) {
                for (const key in cookieQuery) {
                    //console.log(key, cookieQuery[key])

                    if (cookieQuery[key]) {
                        result["query"][key] = cookieQuery[key];
                    }
                }
            }

            return result;
        },
        moreFuncClick(funcInfo, id, rowItem) {
            if (funcInfo["type"] == "route") {
                // 要把 Key 存到 cookie 裡
                if (funcInfo["cookie"]) {
                    this.$cookies.set(funcInfo["key"], id);
                }

                var routerName = this.$root.$data.store.getRouteName(funcInfo["name"]);
                var queryString = { [`${funcInfo["key"]}`]: id };
                // 要加到 queryString 裡的
                if (funcInfo.queryString) {
                    for (const qsKey of funcInfo.queryString) {
                        if (rowItem[qsKey]) {
                            queryString[qsKey] = rowItem[qsKey];
                        }
                    }
                }

                var result = { name: routerName };
                result["query"] = queryString;

                this.$router.push(result);
            } else if (funcInfo["type"] == "callback") {
                // Find the record and pass it back
                var record = this.records.filter((x) => x[this.pkColumn] == id);

                if (record.length > 0) {
                    this.$emit(funcInfo["name"], id, record[0]);

                    this.$emit("callback", funcInfo["name"], id, record[0]);
                }
            }
        },
        pageChangeHandler(newPage) {
            if (newPage < 1) return;
            if (newPage > this.totalPages) return;

            var result = { name: this.$route.name };
            this.qryModel["PageNumber"] = newPage;
            result["query"] = this.qryModel;

            this.$router.push(result, () => { });
        },
        toggleMenu(item) {
            $("#" + item).slideToggle("slow");
        },
        async updateMotionData(item) {
            // 看要不要自動儲存
            if (this.autoSave) {
                let request = [
                    {
                        MotionDataId: item.MotionDataId,
                        MotionStatus: item.MotionStatus,
                        Memo: item.Memo,
                        TestingTime: item.TestingTime,
                    },
                ];

                await api.post(request, "score");

                item.isDirty = false;
            } else {
                // Mark dirty, 送出時要儲存
                item.isDirty = true;
            }
        },
        // 手動儲存
        async submitRecord() {
            // 修改過的 row 要儲存
            let request = [];

            for (const item of this.records) {
                if (item.isDirty) {
                    request.push({
                        MotionDataId: item.MotionDataId,
                        Memo: item.Memo,
                        TestingTime: item.TestingTime,
                    });
                }
            }

            await api.post(request, "score");

            for (const item of this.records) {
                item.isDirty = false;
            }

            alert(i18n.t("form.success"));
        },
        selectChanged() {
            this.tableRecords = this.records;

            if (this.filterModel["UserId"]) {
                this.tableRecords = this.records.filter(
                    (x) => x["UserId"] == this.filterModel["UserId"]
                );
            }

            if (this.filterModel["MissionId"]) {
                this.tableRecords = this.tableRecords.filter(
                    (x) => x["MissionId"] == this.filterModel["MissionId"]
                );
            }
        },
        // 啟動套號 - 活動
        async enableClass() {
            console.log("enableClass");
        },
        async onDeviceToggle(item) {
            this.SetMotionStatus(item, "L", item.DeviceToggle);
            this.updateMotionData(item);
            if (item.DeviceToggle == true) {
                let message = {
                    userId: item.UserMemberId,
                    userName: item.UserName,
                    CourseType: item.CourseType,
                    CourseParams: item.MissionParameter,
                    DeviceId: item.DeviceId,
                    MotiondataId: item.MotionDataId.toString(),
                };
                this.publishMessage(item.DeviceId, message);

                this.createActivityLog(item, this.ActivityLog.ActivityLogStage.CourseStart, this.ActivityLog.ActivityLogStatus.NULL);
            }
             
            this.$refs.table.refresh();
        },
        async onDelete(item) {
            if (item.DeleteBtn == true) {
                if (confirm(i18n.t("form.confirmDelete") + "?")) {
                    let message = {
                        CourseType: "DeleteSpecificData",
                        DeviceId: item.DeviceId,
                        MotiondataId: item.MotionDataId.toString(),
                    };
                    this.publishMessage(item.DeviceId, message);
                    //item.DeleteBtn = true
                } else {
                    item.DeleteBtn = false;
                }
                
                this.createActivityLog(item, this.ActivityLog.ActivityLogStage.SensorDataDeleted, this.ActivityLog.ActivityLogStatus.NULL);
            }
            this.SetMotionStatus(item, "D", item.DeleteBtn);
            this.updateMotionData(item);
            this.$refs.table.refresh();
        },


        async onDeviceManage(item) {                     
            this.$router.push(this.getSensorImpedanceRouterLink(item));            
            this.$refs.table.refresh();
        },
        GetMotionStatus(item, btn) {
            if (item.MotionStatus == null) return false;
            return item.MotionStatus.indexOf(btn) > -1 ? true : false;
        },
        SetMotionStatus(item, btn, val) {
            var btnlist = "LD";
            if (item.MotionStatus == null) item.MotionStatus = "__";
            var pos = btnlist.indexOf(btn);
            var char = val ? btn : "_";
            item.MotionStatus =
                (pos > 0 ? item.MotionStatus.substring(0, pos) : "") +
                char +
                (pos < item.MotionStatus.length - 1
                    ? item.MotionStatus.substring(pos + 1)
                    : "");
        },
        formatDate(d) {
            const localDate = gmtToLocal(d);
            if (localDate) {
                return localDate.replace("T", " ");
            }
            return null;
        },
        hasActivity() {
            if (this.$route.query["ActivityId"]) return true;

            return false;
        },
        async createActivityLog(item, Stage, Status) {
            //Stage:階段
            //StageType:分類,vue暫時用不到
            //Status:狀態
            const apiGet = new ApiService("ActivityLog");
            let request = {                
                MotionDataId: item.MotionDataId,
                ActivityLogStage: Stage,
                ActivityLogStatus: Status
            };

            let jsonrequest = JSON.parse(JSON.stringify(request));            
            await apiGet.create(jsonrequest);                        
        },
        gmtToLocal,
        formatDatetime,
        
    },
    watch: {
        "$route.params": "getAll",
        sortBy: "getAll",
        sortDesc: "getAll",
    },
};
</script>