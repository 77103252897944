<template>
    <div>
        <div id="webpath">{{header}}{{action=='C' ? $t('form.add') : $t('form.modify') }}</div>
        <cmpLoading v-if="loading"></cmpLoading>

        <div>
            <table border="0" align="center" class="table2">
                <tr v-for="f in fields" :key="f.key">
                    <td align="left">
                        <cmpSelect v-if="f.input.type == 'select'" v-model="model[f.key]"
                                   v-bind:options="formBind[f.key]"
                                   v-bind:isMultiple="false"
                                   v-bind:moreOptions="f.input.moreOptions">
                        </cmpSelect>


                        <cmpSelect v-else-if="f.input.type == 'selectmulti'" v-model="model[f.key]"
                                   v-bind:options="formBind[f.key]"
                                   v-bind:isMultiple="true">
                        </cmpSelect>

                        <b-form-radio-group v-else-if="f.input.type == 'radio'"
                                            v-model="model[f.key]"
                                            :options="formBind[f.key]"
                                            :name="f.key">
                        </b-form-radio-group>

                    </td>
                </tr>
            </table>

            <table border="0" align="center" class="table2">
                <tr>
                    <td colspan="2" align="center">
                        <br />
                        <div class="btn9" @click.prevent="createRecord">✔ {{ $t('form.submit') }}</div>
                        <div class="btn9" @click.prevent="onCancel">✘ {{ $t('form.goBack') }}</div>
                    </td>
                </tr>
            </table>
        </div>

    </div>
</template>

<script>
    import i18n from '@/i18n'
    import cmpSelect from "@/components/common/cmpSelect"
    import cmpLoading from "@/components/common/cmpLoading"
    import ApiService from '@/ApiService';
    import { gmtToLocal, localToGmt } from '@/util/dateutil';

    var api = undefined

    export default {
        components: {
            cmpSelect,
            cmpLoading
        },
        data() {
            return {
                loading: false,
                model: {},
                action: '', // C=新增, U=修改
                formBind: {},
                errorMessage: {}
            };
        },
        props: {
            name: String,
            pkColumn: String,
            fields: { type: Array, default: () => [] },
            parent: Object,
            header: String,
            query: Object,
            collapseFields: { type: Array, default: () => [] }
        },
        async created() {
            api = new ApiService(this.name)
            this.model.id = this.$route.query.id
            this.action = this.$route.query.action

            // 有 ID, 從資料庫讀這筆資料
            if (this.model.id) {
                await this.get(this.model.id);
            }
            // 抓現在登入 user 的資料
            else if (this.query && this.query['current']) {
                await this.getCurrent();

                if (this.query.action) {
                    this.action = this.query.action;
                }
            }
            // 抓parent 的資料
            else if (this.parent && this.parent.key in this.$route.query) {
                this.model[this.parent.key] = this.$route.query[this.parent.key];
            }

            for (let f of this.$props.fields) {
                // 這些 input type 需要從資料庫讀取他們的選項
                if (['select', 'radio', 'checkbox', 'selectmulti'].includes(f.input.type)) {
                    var res = await this.getSelectOptions(f.input.objectName, f.input.idField, f.input.displayField, f.input.filterField, f.input.filterValue)

                    this.$set(this.formBind, f.key, res)

                    if (f.input.type == 'checkbox') {
                        if (!this.model[f.key]) {
                            this.model[f.key] = []
                        } else {
                            this.model[f.key] = this.model[f.key].split(',')
                        }
                    }

                }
                else if (['readonly'].includes(f.input.type)) {

                    // 新增的話要把 readonly 的值讀出來
                    if (!this.isUpdate && this.$route.query[f.input.idField]) {
                        let rApi = new ApiService(f.input.objectName);
                        let response = await rApi.get(this.$route.query[f.input.idField], i18n.locale);

                        this.$set(this.model, f.key, response[f.input.displayField]);
                        this.$set(this.model, f.input.modelField, response[f.input.responseField]);
                    }
                }
                else if (['datetime'].includes(f.input.type)) {
                    let localDateString = gmtToLocal(this.model[f.key]);

                    this.$set(this.model, f.key, localDateString);

                }
                else if (['date'].includes(f.input.type)) {
                    if (this.model[f.key]) {
                        let localDateString = gmtToLocal(this.model[f.key]);
                        localDateString = localDateString.split('T')[0];
                        this.$set(this.model, f.key, localDateString);
                    }
                }
                else if (['textboxSearch'].includes(f.input.type)) {
                    if (f.default && !this.isUpdate) {
                        if (f.default.type == "currentUser") {
                            const profile = this.$root.$data.store.getProfile();
                            this.model[f.key] = profile.UserId;
                            this.model[f.input.idField] = profile.UserId;
                            this.model[f.input.editDisplayField] = profile.Name;

                        }
                    }
                }
            }

        },
        methods: {
            async get(id) {
                this.loading = true

                try {
                    this.model = await api.get(id, i18n.locale)
                    this.model['id'] = id;

                } finally {
                    this.loading = false
                }
            },
            async getCurrent() {
                this.loading = true

                try {
                    this.model = await api.getCurrent()
                    this.model['id'] = this.model[this.pkColumn];

                } finally {
                    this.loading = false
                }
            },
            async createRecord(event) {

                // 驗證 form
                if (!this.validate())
                    return;

                event.target.classList.add('disabled-link');

                // 有沒有上傳的 module, 如果有, 要 post form

                let hasFileControl = false;
                let requestData = JSON.parse(JSON.stringify(this.model));

                // Transform checkbox's and multi select's array into comma delimited string
                for (let f of this.$props.fields) {
                    if (f.input.type == 'checkbox') {
                        if (f.key in this.model) {
                            requestData[f.key] = Object.values(requestData[f.key]).join(',')
                        }
                    }
                    else if (f.input.type == 'selectmulti') {
                        if (f.key in this.model) {
                            if (requestData[f.key]) {
                                requestData[f.key] = Object.values(requestData[f.key]).join(',')
                            }
                        }
                    }
                    else if (['date', 'datetime'].includes(f.input.type)) {
                        let gmtDateString = localToGmt(this.model[f.key]);

                        requestData[f.key] = gmtDateString;

                    }
                    else if (f.input.type == "file") {
                        hasFileControl = true;
                        let fileControl = document.querySelector('#' + f.key);
                        requestData[f.key] = fileControl.files[0];
                    }
                    else if (f.input.type == "multirow") {
                        requestData[f.key] = requestData[f.key].filter(function (item) {
                            if (item[f.input.fields[0].key])
                                return true
                            else
                                return false
                        });

                    }
                }

                try {
                    // 如果有檔桉的話, 要用 form
                    if (hasFileControl) {
                        var formData = new FormData();

                        for (let key in requestData) {
                            if (requestData[key]) {
                                formData.append(key, requestData[key]);
                            }
                        }

                        if (this.isUpdate) {
                            await api.updateForm(requestData.id, formData);
                        } else {
                            await api.postForm(formData)
                        }
                    }
                    else {
                        if (this.isUpdate) {
                            await api.update(requestData.id, requestData)
                        } else {
                            requestData[this.pkColumn] = 0;

                            await api.create(requestData)
                        }
                    }
                }
                finally {
                    event.target.classList.remove('disabled-link');
                }

                // Clear the data inside of the form
                this.model = {}

                this.$router.go(-1);

                //var routerParam = this.getRouterParam();
                //this.$router.push(routerParam);

            },
            onCancel() {
                this.$router.go(-1);

                //var routerParam = this.getRouterParam();
                //this.$router.push(routerParam);
            },
            async getSelectOptions(objectName, idField, displayField, filterField, filterValue) {
                var data = {}
                data[filterField] = filterValue
                data['language'] = i18n.locale;

                var res = await api.getSelectOptions(objectName, data);

                var result = res.Data.map(function (row) {
                    let rowText = "";
                    if (Array.isArray(displayField)) {
                        for (let v of displayField) {
                            rowText = rowText + " " + row[v];
                        }
                    }
                    else {
                        rowText = row[displayField];
                    }

                    return {
                        value: row[idField], text: rowText
                    }
                });

                return result;
            }
        }
    }
</script>