<template>
    <cmpTableWithMenu v-bind:name="name" 
        v-bind:pkColumn="pkColumn"
        v-bind:fields="fields" 
        v-bind:qryFields="qryFields" 
        v-bind:children="children"
        v-bind:parent="parent"
        v-bind:header="header"
        v-bind:tableFuncs="tableFuncs"
        v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"
    import i18n from '@/i18n'

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    { "key": "UserName", "label": i18n.t('component.ProjectParticipant.UserName.label'), sortable: true },
                    { "key": "ProjectRoleName", "label": i18n.t('component.ProjectParticipant.ProjectRoleName.label'), sortable: true },
                    { "key": "ClassCode", "label": i18n.t('component.ProjectParticipant.ClassCode.label'), sortable: true }
                ]
            },
            qryFields() {
                return [
                    { "key": "UserName", "label": i18n.t('component.ProjectParticipant.UserName.label'), input: { type: "text" } },
                    { "key": "ClassCode", "label": i18n.t('component.ProjectParticipant.ClassCode.label'), input: { type: "text" } },
                ]
            },
            header() {
                return i18n.t('component.ProjectParticipant.lstHeader')
            }
        },
        data() {
            return {
                name: "ProjectParticipant",
                pkColumn: "ProjectParticipantId",
                parent: {
                    name: "Class",
                    key: "ProjectId"
                },
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete']
            };
        }
    }
</script>