<!-- 顯示可以勾選多筆資料的 table -->
<template>
    <div>
        <div v-if="showHeader" style="text-align:center">
            <div id="webpath">{{header}}</div>
            <cmpLoading v-if="loading"></cmpLoading>
            <!--
    <div class="mb-1">
        <img src="@/assets/icons/IcnSearch.png" data-bs-toggle="collapse" data-bs-target="#qryForm" aria-expanded="false" aria-controls="qryForm"
             style="cursor: pointer" />
    </div>

    <div class="collapse mb-5" id="qryForm">
        <cmpSearch @submit="searchSubmit"
                   @cancel="searchCancel"
                   v-bind:qryFields="qryFields">
        </cmpSearch>
    </div>
        -->
            <br /><br />
        </div>
        
        <!--content data-->
        <div>
            <table v-if="commonFields && commonFields.length > 0" border="0" align="center">
                <tbody id="detailbody">
                    <tr v-for="f in commonFields" :key="f.key">
                        <td align="right">{{f.label}}:</td>
                        <td>
                            <cmpSelect v-if="f.input.type == 'select'" v-model="commonFieldModel[f.key]" v-bind:id="`${id}_${f.key}`"
                                       v-bind:options="formBind[f.key]">
                            </cmpSelect>
                        </td>
                    </tr>
                </tbody>
            </table>

            
            <br />

            <table border="0" align="center" cellpadding="5" style="text-align:left">
                <tr>
                    <th>
                        <input type="checkbox" v-model="checkAll" v-bind:id="`${id}_checkall`" />&nbsp;
                        <label v-bind:for="`${id}_checkall`"></label>
                    </th>
                    <th v-for="f in fields.filter(t => t.input.type !== 'hidden')" :key="f.key">
                        {{f.label}}
                    </th>
                </tr>
                <tr v-for="(r, index) in records" :key="r.icon">
                    <td>
                        <input type="checkbox" v-model="r['selected']" v-bind:id="`${id}_${index}_check`" />
                    </td>
                    <td v-for="f in fields" :key="f.key">
                        <input v-if="f.input.type == 'text'" type="text" v-model="r[f.key]" style="width:90px" v-bind:id="`${id}_${f.key}_${index}`" />
                        <input v-else-if="f.input.type == 'number'" type="number" v-model="r[f.key]" v-bind:id="`${id}_${f.key}_${index}`" />
                        <span v-else-if="f.input.type == 'readonly'" v-bind:id="`${id}_${f.key}_${index}`">{{r[f.key]}}</span>
                        <input v-else-if="f.input.type == 'hidden'" type="hidden" v-model="r[f.key]" v-bind:id="`${id}_${f.key}_${index}`" />
                        <cmpSelect v-else-if="f.input.type == 'select'" v-model="r[f.key]" v-bind:id="`${id}_${f.key}_${index}`"
                                   v-bind:options="formBind[f.key]" v-bind:isMultiple="false"
                                   v-bind:moreOptions="f.input.moreOptions">
                        </cmpSelect>
                    </td>
                </tr>
            </table>
        </div>
        <br /><br />
        <div v-if="showFooter" style="width:100%; text-align:center">
            <div class="btn9" v-bind:id="`${id}_submit`" @click.prevent="submitForm">
                ✔ {{ $t('form.submit') }}
            </div>
            <div class="btn9" v-if="parent && Object.keys(parent).length > 0" v-bind:id="`${id}_back`" @click.prevent="$router.go(-1)">
                ✘ {{ $t('form.goBack') }}
            </div>
        </div>
    </div>
</template>

<style>
    .sr-only {
        display: none;
        width: 0px
    }
</style>

<script>
    import i18n from '@/i18n'
    import ApiService from '@/ApiService';
    //import cmpSearch from "@/components/common/cmpSearch"
    import cmpSelect from "@/components/common/cmpSelect"
    import cmpLoading from "@/components/common/cmpLoading"

    var api = undefined

    export default {
        components: {
            //cmpSearch,
            cmpSelect,
            cmpLoading
        },
        data() {
            return {
                loading: false,         // 是不是正在從後台讀取資料
                records: [],            // 顯示在 table 的資料
                qryModel: {},           // 設成 querystring
                commonFieldModel: {},   // Table 每單筆資料共同的欄位
                formBind: {},           // bind 到各別 input 的值，例如 combobox, checkbox, radio button
                checkAll: false         // bind 到全選的 checkbox
            };
        },
        props: {
            name: String,        // 資料庫 table 的名稱
            pkColumn: String,    // 資料庫 table PK 的名稱
            fields: Array,       // table 的欄位
            qryFields: Array,    // 艘巡的欄位（目前沒用到）
            commonFields: Array, // 共同的欄位
            getAllUrlPath: { type: String, default: () => "" }, // 讀取資料的 URL
            header: String,      // 要顯示的 header
            parent: Object,      // 設定的 parent
            id: String,          // 要 bind 到 <input> 的 id
            showHeader: { type: Boolean, default: true },   // 要不要顯示 header
            showFooter: { type: Boolean, default: true }    // 要不要顯示 footer
        },
        async created() {
            api = new ApiService(this.name)
            this.getAll()
        },
        methods: {
            /*
             *  從後台讀取要顯示在這個 table 的資料
             */
            async getAll() {
                this.qryModel = JSON.parse(JSON.stringify(this.$route.query))
                
                this.loading = true

                try {
                    let urlPath = this.getAllUrlPath ? this.getAllUrlPath : "";
                    let res = await api.getAll(JSON.parse(JSON.stringify(this.qryModel)), urlPath)
                    
                    this.records = res;

                    if (this.commonFields) {
                        await this.initializeFields(this.$props.commonFields);
                    }

                    await this.initializeFields(this.$props.fields);
                    
                } finally {
                    this.loading = false
                }

            },
            async initializeFields(fields) {
                for (let f of fields) {
                    if (['select', 'radio', 'checkbox'].includes(f.input.type)) {
                        const res2 = await this.getSelectOptions(f.input.objectName, f.input.idField, f.input.displayField, f.input.filterField, f.input.filterValue, f.input.subPath)

                        this.$set(this.formBind, f.key, res2)
                        this.qryModel[f.key] = null
                    }
                }
            },
            /*
             *  儲存資料到資料庫
             *  @param {object} event 呼叫這個 event 的參數
             */
            async submitForm(event) {
 
                // 只 submit 有選取的
                var requestObj = this.getSelected();

                event.target.classList.add('disabled-link');

                try {
                    await api.create(requestObj, 'multi')
                }
                finally {
                    event.target.classList.remove('disabled-link');
                }
                var routerParam = this.getCancelRouterLink();
                this.$router.push(routerParam);
                
            },
            /*
             *  要不要顯示這個欄位的標題
             *  @param {string} field 欄位的名稱
             */
            getCancelRouterLink() {
                var routerName = this.$root.$data.store.getRouteName(this.parent.name, "lst");

                var routerParam = { name: routerName }
                if (this.parent && Object.keys(this.parent).length > 0) {
                    routerParam['query'] = {}
                    routerParam['query'][this.parent.key] = this.$route.query[this.parent.key]
                }
                return routerParam
            },
            /*
             *  目前沒使用
             */
            searchSubmit(queryModel) {
                // 存Parent ID
                if (this.parent && this.parent.key in this.$route.query) {
                    queryModel[this.parent.key] = this.$route.query[this.parent.key]
                }
                var routerName = this.$root.$data.store.getRouteName(this.name, "lst");
                var result = { name: routerName }
                this.qryModel = queryModel
                result['query'] = this.qryModel

                this.$router.push(result).catch(() => { });
            },
            /*
             * 目前沒使用
             */
            searchCancel() {
                window.$('#qryForm').collapse('hide');
            },
            /*
             * Return 有勾選的資料
             */
            getSelected() {
                // 把 commonFields 的值加到每一個 record
                if (this.commonFields) {
                    for (const cf of this.commonFields) {
                        for (const r of this.records) {
                            r[cf.key] = this.commonFieldModel[cf.key];
                        }
                    }
                }

                // 只 submit 有選取的
                var requestObj = []

                for (const record of this.records) {
                    if (record['selected']) {
                        requestObj.push(record);
                    }

                }

                return requestObj;

            },
            /*
             *  從後台讀取像 combobox, radiobutton 選項的值
             *  @param {string} objectName 要從資料庫讀取選項的 table 名稱
             *  @param {string} idField ID 欄位的名稱
             *  @param {string} displayField 要顯示欄位的名稱
             *  @param {string} filterField 要搜尋的欄位名稱
             *  @param {string} filterValue 要搜尋的欄位的值
             *  @param {string} subPath call API 的子路徑
             */
            async getSelectOptions(objectName, idField, displayField, filterField, filterValue, subPath) {
                var data = {}

                if (filterField) {
                    if (filterValue) {
                        data[filterField] = filterValue
                    }
                    // 如果 filterValue 沒有傳進來，從 querystring 抓
                    else if (this.$route.query[filterField]){
                        data[filterField] = this.$route.query[filterField]
                    }

                }

                data['language'] = i18n.locale;

                var res = await api.getSelectOptions(objectName, data, subPath);

                // 把重複的資料移除
                var result = res.Data.reduce((unique, row) => {

                    if (row[displayField] && !unique.some(obj => obj.text === row[displayField])) {
                        unique.push({
                            value: row[idField], text: row[displayField]
                        });
                    }
                    return unique;
                }, []);


                //return [{ value: '', text: '請選擇' }].concat(result);
                return result;
            }
        },
        watch: {
            '$route.params': 'getAll',
            checkAll() {

                for (let r of this.records) {
                    r['selected'] = this.checkAll;
                }
            },
        }
    }

</script>