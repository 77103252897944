<template>
    <cmpRec v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    { "key": "InventoryName", "label": i18n.t('component.DeviceInventory.InventoryName.label')},
                    { "key": "InventoryCode", "label": i18n.t('component.DeviceInventory.InventoryCode.label') },
                    { "key": "InventoryNumber", "label": i18n.t('component.DeviceInventory.InventoryNumber.label') },
                    { "key": "Depositary", "label": i18n.t('component.DeviceInventory.Depositary.label')},
                    { "key": "InventoryLocation", "label": i18n.t('component.DeviceInventory.InventoryLocation.label')},
                    { "key": "UnitId", "label": i18n.t('component.DeviceTables.UnitId.label') },
                ]
            },
            header() {
                return i18n.t('component.DeviceInventory.recHeader')
            }
        },
        data() {

            return {
                name: "DeviceInventory",
                pkColumn: "DeviceInventoryId",
                parent: {}
            };
        }
    }
</script>