<template>
    <cmpCreate v-bind:name="name"
               v-bind:pkColumn="pkColumn"
               v-bind:fields="fields"
               v-bind:parent="parent"
               v-bind:header="header"
               v-bind:goBackAfterCreate="false"
               v-bind:local="true"
               @recordCreated="recordCreated"
               @onCancel="onCancel"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    //import cmpCreate from "@/components/common/cmpCreate"

    export default {
        components: {
            cmpCreate: () => import("@/components/common/cmpCreate")
        },
        computed: {
            fields() {
                return [
                    { "key": "MemberId", "label": i18n.t('component.User.MemberId.label'), input: { type: "text" }, required: true },
                    { "key": "Name", "label": i18n.t('component.User.Name.label'), input: { type: "text" } }
                ]
            },
            header() {
                return i18n.t('component.User.insHeader')
            }
        },
        data() {

            return {
                name: "User",
                pkColumn: "UserId",
                parent: {}
            };
        },
        methods: {
            recordCreated(record) {
                this.$emit("recordCreated", record);
            },
            onCancel() {
                this.$emit("onCancel");
            }
        }
    }
</script>