<template>
    <div style="text-align:center">
        <br />
        <cmpSelect v-model="selectedRole"
                   v-bind:options="rolesOption"
                   v-bind:isMultiple="false">
        </cmpSelect>
        <br /><br />
        <div class="btn9" @click.prevent="submit">✔ {{ $t('form.submit') }}</div>
    </div>
</template>

<script>
    import cmpSelect from "@/components/common/cmpSelect"
    import ApiService from '@/ApiService';

    const apiUserRole = new ApiService("UserRole");

    export default {
        components: {
            cmpSelect
        },
        data() {

            return {
                selectedRole: "",
                rolesOption: []
            };
        },
        async created() {

            var res = await apiUserRole.getAll(null, "current");

            var result = res.map(function (row) {
                return {
                    value: row["RoleName"], text: row["RoleName"]
                }
            });

            this.rolesOption = result;

            //this.$set(this.model, f.key, response[f.input.displayField]);
        },
        methods: {
            async submit() {
                const apiProfile = new ApiService("Profile");
                let requestData = {
                    "Role": this.selectedRole
                };
                await apiProfile.post(requestData, "selectrole");

                let routerName = this.$root.$data.store.getRouteName("loggedinHome");

                this.$router.push({ name: routerName })
            }
        }
    }
</script>