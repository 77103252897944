<template>
    <cmpCreate v-bind:name="name"
               v-bind:pkColumn="pkColumn"
               v-bind:fields="fields"
               v-bind:parent="parent"
               v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "UserId", "label": i18n.t('component.UserRole.UserId.label'), input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", editDisplayField: "UserName", qryFields: ["MemberIdOr", "NameOr"], displayFields: ["MemberId", "Name"] } },
                    { "key": "Account", "label": i18n.t('component.UserRole.Account.label'), input: { type: "text" } },
                    { "key": "Roles", "label": i18n.t('component.UserRole.Roles.label'), input: { type: "selectmulti", objectName: "Role", idField: "RoleId", displayField: ["RoleDisplayName"] } }
                ]
            },
            header() {
                return i18n.t('component.UserRole.insHeader')
            }
        },
        data() {

            return {
                name: "Login",
                pkColumn: "LoginId",
                parent: {}
            };
        }
    }
</script>