<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"
    import { codeName } from "@/util/constant"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "UnitCode", "label": i18n.t('component.Unit.UnitCode.label'), input: { type: "text" }, required: true },
                    { "key": "UnitName", "label": i18n.t('component.Unit.UnitName.label'), input: { type: "text" } },
                    { "key": "UnitBriefName", "label": i18n.t('component.Unit.UnitBriefName.label'), input: { type: "text" } },
                    { "key": "UnitTaxNumber", "label": i18n.t('component.Unit.UnitTaxNumber.label'), input: { type: "text" } },
                    { "key": "UnitType", "label": i18n.t('component.Unit.UnitType.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.UnitType } },
                    { "key": "DirectorId", "label": i18n.t('component.Unit.Director.label'), input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", editDisplayField: "Director", qryFields: ["MemberIdOr", "NameOr"], displayFields: ["MemberId", "Name"] } },
                    { "key": "UnitEmail", "label": i18n.t('component.Unit.UnitEmail.label'), input: { type: "text" } },
                    { "key": "Url", "label": i18n.t('component.Unit.Url.label'), input: { type: "text" } },
                    { "key": "ContactPerson", "label": i18n.t('component.Unit.ContactPerson.label'), input: { type: "text" } },
                    { "key": "ContactTitle", "label": i18n.t('component.Unit.ContactTitle.label'), input: { type: "text" } },
                    { "key": "Phone", "label": i18n.t('component.Unit.Phone.label'), input: { type: "text" } },
                    { "key": "Mobile", "label": i18n.t('component.Unit.Mobile.label'), input: { type: "text" } },
                    { "key": "UnitZip", "label": i18n.t('component.Unit.UnitZip.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.ZipCode } },
                    { "key": "UnitAddress", "label": i18n.t('component.Unit.UnitAddress.label'), input: { type: "text" } },
                    { "key": "UnitBankAtm", "label": i18n.t('component.Unit.UnitBankAtm.label'), input: { type: "text" } },
                    { "key": "UnitAccount", "label": i18n.t('component.Unit.UnitAccount.label'), input: { type: "text" } },
                    { "key": "UnitMemo", "label": i18n.t('component.Unit.UnitMemo.label'), input: { type: "text" } }
                ]
            },
            header() {
                return i18n.t('component.Unit.insHeader')
            }
        },
        data() {

            return {
                name: "Unit",
                pkColumn: "UnitId",
                parent: {}
            };
        }
    }
</script>