<!-- Combobox (<select>) 的 wrapper -->
<template>
    <select v-model="selectedOption" v-bind:multiple="isMultiple" v-bind:id="id">
        <option v-if="!isMultiple" v-bind:value="null">{{nullValueLabel}}</option>
        <option v-for="item in filteredOptions" v-bind:key="item.value" :value="item.value">{{ item.text }}</option>
        <option v-for="item in moreOptions" v-bind:key="item.value" :value="item.value">{{ item.text }}</option>
    </select>
</template>

<script>
    import i18n from '@/i18n'

    export default {
        data() {
            return {
                selectedOption: null,       // 選取的object 的 value
                selectedOptionObject: null, // 選取的object 的全部的 value, 用來 filter 其他的 combobox
                filteredOptions: null       // bind 到 combobox 的選項
            };
        },
        props: {
            name: String,           // 這個 combobox 的名稱
            options: Array,         // 要 bind 到 combobox 全部的選項
            value: null,            // v-model 需要用到的
            filterValue: null,      // 用來過濾 options 的值
            isMultiple: Boolean,    // combobox 要不要支援多筆選項
            nullVaule: { type: String }, // 不選的選項的值
            moreOptions: { type: Array, default: () => [] }, // 要加在 combobox 的更多選項
            id: String              // 要 bind 到 html tag 的 id
        },
        computed: {
            /*
             *  要 bind 到沒有選項的值
             */
            nullValueLabel() {
                if (this.nullValue)
                    return this.nullValue;
                else
                    return i18n.t('form.pleaseSelect');
            }
        },
        created() {
            if (this.isMultiple) {
                this.selectedOption = [];
            }
            else {
                this.selectedOption = null;
            }
        },
        watch: {
            options() {
                // props.options 傳進來時, 先顯示全部的 options
                this.filteredOptions = this.options;
            },
            selectedOption() {
                // 這個是選取的值
                this.$emit('input', this.selectedOption);


                if (this.options) {
                    var s = this.options.filter(item => item['value'] == this.selectedOption);

                    if (s.length > 0) {
                        // 這個是選取的值的整個 record
                        this.$emit('selectedValue', s[0]);
                    }
                }

            },
            /*
             *  從上層傳進來的 value (v-model) 要用來設一些這個 component 的變數
             */
            value() {
                if (this.isMultiple) {
                    if (this.value) {
                        // 如果是 string 要轉成 array
                        if (typeof this.value === 'string' || this.value instanceof String) {
                            this.selectedOption = this.value.split(',');
                        }
                        else {
                            this.selectedOption = this.value;
                        }
                    }

                }
                else {
                    this.selectedOption = this.value;
                }
            },
            filterValue() {

                // 用 record 的 pk 做過濾
                if (this.filterValue && this.options) {
                    this.filteredOptions = this.options.filter(item => item['pk'] == this.filterValue || item['value'] == null);
                }
                else {
                    this.filteredOptions = this.options;
                }

                this.selectedOption = null;
            }
        }
    }
</script>