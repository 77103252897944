<!-- 一個可搜尋欄位的 component -->
<template>
    <div style="display:inline">
        <input type="text" v-model="searchResultValue" v-bind:id="`${id}_text`" />
        <input type="hidden"
               v-bind:value="searchResultId"
               v-on:input="$emit('input', $event.target.value)" />


        <a style="cursor: pointer; margin-left:2px" data-toggle="dropdown" aria-expanded="false" data-disabled="true"
           v-bind:data-target="`${id}_search`"
           v-bind:id="`${id}_search`"
           data-flip="false"
           @click="search()">
            <img src="@/assets/icons/IcnSearchButton.png" class="search-icon" />
        </a>
        <div class="dropdown-menu" v-bind:aria-labelledby="`${id}_search`">
            <cmpSearchModal ref="searchModal" @submit="onSearch"
                            v-bind:objectName="objectName"
                            v-bind:header="header"
                            v-bind:qryFields="qryFields"
                            v-bind:displayFields="displayFields">
            </cmpSearchModal>
        </div>

        <a v-if="addButtonConfig" href="#" @click.prevent="showModal" id="add"><img src="@/assets/icons/IcnAddpeople.png" style="height:30px" /></a>

        <b-modal v-bind:id="addModalId"
                 v-bind:hide-footer="true">
            <component v-if="addButtonConfig" v-bind:is="addButtonConfig.form"
                       @recordCreated="recordCreated"
                       @onCancel="modalCancel"
                       ></component>
        </b-modal>
    </div>
</template>

<script>
    import cmpSearchModal from "@/components/common/cmpSearchModal"
    import insUserModal from "@/views/insUserModal"

    //import $ from 'jquery'

    export default {
        components: {
            cmpSearchModal,
            insUserModal
        },
        data() {
            return {
                searchResultId: '',     // 搜尋到的這筆資料的 ID
                searchResultValue: ''   // 要用來搜尋的 keyword，或搜尋後找到的這筆資料的值
            };
        },
        props: {
            objectName: String,     // 要搜尋的資料庫 table 的名稱
            header: String,         // 標題（目前沒顯示）
            idField: String,        // ID 欄位的名稱
            qryFields: Array,       // 搜尋資料庫的欄位
            displayFields: Array,   // 顯示在搜尋 table 的欄位名稱
            displayField: String,   // 顯示在 textbox 的欄位名稱
            displayFieldValue: String,  // 要直接顯示在 textbox 的值
            value: null,            // 這個 component 的 value
            formFilterFields: { type: Array, default: () => [] }, // 搜尋時要從 parent form 取得的欄位
            id: String,              // 要 bind 到這個 component html tag 的 id
            addButtonConfig: { type: Object, default: () => null }  // 顯式新增按鈕和 config
        },
        computed: {
            addModalId: function () {
                return `addModal_${this.id}`;
            }
        },
        created() {

        },
        mounted() {
            this.searchResultId = this.value;
            this.searchResultValue = this.displayFieldValue;
        },
        methods: {
            /*
             *  cmpSearchModal 搜尋完成的 callback
             *  @param {array} searchResultList 搜尋傳回的值
             */
            onSearch(searchResultList) {
                // 目前這需要顯示第單筆資料
                if (searchResultList && searchResultList.length > 0) {
                    this.searchResultId = searchResultList[0][this.idField];
                    this.searchResultValue = searchResultList[0][this.displayField]

                    this.$emit('searchResultValue', this.searchResultValue);
                }
            },
            /*
             *  執行搜尋
             */
            search() {
                //c('cmpTextboxSearc', 'search', this.formFilterFields);

                // 搜尋前需要從 parent 取得其他的欄位
                if (this.formFilterFields.length > 0) {
                    this.$emit('onSearch', this.formFilterFields);
                }
                else {
                    this.$refs.searchModal.search(this.searchResultValue);
                }
            },
            /*
             *  從 parent 執行搜尋
             *  @param {array} searchTerms 搜尋的 keywords
             */
            parentSearch(searchTerms) {
                this.$refs.searchModal.search(this.searchResultValue, searchTerms);
            },
            /*
             *  清除搜尋欄位
             */
            clearSearch() {
                this.searchResultValue = "";
            },
            showModal() {
                this.$bvModal.show(this.addModalId);
            },
            recordCreated(record) {
                this.onSearch([record]);
                this.modalCancel();
            },
            modalCancel() {
                this.$bvModal.hide(this.addModalId);
            }
        },
        watch: {
            searchResultId() {
                this.$emit('input', this.searchResultId);
            },
            value() {
                this.searchResultId = this.value;
            },
            displayFieldValue() {
                this.searchResultValue = this.displayFieldValue;
            },
            searchResultValue() {
                if (this.searchResultValue == "") {
                    this.searchResultId = "";
                }
            }
        }
    }
</script>