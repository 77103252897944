<template>
    <div style="margin: 0 20px">

        <cmpLoading v-if="loading"></cmpLoading>

        <div v-if="showPrintButton" style="text-align: center">
            <cmpMenuButton v-bind:label="'列印'"
                           v-bind:imagePath="'assets/icons/IcnPrint.png'"
                           v-bind:hasPrivilege="true"
                           @click="print()">
            </cmpMenuButton>
            <br /><br />
        </div>

        <div id="rptTeacherPrintable">
            <div style="text-align:center; font-size:24px">{{header}}</div>
            <br />
            列印日期: {{now}}
            <br /><br />

            <div>
                人員名稱: {{model.User.UserName}}
                <table class="report-table">
                    <tr>
                        <td v-bind:colspan="getHeaderColSpan()">
                            單位：{{model.User.UnitName}}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">角色</td>
                    </tr>
                    <tr>
                        <td>編號</td>
                        <td>名稱</td>
                    </tr>
                    <tr v-for="r in model.Permission.Roles" :key="r.RoleId + Math.random().toString(16).slice(2)">
                        <td>{{formatRoleId(r.RoleId)}}</td>
                        <td>{{r.DisplayName}}</td>
                    </tr>
                </table>
                <br /><br />
                <p style="page-break-after:always"></p>
            </div>
        </div>
    </div>
</template>

<style>
    .report-width {
        width: 700px;
    }

    .report-table {
        border-collapse: collapse;
    }

    .report-table tr td {
        border: 1px solid black;
        padding: 2px;
    }

    .report-table-cell {
        background-color: lightgray
    }
</style>
<script>
    // import 一些需要用到的 javascript library/function
    import ApiService from '@/ApiService';
    import { formatDate, calculateAge, formatDatetime, gmtToLocal } from '@/util/dateutil';
    import cmpLoading from "@/components/common/cmpLoading"
    import cmpMenuButton from "@/components/common/cmpMenuButton"

    var api = new ApiService("Report"); // Report 是後台 controller 的名字

    export default {
        components: {
            cmpLoading, cmpMenuButton
        },
        data() {
            return {
                loading: false,
                name: "Report",
                header: "分析報告表",
                model: {}
            }
        },
        props: {
            showPrintButton: { type: Boolean, default: true },  // 要不要顥式列印 button
            loadOnCreate: { type: Boolean, default: true }      // 要不要自動去後台抓資料
        },
        computed: {
            age: function () {
                return calculateAge(new Date(this.model.Info.Birthday));
            },
            ReportDateFormatted: function () {
                return formatDate(new Date());
            },
            now: function () {
                return formatDatetime(new Date())
            }
        },
        // 這個 view 的 entry function
        async created() {
            if (this.loadOnCreate) {
                // this.$route.query 是抓 querystring
                await this.get(this.$route.query)
            }
        },
        methods: {
            async get(queryModel) {
                this.loading = true
                try {
                    // Call API 抓report 的資料, 會call GET report/sample
                    this.model = await api.getAll(queryModel, 'sample');

                } finally {
                    this.loading = false
                }
            },
            formatRoleId(roleId) {
                return "R_" + roleId;
            },
            getHeaderColSpan() {
                return 2;
            },
            formatDate(d) {
                return formatDate(d);
            },
            formatDateToLocal(d) {
                const localDate = gmtToLocal(d);
                if (localDate) {
                    return localDate.split('T')[0];
                }

                return null;
            },
            print() {

                var printContents = document.getElementById('rptTeacherPrintable').innerHTML;
                //var originalContents = document.body.innerHTML;
                let loadScript = "javascript:window.print();window.close()";

                let stylesHtml = '';
                for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                    stylesHtml += node.outerHTML;
                }

                var WinPrint = window.open('', '', 'left=0,top=0,width=750,height=900,toolbar=0,scrollbars=0,status=0');
                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    ${stylesHtml}
                  </head>
                    <body onload="${loadScript}">
                    ${printContents}
                  </body>
                </html>`);

                WinPrint.document.close();
            }
        }
    }
</script>
