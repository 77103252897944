<template>
    <cmpCreate v-bind:name="name"
               v-bind:pkColumn="pkColumn"
               v-bind:fields="fields"
               v-bind:parent="parent"
               v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "CourseName", "label": i18n.t('component.MotionData.CourseName.label'), input: { type: "readonly" } },
                    { "key": "MissionName", "label": i18n.t('component.MotionData.MissionName.label'), input: { type: "readonly" } },
                    { "key": "UserName", "label": i18n.t('component.MotionData.UserName.label'), input: { type: "readonly" } },
                    { "key": "DeviceId", "label": i18n.t('component.MotionData.DeviceId.label'), input: { type: "text" } },
                    { "key": "Memo", "label": i18n.t('component.MotionData.Memo.label'), input: { type: "textarea", style: "width:200px; height: 200px" } }
                ]
            },
            header() {
                return i18n.t('component.MotionData.insHeader')
            }
        },
        data() {
            return {
                name: "MotionData",
                pkColumn: "MotionDataId",
                parent: { "name": "MotionData", "key": "ActivityId" }
            };
        }
    }
</script>