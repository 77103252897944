<!-- 活動單筆新增及修改畫面 -->
<template>
    <!-- 綁定參數及callback function -->
    <cmpCreate ref="cmpCreate"
               v-bind:name="name"
               v-bind:pkColumn="pkColumn"
               v-bind:fields="fields"
               v-bind:parent="parent"
               v-bind:header="header"
               v-bind:navButtons="navButtons"
               v-bind:functionButtons="functionButtons"
               @inputChanged="inputChanged"
               @functionButtonClick="functionButtonClick"></cmpCreate>
</template>

<script>
    //多國語言library
    import i18n from '@/i18n'
    // 匯入cmpCreate component
    import cmpCreate from "@/components/common/cmpCreate"
    //常數libaray
    import { codeName } from "@/util/constant"
    //時間函數
    import { gmtToLocal } from '@/util/dateutil';

    export default {
        components: {
            cmpCreate
        },
        computed: {
            //新增修改畫面欄位
            fields() {
                return [
                    //key: 欄位名稱, label:欄位說明, input 輸入欄位 type:textboxSearch:顯示搜尋畫面並挑選, objectName: 參考table,idField:參考table p-key displayField:顯示欄位,editDisplayField:單筆查詢,取得欄位名稱, qryFields 查詢欄位條件 displayFields:顯示欄位
                    {
                        "key": "RespondentId", "label": i18n.t('component.Activity.RespondentName.label'), input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", editDisplayField: "RespondentName", qryFields: ["MemberIdOr", "NameOr"], displayFields: ["MemberId", "Name"] }
                        , addButtonConfig: { form: "insUserModal" }, required: true, hideIfQuery: true, queryString: 'ActivityId'
                    },
                    { "key": "RespondentName", "label": i18n.t('component.Activity.RespondentName.label'), input: { type: "readonly" } },
                    { "key": "ActivityStatus", "label": i18n.t('component.Activity.ActivityStatus.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.ActivityStatus }, required: true },
                    { "key": "ActivityStartDate", "label": i18n.t('component.Activity.ActivityStartDate.label'), input: { type: "datetime" }, required: true },
                    { "key": "ActivityPeriod", "label": i18n.t('component.Activity.ActivityPeriod.label'), input: { type: "number" } },
                    { "key": "ActivityEndDate", "label": i18n.t('component.Activity.ActivityEndDate.label'), input: { type: "datetime" }, required: true },
                    { "key": "ModeratorId", "label": i18n.t('component.Activity.ModeratorName.label'), input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", editDisplayField: "ModeratorName", qryFields: ["MemberIdOr", "NameOr"], displayFields: ["MemberId", "Name"] }, required: true },
                    { "key": "ActivityType", "label": i18n.t('component.Activity.ActivityType.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.ActivityType } },                    
                    { "key": "ProjectStage", "label": i18n.t('component.Activity.ProjectStage.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.ProjectStage } },
                    //key: 欄位名稱, label:欄位說明, input 輸入欄位 type:select: select element, objectName: 參考table,idField:參考table p-key displayField:顯示欄位,filterField:過濾條件欄位, filterValue:過濾條件值
                    { "key": "ActivityTitle", "label": i18n.t('component.Activity.ActivityTitle.label'), input: { type: "text" }, required: true },
                    { "key": "ActivityDesc", "label": i18n.t('component.Activity.ActivityDesc.label'), input: { type: "text" } },
                    { "key": "Memo", "label": i18n.t('component.Activity.Memo.label'), input: { type: "text" } },
                    {
                        //key: 欄位名稱, label:欄位說明, 
                        "key": "Missions", "label": i18n.t('component.Activity.MissionName.label'),
                        //input type:multirow 多筆輸入, fields欄位比照上層fields
                        input: {
                            type: "multirow",
                            fields: [{
                                key: "MissionId", "label": i18n.t('component.Activity.MissionName.label'),
                                input: {
                                    type: "textboxSearch", objectName: "Mission", idField: "MissionId", displayField: "MissionName", editDisplayField: "MissionName", qryFields: ["MissionNameOr", "CourseNameOr"], displayFields: ["CourseName", "MissionName"],
                                }
                            }]
                        }, required: true
                    },
                ]
            },
            header() {
                //返回 畫面header 路徑
                return i18n.t('component.Activity.insHeader')
            },
            navButtons() {
                return [
                    //key: 欄位名稱, label:欄位說明
                    //{ "key": "User", "label": "User", "redirectComponent": "insUser", icon: "IcnAddpeople.png" },
                    { "key": "Device", "label": i18n.t('component.Activity.FunctionButton.Device'), "function": "callback", icon: "IcnGo.png" }
                ]
            },
            functionButtons() {
                return [
                    //key: Button 名稱, label:欄位說明
                    //{ "key": "Device", "label": i18n.t('component.Activity.FunctionButton.Device') },
                ]
            }
        },
        data() {

            return {
                // 資料庫 table 的名稱
                name: "Activity",
                // 資料庫 table p-key
                pkColumn: "ActivityId",
                //參考到的上層畫面 用法可參考CodeDetail
                parent: {}
            };
        },
        methods: {
            /*
             *輸入內容,修改時call back function, 其他call back function 請參考cmpCreate
             */
            inputChanged(caller, model) {
                // 計算活動時間
                if (["ActivityStartDate", "ActivityPeriod"].includes(caller) && model.ActivityStartDate && model.ActivityPeriod && !model.ActivityEndDate) {
                    const profile = this.$root.$data.store.getProfile();
                    let startDate = new Date(model.ActivityStartDate);

                    // 小時
                    if (profile.ActivityPeriodUnit == "hour") {
                        startDate.setTime(startDate.getTime() + (model.ActivityPeriod * 60 * 60 * 1000));
                    }
                    // 分鐘
                    else {
                        startDate.setTime(startDate.getTime() + (model.ActivityPeriod * 60 * 1000));
                    }
                    model.ActivityEndDate = gmtToLocal(startDate.toISOString());
                }

                // 開始時間不能超過結束時間
                if (["ActivityStartDate", "ActivityEndDate", "ActivityPeriod"].includes(caller) && model.ActivityStartDate && model.ActivityEndDate) {
                    let startDate = new Date(model.ActivityStartDate);
                    let endDate = new Date(model.ActivityEndDate);

                    if (startDate > endDate) {
                        model.ActivityEndDate = "";
                    }
                }
            },
            /*
             *  功能按鈕的 call back function, 
             *  @param {string} caller 功能按鈕的名字
             *  @param {object} record 這個 form 的資料, cmpCreate 裡的 model
             */
            functionButtonClick(caller, record) {

                if (caller === "Device") {

                    // Redirect 到設備對照
                    var routerParam = { name: "lstMotionData" };
                    routerParam['query'] = {};
                    routerParam['query']["ActivityId"] = record["ActivityId"]
                    this.$router.push(routerParam);
                }
            }

        }
    }
</script>