<template>
    <div>
    </div>
</template>


<script>
    import ApiService from '@/ApiService';

    export default {
        async created() {
            const queryString = JSON.parse(JSON.stringify(this.$route.query))

            if (queryString.DT) {
                
                localStorage.setItem('token', queryString.DT);

                if (!localStorage.getItem('profile')) {
                    const profileApi = new ApiService('profile');
                    var profile = await profileApi.getProfile();

                    if (profile && profile.length > 0) {
                        localStorage.setItem('profile', JSON.stringify(profile[0]));
                    }
                }
            }

            if (queryString.R) {
                this.$router.push(queryString.R)
            }
        }

    }
</script>
