<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"
    import { codeName } from "@/util/constant"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "InventoryName", "label": i18n.t('component.DeviceInventory.InventoryName.label'), input: { type: "text" }, required: true },
                    { "key": "InventoryCode", "label": i18n.t('component.DeviceInventory.InventoryCode.label'), input: { type: "text" } },
                    { "key": "InventoryNumber", "label": i18n.t('component.DeviceInventory.InventoryNumber.label'), input: { type: "number" } },
                    { "key": "Depositary", "label": i18n.t('component.DeviceInventory.Depositary.label'), input: { type: "text" } },
                    { "key": "InventoryLocation", "label": i18n.t('component.DeviceInventory.InventoryLocation.label'), input: { type: "text" } },
                    {
                        "key": "UnitId", "label": i18n.t('component.DeviceInventory.UnitId.label')
                        , input: { type: "textboxSearch", objectName: "Unit", idField: "UnitId", displayField: "UnitName", editDisplayField: "UnitName", qryFields: ["UnitNameOr", "UnitCodeOr"], displayFields: ["UnitCode", "UnitName"] }
                        , required: true
                    },

                ]
            },
            header() {
                return i18n.t('component.DeviceInventory.insHeader')
            }
        },
        data() {

            return {
                name: "DeviceInventory",
                pkColumn: "DeviceInventoryId",
                parent: {}
            };
        }
    }
</script>