<template>
    <cmpTableWithMenu v-bind:name="name" 
        v-bind:pkColumn="pkColumn"
        v-bind:fields="fields" 
        v-bind:qryFields="qryFields" 
        v-bind:children="children"
        v-bind:parent="parent"
        v-bind:header="header"
        v-bind:tableFuncs="tableFuncs"
        v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"
    import i18n from '@/i18n'
    import { codeName } from "@/util/constant"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    { "key": "UserName", "label": i18n.t('component.ActivityParticipant.UserName.label'), sortable: true },
                    { "key": "ProjectRoleName", "label": i18n.t('component.ActivityParticipant.ProjectRoleName.label'), sortable: true }
                ]
            },
            qryFields() {
                return [
                    { "key": "UserName", "label": i18n.t('component.ActivityParticipant.UserName.label'), input: { type: "text" } },
                    { "key": "ProjectRole", "label": i18n.t('component.ActivityParticipant.ProjectRoleName.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeDisplay", displayField: "CodeDisplay", filterField: "CodeName", filterValue: codeName.ProjectRole } },
                ]
            },
            header() {
                return i18n.t('component.ActivityParticipant.lstHeader')
            },
            parent() {
                return {
                    "name": "Activity",
                    "key": "ActivityId",
                    "dataHeaders": [
                        { "key": "ActivityTitle", "label": i18n.t('component.ActivityParticipant.ActivityTitle.label') }
                    ]
                }
            }
        },
        data() {
            return {
                name: "ActivityParticipant",
                pkColumn: "ActivityParticipantId",
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete']
            };
        }
    }
</script>