<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "ParametervarSect", "label": i18n.t('component.SysParameter.ParametervarSect.label'), input: { type: "text" } },
                    { "key": "ParametervarName", "label": i18n.t('component.SysParameter.ParametervarName.label'), input: { type: "text" } },
                    { "key": "ParametervarValue", "label": i18n.t('component.SysParameter.ParametervarValue.label'), input: { type: "text" } }
                ]
            },
            header() {
                return i18n.t('component.SysParameter.insHeader')
            }
        },
        data() {

            return {
                name: "SysParameter",
                pkColumn: "SysParameterId",
                parent: {}
            };
        }
    }
</script>