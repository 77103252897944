<template>
    <cmpTableWithMenu v-bind:name="name"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:header="header"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    { "key": "CourseName", "label": i18n.t('component.GoldenSampleFeature.CourseName.label'), sortable: true },
                    { "key": "FeatureFieldName", "label": i18n.t('component.GoldenSampleFeature.FeatureFieldName.label'), sortable: true },
                    { "key": "FeatureFieldValue", "label": i18n.t('component.GoldenSampleFeature.FeatureFieldValue.label'), sortable: true },
                    { "key": "FeatureFieldValueNormal", "label": i18n.t('component.GoldenSampleFeature.FeatureFieldValueNormal.label'), sortable: true },
                    { "key": "FeatureFieldSorting", "label": i18n.t('component.GoldenSampleFeature.FeatureFieldSorting.label'), sortable: true },
                ]
            },
            qryFields() {
                return [
                    { "key": "CourseName", "label": i18n.t('component.GoldenSampleFeature.UnitName.label'), input: { type: "text" } },
                    { "key": "FeatureFieldName", "label": i18n.t('component.GoldenSampleFeature.UnitBriefName.label'), input: { type: "text" } },
                ]
            }
            ,
            header() {
                return i18n.t('component.GoldenSampleFeature.lstHeader')
            }
        },
        data() {

            return {
                name: "GoldenSampleFeature",
                pkColumn: "GoldenSampleFeatureId",
                children: {},
                parent: {},
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete'],
                moreTableFuncs: []
            };
        }
    }
</script>