<template>
    <div>
        <div class="iotType">
            <b-button-group v-if="sysParameter">
                <b-button :class="{ active: sysParameter.ParametervarValue === 'socket' }"
                    v-on:click="setIotType('socket')">socket</b-button>
                <b-button :class="{ active: sysParameter.ParametervarValue === 'iot' }"
                    v-on:click="setIotType('iot')">iot</b-button>
            </b-button-group>
            <div v-else><span style="background-color: red;">無法取得通訊型態，請盡速通知開發團隊</span></div>
        </div>
    </div>
</template>

<style>
.active {
    background-color: green !important
        /* 被選中的按鈕背景色 */
}

.iotType {
    margin: 20px;
    text-align: center;
}
</style>

<script>
import ApiService from '@/ApiService';
export default {
    mounted() {
        this.getIotType();
    },
    methods: {
        async getIotType() {
            const api = new ApiService("SysParameter");
            const requestData = {
                ParametervarName: "IotType",
                ParametervarSect: "IotType"
            }
            this.sysParameter = (await api.getAll(requestData)).Data[0];
        },
        async setIotType(iotType) {
            const api = new ApiService("SysParameter");
            const requestData = { ... this.sysParameter, "ParametervarValue": iotType }
            await api.update(this.sysParameter.SysParameterId, requestData)
            this.getIotType();
        }
    },
    data() {
        return {
            sysParameter: {}
        };
    }
}
</script>