import cmpLoading from "@/components/common/cmpLoading"
import ApiService from '@/ApiService';
import { userRole } from '@/util/constant'

const api = new ApiService('authenticate');


export default {
    data() {
        return {
            username: "",
            password: "",
            loading: false,
            showLogin: false
        };
    },
    components: {
        cmpLoading
    },
    methods: {
        async login() {
            try {
                
                var cred = {
                    "username": this.username,
                    "password": this.password
                }
                this.loading = true;

                await api.login(cred)

                const profileApi = new ApiService('profile');
                var profile = await profileApi.getProfile();

                if (profile && profile.length > 0) {
                    this.$root.$data.store.setProfile(profile[0]);

                    if (profile[0].Roles && profile[0].Roles.length > 1) {
                        let profileRoute = this.$root.$data.store.getRouteName("ChooseRole", "lst");

                        this.$router.push({ name: profileRoute })
                        return;
                    }
                }

                let routerName = this.$root.$data.store.getRouteName("loggedinHome");

                this.$router.push({ name: routerName })
            }
            catch {
                alert('登入失敗');
                this.loading = false;
            }
        }


    }
}