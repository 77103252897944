<!-- 顯示 form 的 component，支援很多不同的輸入欄位，例如 text, number, datetime, combobox 等等 -->

<template>
    <div>
        <div id="webpath" v-if="!showFormOnly">{{ header }}{{ action == 'U' ? $t('form.modify') : $t('form.add') }}</div>
        <cmpLoading v-if="loading && !showFormOnly"></cmpLoading>
        <div style="text-align:center">
            <div v-for="n in navButtons" v-bind:key="n.name" style="display:inline">
                <cmpMenuButton v-bind:label="n.label" v-bind:imagePath="`assets/icons/${n.icon}`" v-bind:hasPrivilege="true"
                               @click="navButtonsClick(n.key, n.function, n.redirectComponent)">
                </cmpMenuButton>

            </div>
        </div>
        <br />
        <div style="text-align:center">
            <cmpDataHeaderRow v-if="parent && parent.dataHeaders" v-bind:name="parent.name" v-bind:pkColumn="parent.key"
                              v-bind:dataHeaders="parent.dataHeaders"></cmpDataHeaderRow>
        </div>
        <div>
            <table border="0" align="center" class="table2">
                <tr v-for="f in fields" :key="f.key">
                    <td align="left" valign="top" v-if="showTableHeader(f)">
                        <span v-if="showTableHeader(f)">
                            {{ f.label }} <span v-if="f.required">*</span>
                        </span>
                    </td>
                    <td align="left" v-if="showTableHeader(f)">
                        <span v-if="f.input.type == 'label'">
                            {{ f.input.default }}
                        </span>
                        <input v-if="f.input.type == 'text'" type="text" v-model="model[f.key]" v-bind:id="f.key"
                               v-bind:style="f.input.style ? f.input.style : null" @change="inputChanged(f.key, $event)" />
                        <input v-if="f.input.type == 'readonly' && showReadonly(f)" type="text" v-model="model[f.key]"
                               v-bind:id="f.key" v-bind:style="f.input.style ? f.input.style : null" readonly />
                        <input v-if="f.input.type == 'number'" type="number" v-model="model[f.key]" v-bind:id="f.key"
                               v-bind:style="f.input.style ? f.input.style : null" @change="inputChanged(f.key, $event)" />
                        <input v-else-if="f.input.type == 'password'" type="password" v-model="model[f.key]"
                               v-bind:id="f.key" v-bind:style="f.input.style ? f.input.style : null" />
                        <input v-else-if="f.input.type == 'boolean'" type="checkbox" v-model="model[f.key]"
                               v-bind:id="f.key" v-bind:style="f.input.style ? f.input.style : null" />
                        <textarea v-else-if="f.input.type == 'textarea'" v-model="model[f.key]" v-bind:id="f.key"
                                  v-bind:style="f.input.style ? f.input.style : null" />

                        <cmpConfirmPassword v-else-if="f.input.type == 'confirmPassword'" v-model="model[f.key]"
                                            v-bind:ref="f.key" v-bind:label="f.label">
                        </cmpConfirmPassword>

                        <input v-else-if="f.input.type == 'date'" type="date" v-model="model[f.key]" v-bind:id="f.key" />

                        <input v-else-if="f.input.type == 'datetime'" type="datetime-local" v-model="model[f.key]"
                               v-bind:id="f.key" @change="inputChanged(f.key, $event)" />

                        <cmpSelect v-else-if="f.input.type == 'select'" v-model="model[f.key]" v-bind:id="f.key"
                                   v-bind:options="formBind[f.key]" v-bind:isMultiple="false"
                                   v-bind:moreOptions="f.input.moreOptions">
                        </cmpSelect>


                        <cmpSelect v-else-if="f.input.type == 'selectmulti'" v-model="model[f.key]" v-bind:id="f.key"
                                   v-bind:options="formBind[f.key]" v-bind:isMultiple="true">
                        </cmpSelect>

                        <cmpMultiRowInput v-else-if="f.input.type == 'multirow'" v-model="model[f.key]" v-bind:id="f.key"
                                          v-bind:fields="f.input.fields" v-bind:formFilterFields="f.input.formFilterFields"
                                          v-bind:ref="f.key" @onSearch="multiRowInputOnSearch(f.key, $event)">
                        </cmpMultiRowInput>

                        <b-form-radio-group v-else-if="f.input.type == 'radio'" v-model="model[f.key]" v-bind:id="f.key"
                                            :options="formBind[f.key]" :name="f.key">
                        </b-form-radio-group>

                        <b-form-checkbox-group v-else-if="f.input.type == 'checkbox'" v-model="model[f.key]"
                                               v-bind:id="f.key" :options="formBind[f.key]" :name="f.key">
                        </b-form-checkbox-group>

                        <cmpTextboxSearch v-else-if="f.input.type == 'textboxSearch' && showTextboxSearch(f)"
                                          v-model="model[f.key]" v-bind:id="f.key" v-bind:idField="f.input.idField"
                                          v-bind:objectName="f.input.objectName" v-bind:header="f.label"
                                          v-bind:qryFields="f.input.qryFields" v-bind:displayFields="f.input.displayFields"
                                          v-bind:displayField="f.input.displayField"
                                          v-bind:displayFieldValue="model[f.input.editDisplayField]"
                                          v-bind:formFilterFields="f.input.formFilterFields" v-bind:ref="f.key"
                                          v-bind:addButtonConfig="f.addButtonConfig" @onSearch="textboxSearchOnSearch(f.key, $event)"
                                          @searchResultValue="textboxSearchSearchResultValue(f.key, $event)">
                        </cmpTextboxSearch>

                        <input v-else-if="f.input.type == 'file'" type="file" v-bind:id="f.key" v-bind:name="f.key">

                        <cmpCreate v-else-if="f.input.type == 'form'" v-bind:fields="f.input.fields" v-bind:local=true
                                   v-bind:showFormOnly="true" v-bind:ref="f.key"></cmpCreate>

                        <!-- b-dropdown不支援v-model  -->

                        <div v-else-if="f.input.type === 'dropdownPicture'">
                            <b-dropdown v-bind:id="f.key" text="選擇圖片">
                                <b-dropdown-item-button v-for="(item, index) in f.input.content" :key="index"
                                                        @click="$set(model, f.key, item)">
                                    <img :src="`${s3PublicPath}${item}`" style="width:160px">
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <div v-if="model[f.key]">
                                <p>選擇的圖片:</p>
                                <img :src="`${s3PublicPath}${model[f.key]}`" style="width:160px">
                            </div>
                        </div>

                        <div v-else-if="f.input.type === 'picture'" v-bind:id="f.key">
                            <img :src="`${s3PublicPath}${f.input.content}`" style="width:160px">
                        </div>


                        <label class="error-label" v-if="errorMessage[f.key]">{{ errorMessage[f.key] }}</label>

                    </td>
                </tr>
            </table>


            <div v-for="cf in collapseFields" :key="cf.key" style="text-align:center">
                <br />
                <a data-toggle="collapse" href="#abc" role="button">
                    {{ cf.header }} >
                </a>
                <div class="collapse" id="abc">
                    <table border="0" align="center" class="table2">
                        <tr v-for="f in cf.fields" :key="f.key">
                            <td align="left" valign="top" v-if="showTableHeader(f)">
                                <span v-if="showTableHeader(f)">
                                    {{ f.label }} <span v-if="f.required">*</span>
                                </span>
                            </td>
                            <td align="left" v-if="showTableHeader(f)">
                                <input v-if="f.input.type == 'text'" type="text" v-model="model[f.key]" v-bind:id="f.key"
                                       v-bind:style="f.input.style ? f.input.style : null" />
                                <input v-if="f.input.type == 'readonly' && showReadonly(f)" type="text" v-bind:id="f.key"
                                       v-model="model[f.key]" v-bind:style="f.input.style ? f.input.style : null" readonly />
                                <input v-if="f.input.type == 'number'" type="number" v-model="model[f.key]"
                                       v-bind:id="f.key" v-bind:style="f.input.style ? f.input.style : null" />
                                <input v-else-if="f.input.type == 'password'" type="password" v-model="model[f.key]"
                                       v-bind:id="f.key" v-bind:style="f.input.style ? f.input.style : null" />
                                <input v-else-if="f.input.type == 'boolean'" type="checkbox" v-model="model[f.key]"
                                       v-bind:id="f.key" v-bind:style="f.input.style ? f.input.style : null" />
                                <input v-else-if="f.input.type == 'hidden'" type="hidden" v-model="model[f.key]"
                                       v-bind:id="f.key" v-bind:style="f.input.style ? f.input.style : null" />

                                <textarea v-else-if="f.input.type == 'textarea'" v-model="model[f.key]" v-bind:id="f.key"
                                          v-bind:style="f.input.style ? f.input.style : null" />

                                <cmpConfirmPassword v-else-if="f.input.type == 'confirmPassword'" v-model="model[f.key]"
                                                    v-bind:id="f.key" v-bind:key="f.key" v-bind:label="f.label">
                                </cmpConfirmPassword>

                                <input v-else-if="f.input.type == 'date'" type="date" v-model="model[f.key]"
                                       v-bind:id="f.key" />

                                <input v-else-if="f.input.type == 'datetime'" type="datetime-local" v-bind:id="f.key"
                                       v-model="model[f.key]" />

                                <cmpSelect v-else-if="f.input.type == 'select'" v-model="model[f.key]" v-bind:id="f.key"
                                           v-bind:options="formBind[f.key]" v-bind:isMultiple="false"
                                           v-bind:moreOptions="f.input.moreOptions">
                                </cmpSelect>


                                <cmpSelect v-else-if="f.input.type == 'selectmulti'" v-model="model[f.key]"
                                           v-bind:id="f.key" v-bind:options="formBind[f.key]" v-bind:isMultiple="true">
                                </cmpSelect>

                                <cmpMultiRowInput v-else-if="f.input.type == 'multirow'" v-model="model[f.key]"
                                                  v-bind:id="f.key" v-bind:fields="f.input.fields"
                                                  v-bind:formFilterFields="f.input.formFilterFields" v-bind:ref="f.key"
                                                  @onSearch="multiRowInputOnSearch(f.key, $event)">
                                </cmpMultiRowInput>

                                <b-form-radio-group v-else-if="f.input.type == 'radio'" v-model="model[f.key]"
                                                    v-bind:id="f.key" :options="formBind[f.key]" :name="f.key">
                                </b-form-radio-group>

                                <b-form-checkbox-group v-else-if="f.input.type == 'checkbox'" v-model="model[f.key]"
                                                       v-bind:id="f.key" :options="formBind[f.key]" :name="f.key">
                                </b-form-checkbox-group>

                                <cmpTextboxSearch v-else-if="f.input.type == 'textboxSearch' && showTextboxSearch(f)"
                                                  v-model="model[f.key]" v-bind:id="f.key" v-bind:idField="f.input.idField"
                                                  v-bind:objectName="f.input.objectName" v-bind:header="f.label"
                                                  v-bind:qryFields="f.input.qryFields" v-bind:displayFields="f.input.displayFields"
                                                  v-bind:displayField="f.input.displayField"
                                                  v-bind:displayFieldValue="model[f.input.editDisplayField]"
                                                  v-bind:formFilterFields="f.input.formFilterFields" v-bind:ref="f.key"
                                                  v-bind:addButtonConfig="f.addButtonConfig"
                                                  @onSearch="textboxSearchOnSearch(f.key, $event)"
                                                  @searchResultValue="textboxSearchSearchResultValue(f.key, $event)">
                                </cmpTextboxSearch>

                                <input v-else-if="f.input.type == 'file'" type="file" v-bind:id="f.key" v-bind:name="f.key">

                                <label class="error-label" v-if="errorMessage[f.key]">{{ errorMessage[f.key] }}</label>

                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <table v-if="!showFormOnly" border="0" align="center" class="table2">
                <tr>
                    <td colspan="2" align="center">
                        <br />
                        <div class="btn9" @click.prevent="onSubmit">✔ {{ $t('form.submit') }}</div>
                        <div v-for="b in functionButtons" v-bind:key="b.key" class="btn9" @click.prevent="functionButtonClick($event, b.key)">{{ b.label }}</div>
                        <div class="btn9" @click.prevent="onCancel">✘ {{ $t('form.goBack') }}</div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<style>
    #dropdown-1 .dropdown-menu.show {
        transform: none !important;
        /* 移除 transform 效果 */
        position: absolute !important;
        /* 确保定位为绝对定位 */
        top: 100% !important;
        /* 定位到触发元素的下方 */
        left: 0 !important;
        /* 左对齐于触发元素 */
    }
</style>
<script>
    import cmpTextboxSearch from "@/components/common/cmpTextboxSearch"
    import cmpSelect from "@/components/common/cmpSelect"
    import cmpConfirmPassword from "@/components/common/cmpConfirmPassword"
    import cmpMultiRowInput from "@/components/common/cmpMultiRowInput"
    import cmpLoading from "@/components/common/cmpLoading"
    import cmpMenuButton from "@/components/common/cmpMenuButton"
    import cmpDataHeaderRow from "@/components/common/cmpDataHeaderRow"
    import ApiService from '@/ApiService';
    import cookieManager from '@/util/cookieManager';
    import i18n from '@/i18n'
    import { gmtToLocal, localToGmt } from '@/util/dateutil';
    import { validateEmail } from '@/util/validator';

    var cm = new cookieManager();

    export default {
        name: "cmpCreate",
        components: {
            cmpTextboxSearch,
            cmpSelect,
            cmpConfirmPassword,
            cmpMultiRowInput,
            cmpLoading,
            cmpMenuButton,
            cmpDataHeaderRow
        },
        watch: {
            fields: {
                handler: function (newValue) {

                    this.$nextTick(() => {
                        if (this.local) {
                            this.model = {};
                            this.fieldsData = [];
                            this.fieldsData = newValue;

                            for (let f of this.fieldsData) {
                                if (f.input.hasOwnProperty("content")) {
                                    if (['select'].includes(f.input.type)) {
                                        var temp = [];
                                        f.input.content.map(value => temp.push({ "text": value, "value": value }));
                                        this.$set(this.formBind, f.key, temp);
                                    }
                                    else if (['readonly'].includes(f.input.type)) {
                                        this.$set(this.model, f.key, f.input.content);
                                    }
                                    else if (['boolean'].includes(f.input.type)) {
                                        this.model[f.key] = false;
                                    }
                                }
                            }
                        }
                    })


                },
                deep: true
            },
            model: {
                handler: function (newValue) {
                    this.$emit('input', newValue);
                },
                deep: true
            },
        },
        data() {
            return {
                loading: false,     // 是不是正在從後台讀取資料
                model: {},          // bind 到這個 form 的 Object
                action: '',         // C=新增, U=修改
                formBind: {},       // bind 到各別 input 的值，例如 combobox, checkbox, radio button
                errorMessage: {},   // form 驗證失敗時要顯示的錯誤訊息
                fieldsData: [],      // TODO: 和 this.$props.fields 一樣?
                s3PublicPath: process.env.VUE_APP_S3PUBLIC,
                test: ""
            };
        },
        props: {
            name: String,       // table 的名字, 也是 Controller 的名字
            subPath: String,    // ApiService subPath
            pkColumn: String,   // table primary key 的名字
            fields: { type: Array, default: () => [] }, // 要顯示的欄位
            parent: Object,     // 母畫面的 information
            header: String,     // 顯示的標題
            query: Object,      // 要更新到資料庫但是不顯示在畫面上的欄位
            collapseFields: { type: Array, default: () => [] },  // 使用著可以隱藏的欄位
            local: { type: Boolean, default: false },       // 要不要從資料庫讀資料
            showFormOnly: { type: Boolean, default: false }, // 要不要只顯示 form，不顯示 header 和按鈕
            navButtons: { type: Array, default: () => [] },  // 顯示在上面的 nav buttons
            goBackAfterCreate: { type: Boolean, default: true }, // 新增修改完成後要不要返回前一頁
            functionButtons: { type: Array, default: () => [] },  // 顯示在下面的功能按鈕
        },
        computed: {
            isUpdate: function () {
                return this.action == 'U';
            }
        },
        mounted() {

        },
        async created() {

            this.fieldsData = await this.$props.fields;

            if (this.local) {
                for (let f of this.fieldsData) {
                    if (f.input.hasOwnProperty("content")) {

                        if (['select'].includes(f.input.type)) {
                            var temp = [];
                            f.input.content.map(value => temp.push({ "text": value, "value": value }));
                            this.$set(this.formBind, f.key, temp);
                        }
                        else if (['readonly'].includes(f.input.type)) {
                            this.$set(this.model, f.key, f.input.content);
                        }
                        else if (['boolean'].includes(f.input.type)) {
                            this.model[f.key] = false;
                        }
                    }
                }
            }
            else {
                this.model.id = this.$route.query.id
                this.action = this.$route.query.action

                // 有 ID, 從資料庫讀這筆資料
                if (this.model.id) {
                    await this.get(this.model.id);
                }
                // 抓現在登入 user 的資料
                else if (this.query && this.query['current']) {
                    await this.getCurrent();

                    if (this.query.action) {
                        this.action = this.query.action;
                    }
                }
                // 抓parent 的資料
                else if (this.parent && this.parent.key in this.$route.query) {
                    this.model[this.parent.key] = this.$route.query[this.parent.key];
                }

                // 不同要做不同的 initialize
                for (let f of this.$props.fields) {
                    // 這些 input type 需要從資料庫讀取他們的選項
                    if (['select', 'radio', 'checkbox', 'selectmulti'].includes(f.input.type)) {
                        var res = await this.getSelectOptions(f.input.objectName, f.input.idField, f.input.displayField, f.input.filterField, f.input.filterValue)

                        this.$set(this.formBind, f.key, res)

                        if (f.input.type == 'checkbox') {
                            if (!this.model[f.key]) {
                                this.model[f.key] = []
                            } else {
                                this.model[f.key] = this.model[f.key].split(',')
                            }
                        }

                    }
                    else if (['readonly'].includes(f.input.type)) {

                        // 新增的話要把 readonly 的值讀出來
                        if (!this.isUpdate && this.$route.query[f.input.idField]) {
                            let rApi = new ApiService(f.input.objectName);
                            let response = await rApi.get(this.$route.query[f.input.idField], i18n.locale);

                            this.$set(this.model, f.key, response[f.input.displayField]);
                            this.$set(this.model, f.input.modelField, response[f.input.responseField]);
                        }
                    }
                    else if (['datetime'].includes(f.input.type)) {
                        // 從 GMT 轉成 local
                        let localDateString = gmtToLocal(this.model[f.key]);

                        this.$set(this.model, f.key, localDateString);

                    }
                    else if (['date'].includes(f.input.type)) {
                        // 從 GMT 轉成 local
                        if (this.model[f.key]) {
                            let localDateString = gmtToLocal(this.model[f.key]);
                            localDateString = localDateString.split('T')[0];
                            this.$set(this.model, f.key, localDateString);
                        }
                    }
                    else if (['textboxSearch'].includes(f.input.type)) {
                        if (f.default && !this.isUpdate) {
                            // 顯示目前 User 的資料
                            if (f.default.type == "currentUser") {
                                const profile = this.$root.$data.store.getProfile();
                                this.model[f.key] = profile.UserId;
                                this.model[f.input.idField] = profile.UserId;
                                this.model[f.input.editDisplayField] = profile.Name;

                            }
                        }
                    }
                    else if (['confirmPassword'].includes(f.input.type)) {
                        // 修改時密碼不要顯示
                        if (this.isUpdate) {
                            this.model[f.key] = "";
                        }
                    }
                    else if (['hidden'].includes(f.input.type)) {
                        this.model[f.key] = f.default;
                    }
                    else if (['form'].includes(f.input.type)) {
                        try {
                            let j = JSON.parse(this.model[f.key]);
                            this.$refs[f.key][0].setModel(j);
                        }
                        catch (err) {

                        }
                    }
                }

                // 從 local storage 讀取 model
                const m = this.$root.$data.store.getLocalStorage(`form_${this.name}`);
                if (m) {
                    this.model = JSON.parse(m);
                }
            }

        },
        methods: {
            /*
             *  從後台讀取這筆資料
             *  @param {number} id 這筆資料的 id
             */
            async get(id) {
                this.loading = true

                try {
                    const api = new ApiService(this.name);
                    this.model = await api.get(id, i18n.locale)
                    this.model['id'] = id;

                } finally {
                    this.loading = false
                }
            },
            /*
             *  從後台讀取這筆資料
             */
            async getCurrent() {
                this.loading = true

                try {
                    const api = new ApiService(this.name);
                    this.model = await api.getCurrent()
                    this.model['id'] = this.model[this.pkColumn];

                } finally {
                    this.loading = false
                }
            },
            /*
             *  儲存這筆資料到資料庫
             *  @param {object} event 呼叫這個 event 的參數
             */
            async createRecord(event) {
                // 驗證 form
                if (!this.validate())
                    return false;

                if (event)
                    event.target.classList.add('disabled-link');

                // 有沒有上傳的 module, 如果有, 要 post form
                let hasFileControl = false;
                let requestData = JSON.parse(JSON.stringify(this.model));

                //  Call API 前每個欄位要做不同的處理
                for (let f of this.$props.fields) {
                    if (f.input.type == 'checkbox') {
                        if (f.key in this.model) {
                            requestData[f.key] = Object.values(requestData[f.key]).join(',')
                        }
                    }
                    else if (f.input.type == 'selectmulti') {
                        if (f.key in this.model) {
                            if (requestData[f.key]) {
                                requestData[f.key] = Object.values(requestData[f.key]).join(',')
                            }
                        }
                    }
                    else if (['date', 'datetime'].includes(f.input.type)) {
                        let gmtDateString = localToGmt(this.model[f.key]);

                        requestData[f.key] = gmtDateString;

                    }
                    else if (f.input.type == "file") {
                        hasFileControl = true;
                        let fileControl = document.querySelector('#' + f.key);
                        requestData[f.key] = fileControl.files[0];
                    }
                    else if (f.input.type == "multirow") {
                        //以下程式片段會造成requestData清掉type == multirow的資料
                        // requestData[f.key] = requestData[f.key].filter(function (item) {
                        //     if (item[f.input.fields[0].key])
                        //         return true
                        //     else
                        //         return false
                        // });

                    }
                    else if (f.input.type == "form") {
                        requestData[f.key] = JSON.stringify(this.$refs[f.key][0].getModel());

                    }
                }

                if (!requestData.id) {
                    requestData.id = -1;
                }

                try {
                    const api = new ApiService(this.name);

                    // 如果有檔桉的話, 要 POST form
                    if (hasFileControl) {
                        var formData = new FormData();

                        for (let key in requestData) {
                            if (requestData[key]) {
                                formData.append(key, requestData[key]);
                            }
                        }

                        if (this.isUpdate) {
                            await api.updateForm(requestData.id, formData);
                        } else {
                            await api.postForm(formData)
                        }
                    }
                    else {
                        if (this.isUpdate) {
                            await api.update(requestData.id, requestData)
                        } else {
                            requestData[this.pkColumn] = 0;
                            const response = await api.create(requestData, this.subPath);
                            this.model[this.pkColumn] = response[this.pkColumn];
                            this.model["id"] = response[this.pkColumn];
                        }
                    }
                }
                finally {
                    if (event)
                        event.target.classList.remove('disabled-link');
                }

                this.$emit("recordCreated", this.model);

                return true;
            },
            /*
             *  Submit 按鈕 click event handler
             *  @param {object} event 呼叫這個 event 的參數
             */
            async onSubmit(event) {

                const result = await this.createRecord(event);

                // 更新失敗
                if (!result) {
                    return;
                }

                // 清掉 form 的資料
                this.model = {}

                if (this.goBackAfterCreate) {
                    this.onCancel();
                }
            },
            /*
             *  返回前一頁
             */
            onCancel() {
                this.$emit("onCancel");

                if (this.goBackAfterCreate) {
                    this.goBack();
                }
            },
            /*
             *  功能按鈕的 click event handler
             *  @param {string} key 功能按鈕的名字
             */
            async functionButtonClick(event, key) {
                const result = await this.createRecord(event);

                if (result) {
                    this.$emit("functionButtonClick", key, this.model);

                    // 清掉 form 的資料
                    this.model = {}
                }
            },
            /*
             *  返回前一頁
             */
            goBack() {
                var routerParam = this.getRouterParam();
                this.$root.$data.store.deleteLocalStorage(`form_${this.name}`);
                this.$router.push(routerParam);
            },
            /*
             *  建立回到 lst 畫面 的 route 的參數
             */
            getRouterParam() {
                var routerName = this.$root.$data.store.getRouteName(this.name, "lst");

                var routerParam = { name: routerName };
                routerParam['query'] = {};

                // 如果有返回的 override
                if (this.$route.params.backComponent) {
                    routerName = this.$route.params.backComponent;
                    routerParam['query'] = this.$route.params.query;
                }


                routerParam['name'] = routerName;


                if (this.parent && Object.keys(this.parent).length > 0) {
                    routerParam['query'][this.parent.key] = this.$route.query[this.parent.key]
                }


                let cookieQuery = cm.getRouteQuery(routerName);
                if (cookieQuery) {
                    for (const key in cookieQuery) {
                        if (cookieQuery[key]) {
                            routerParam['query'][key] = cookieQuery[key];
                        }
                    }
                }

                return routerParam;
            },
            /*
             *  從後台讀取像 combobox, radiobutton 選項的值
             *  @param {string} objectName 要從資料庫讀取選項的 table 名稱
             *  @param {string} idField ID 欄位的名稱
             *  @param {string} displayField 要顯示欄位的名稱
             *  @param {string} filterField 要搜尋的欄位名稱
             *  @param {string} filterValue 要搜尋的欄位的值
             */
            async getSelectOptions(objectName, idField, displayField, filterField, filterValue) {
                var data = {}
                data[filterField] = filterValue
                data['language'] = i18n.locale;

                const api = new ApiService(this.name);
                var res = await api.getSelectOptions(objectName, data);

                var result = res.Data.map(function (row) {
                    let rowText = "";
                    if (Array.isArray(displayField)) {
                        for (let v of displayField) {
                            rowText = rowText + " " + row[v];
                        }
                    }
                    else {
                        rowText = row[displayField];
                    }

                    return {
                        value: row[idField], text: rowText
                    }
                });

                return result;
            },
            /*
             *  驗證這個 form，有錯誤時顯示錯誤訊息
             */
            validate() {
                this.errorMessage = {};
                for (let f of this.$props.fields) {
                    if (f.input.type == "confirmPassword") {
                        if (!this.$refs[f.key][0].validate()) {
                            this.$set(this.errorMessage, f.key, i18n.t('errorMessage.differentPassword'))
                        }
                    }
                    else if (f.required && f.input.type != "readonly") {
                        if (!this.model[f.key]) {
                            let errorMsg = i18n.t('errorMessage.requiredField').replace("@col@", f.label);
                            this.$set(this.errorMessage, f.key, errorMsg)
                        }
                    }
                    else if (f.input.format == "email") {
                        if (this.model[f.key] && !validateEmail(this.model[f.key])) {
                            let errorMsg = i18n.t('errorMessage.emailFormat').replace("@col@", f.label);
                            this.$set(this.errorMessage, f.key, errorMsg)
                        }
                    }
                }

                return Object.keys(this.errorMessage).length == 0;
            },
            /*
             *  要不要顯示這個欄位的標題
             *  @param {string} field 欄位的名稱
             */
            showTableHeader(field) {
                if (field.input.type == 'textboxSearch') {
                    return this.showTextboxSearch(field);
                }

                if (field.input.type == 'readonly') {
                    return this.showReadonly(field);
                }

                if (field.show || !field.hasOwnProperty("show"))
                    return true;

                else
                    return false;
                //return true;

            },
            /*
             *  要不要顯示這個 textboxSearch 欄位的標題，修改或有從 querystring 傳值進來就不顯示（不讓使用者輸入）
             *  @param {string} field 欄位的名稱
             */
            showTextboxSearch(field) {
                if (field.hideIfQuery) {
                    if (this.isUpdate)
                        return false;

                    if (this.$route.query[field.queryString]) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            },
            /*
             *  要不要顯示這個 readonly 欄位的標題，修改或有從 querystring 傳值進來就顯示（不讓使用者輸入）
             *  @param {string} field 欄位的名稱
             */
            showReadonly(field) {
                if (this.isUpdate)
                    return true;

                if (this.$route.query[field.input.idField]) {
                    return true;
                }

                return false;
            },
            /*
             *  textboxSearch 需要用其他的 form field 做一些 filter, filter 的邏輯放在 parent,
             *  parent 跑完 filter 的邏輯要 call textboxSearchParent 把要 filter 的再傳回這個 module
             *  @param {string} caller 這個 textboxSearch 欄位的名稱
             *  @param {array} searchFields 要用來 filter 的欄位名稱
             */
            textboxSearchOnSearch(caller, searchFields) {
                this.$emit("textboxSearchOnSearch", caller, searchFields, this.model);
            },
            /*
             *  parent 接到 textboxSearchOnSearch callback，做了一些 filter 後 call 進來的
             *  @param {string} caller 這個 textboxSearch 欄位的名稱
             *  @param {array} formValue filter 過的值，要搜尋的 keywords
             */
            textboxSearchParent(caller, formValue) {
                this.$refs[caller][0].parentSearch(formValue);
            },
            /*
             *  textboxSearch 搜尋完後傳回的值
             *  @param {string} caller 這個 textboxSearch 欄位的名稱
             *  @param {array} searchResult 搜尋完後傳回的值
             */
            textboxSearchSearchResultValue(caller, searchResult) {
                //console.log('textboxSearchSearchResultValue');
                const f = this.fields.find(t => t.key == caller);

                if (f) {
                    this.model[f.input.editDisplayField] = searchResult;
                }
                //console.log(f, this.model);
            },
            /*
             *  multiRowInput 欄位需要用其他的 form field 做一些 filter, filter 的邏輯放在 parent
             *  @param {string} caller 這個 multiRowInput 欄位的名稱
             *  @param {array} eventArgs 要用來 filter 的欄位
             */
            multiRowInputOnSearch(caller, eventArgs) {
                ////console.log('cmpCreate', 'multiRowInputOnSearch', caller, eventArgs);
                let formValue = {};

                for (let s of eventArgs['searchFields']) {
                    let modelVal = this.model[s];
                    if (modelVal) {
                        if (this.name == "Activity") {
                            if (s == "ActivityType") {
                                if (modelVal == "121") {
                                    formValue[s] = "121";
                                }
                                else if (modelVal == "122") {
                                    formValue[s] = "1222";
                                } else if (modelVal == "12Z") {
                                    formValue[s] = "12Z";
                                }
                                else {
                                    formValue[s] = modelVal;
                                }
                            }

                        }
                        else {
                            formValue[s] = modelVal;
                        }
                    }
                }

                this.$refs[caller][0].parentSearch(eventArgs['caller'], formValue);
            },
            /*  有 input 改變, fire event to parent
             *  @param {string} caller 改變 input field 的名字
             *  @param {object} event input 目前不需要
             */
            inputChanged(caller, event) {
                this.$emit("inputChanged", caller, this.model);
            },
            /*  Nav Button 按後的 click event
             *  @param {object} event 觸發這個 event 的 component
             *  @param {string} caller button 的名字
             *  @param {string} f button 的功能
             *  @param {string} redirectComponent 要 redirect 到的 component 名字
             */
            async navButtonsClick(caller, f, redirectComponent) {
                if (f == "redirect") {
                    // 目前的 model 要存到 local storage
                    this.$root.$data.store.setLocalStorage(`form_${this.name}`, JSON.stringify(this.model));

                    // Redirect
                    var routerParam = { name: redirectComponent };
                    routerParam['params'] = {};
                    routerParam['params']['backComponent'] = `ins${this.name}`;
                    routerParam['params']['query'] = this.$route.query;
                    console.log('route', routerParam);

                    this.$router.push(routerParam);
                }
                else {
                    const result = await this.createRecord();

                    if (result) {
                        this.$emit("functionButtonClick", caller, this.model);

                        // 清掉 form 的資料
                        this.model = {}

                        this.$root.$data.store.deleteLocalStorage(`form_${this.name}`);
                    }
                }
            },
            /*  修改 model 的值
             *  @param {string} key 要修改的 key
             *  @param {string} value 要修改值
             */
            updateModel(key, value) {
                this.model[key] = value;
            },
            /*
             *  Return 這個 model
             */
            getModel() {
                return this.model;
            },
            /*
             *  Set 這個 model
             *  @param {object} m 要 set 的 model
             */
            setModel(m) {
                this.model = m;
            }
        }
    }
</script>