<!-- 用 table 的方式顯示多筆資料 -->
<template>
    <div>
        <cmpLoading v-if="loading"></cmpLoading>
        
        <cmpDataHeaderRow v-if="parent && parent.dataHeaders" 
                          v-bind:name="parent.name"
                          v-bind:pkColumn="parent.key"
                          v-bind:dataHeaders="parent.dataHeaders"></cmpDataHeaderRow>

        <b-table id="tblRecords"
                 :items="records"
                 :fields="fields"
                 :children="children"
                 :name="name"

                 small
                 sort-icon-left
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 style="width:80%; margin:0 auto"
                 thead-class="align-top"
                 tbody-tr-class="align-top">

            <template v-for="field in fields" v-slot:[`cell(${field.key})`]="data">
                <div v-if="field.key === 'Operation'" v-bind:key="field.key" style="min-width:200px">

                    <div v-if="allTableFuncs && allTableFuncs.length > 0" style="display:inline; position:relative;">

                        <div v-for="child in allTableFuncs" :key="child.icon + Math.random().toString(16).slice(2)" style="display:inline; position:relative;">
                            <cmpMenuButton v-if="child.name == 'copyNew'" v-bind:label="$t('form.copyNew')"
                                           v-bind:imagePath="'assets/icons/IcnCopyNew.png'"
                                           v-bind:hasPrivilege="privileges && privileges['Create']"
                                           @click="$router.push(getCopyNewRouterLink(name, data.item[pkColumn]))">
                            </cmpMenuButton>

                            <cmpMenuButton v-else-if="child.name == 'detail'" v-bind:label="$t('form.detail')"
                                           v-bind:imagePath="'assets/icons/IcnGresses.png'"
                                           v-bind:hasPrivilege="privileges && privileges['Read']"
                                           @click="$router.push(getDetailRouterLink(children.name, children.key, data.item[pkColumn]))">
                            </cmpMenuButton>

                            <cmpMenuButton v-else-if="child.name == 'rec'" v-bind:label="$t('form.inspect')"
                                           v-bind:imagePath="'assets/icons/IcnGresses.png'"
                                           v-bind:hasPrivilege="privileges && privileges['Read']"
                                           @click="$router.push(getRecRouterLink(name, data.item[pkColumn]))">
                            </cmpMenuButton>

                            <cmpMenuButton v-else-if="child.name == 'edit'" v-bind:label="$t('form.modify')"
                                           v-bind:imagePath="'assets/icons/IcnEdit.png'" v-bind:id="`edit${data.index}`"
                                           v-bind:hasPrivilege="privileges && privileges['Update']"
                                           @click="$router.push(getUpdRouterLink(name, data.item[pkColumn]))">
                            </cmpMenuButton>

                            <cmpMenuButton v-else-if="child.name == 'delete'"
                                           v-bind:imagePath="'assets/icons/IcnTrash.png'" v-bind:id="`delete${data.index}`"
                                           v-bind:hasPrivilege="privileges && privileges['Delete']"
                                           v-bind:label="$t('form.delete')"
                                           @click="deleteRecord(data.item[pkColumn])">
                            </cmpMenuButton>

                            <cmpMenuButton v-else
                                           v-bind:imagePath="`assets/icons/${child.icon}`"
                                           v-bind:hasPrivilege="privileges && privileges['Delete']"
                                           v-bind:label="child.tooltip"
                                           @click="moreFuncClick(child.function, data.item[child.function.key], data.item)">
                            </cmpMenuButton>

                        </div>

                    </div>

                    <div v-if="allTableFuncsMore && allTableFuncsMore.length > 0" style="display:inline; position:relative;">
                        <img v-bind:id="`flip${data.index}`" src="@/assets/icons/IcnMore.png" @click="toggleMenu(`moreedit${data.index}`)">
                        <div v-bind:id="`moreedit${data.index}`" class="moreedit">
                            <div v-for="child in allTableFuncsMore" :key="child.icon + Math.random().toString(16).slice(2)" class="tooltip_right">
                                <a style="cursor:pointer" @click="moreFuncClick(child.function, data.item[child.function.key], data.item)"><img :src="require(`@/assets/icons/${child.icon}`)"></a>
                                <span class="tooltiptext_right">{{child.tooltip}}</span>
                            </div>
                            <br><br>

                        </div>
                    </div>
                </div>
                <div v-else-if="field.type === 'date'" v-bind:key="field.key" v-bind:style="field.style ? field.style : null">
                    {{ formatDate(data.value) }}
                </div>
                <div v-else-if="field.type === 'datetime'" v-bind:key="field.key" v-bind:style="field.style ? field.style : null">
                    {{ formatDatetime(data.value) }}
                </div>
                <div v-else-if="field.type === 'checkbox'" v-bind:key="field.key" v-bind:style="field.style ? field.style : null">
                    <input type="checkbox" v-bind:checked="data.value" onclick="return false;" />
                </div>
                <div v-else-if="field.key === 'ActivityStatusLight'" v-bind:key="field.key" v-bind:style="field.style ? field.style : null">
                    <img v-if="getActivityStatusLight(data.item)" v-bind:src="require(`@/${getActivityStatusLight(data.item)}`)" width="15" height="15" />
                </div>
                <div v-else-if="field.key === 'ActivityLogLight'" v-bind:key="field.key" v-bind:style="field.style ? field.style : null">
                    <img v-if="getActivityLogLight(data.item)" v-bind:src="require(`@/${getActivityLogLight(data.item)}`)" width="15" height="15" />
                </div>
                <div v-else v-bind:key="field.key" v-bind:style="field.style ? field.style : null">
                    {{data.value}}
                </div>
            </template>

        </b-table>
        <br />
        <div align="center">
            <a href="#" @click.prevent="pageChangeHandler(1)"><img src="@/assets/icons/IcnPage1.png"></a>
            <a href="#" @click.prevent="pageChangeHandler(currentPage-1);"><img src="@/assets/icons/IcnPage2.png"></a>
            <a href="#" @click.prevent="pageChangeHandler(currentPage+1);"><img src="@/assets/icons/IcnPage3.png"></a>
            <a href="#" @click.prevent="pageChangeHandler(totalPages);"><img src="@/assets/icons/IcnPage4.png"></a>
            <br />
            <span>{{ $t('form.numberOfRecords').replace("@num@", numberOfRecords).replace("@maxnum@", "10")  }}</span>
            <span v-if="pageText" style="margin-left:5px">{{pageText}}</span>
        </div>

        <br /><br />

        <div v-if="showBackButton" style="text-align:center; margin:0 auto">
            <div class="btn9" v-if="parent && Object.keys(parent).length > 0">
                <router-link :to="getCancelRouterLink()">✘ {{ $t('form.goBack') }}</router-link>
            </div>
        </div>

    </div>
</template>

<style>
    .sr-only {
        display: none;
        width: 0px
    }
</style>

<script>
    import i18n from '@/i18n'
    import cmpMenuButton from "@/components/common/cmpMenuButton"
    import cmpLoading from "@/components/common/cmpLoading"
    import cmpDataHeaderRow from "@/components/common/cmpDataHeaderRow"
    import ApiService from '@/ApiService';
    import cookieManager from '@/util/cookieManager';
    import { gmtToLocal } from '@/util/dateutil';

    import $ from 'jquery'

    var api = undefined
    var cm = new cookieManager();

    export default {
        components: {
            cmpMenuButton, cmpLoading, cmpDataHeaderRow
        },
        data() {
            return {
                loading: false,         // 是不是正在從後台讀取資料
                records: [],            // 顯式在 table 的資料
                pageSize: 0,            // 每個分頁顯示資料的比數
                totalPages: 0,          // 分頁的總數
                currentPage: 1,         // 目前分頁的頁數
                numberOfRecords: "0",   // 顯示第幾比的資料
                qryModel: {},           // bind 到 query string 的 object
                sortBy: '',             // table 要排序的欄位名稱
                sortDesc: false,        // table 的排序
                allTableFuncs: [],      // prop.rec 和 moreTableFuncs 全部合在一起, 前4個按鈕
                allTableFuncsMore: []   // prop.rec 和 moreTableFuncs 全部合在一起, 更多功能的按鈕
            };
        },
        props: {
            name: String,               // 資料庫 table 的名稱，也是 Controller 的名字
            controllerName: { type: String, default: "" },  // 要 override 這個畫面要 call 的 Controller 的名字
            pkColumn: String,           // 資料庫 table PK 的名稱
            fields: Array,              // 要顯示在 table 的欄位
            qryFields: Array,           // 要顯示在搜尋畫面的欄位
            children: Object,           // 子畫面的 information
            parent: Object,             // 設定的 parent，用來決定回前一頁和到其他畫面的邏輯
            tableFuncs: Array,          // 要顯示的功能按鈕
            moreTableFuncs: { type: Array, default: () => [] },     // 更多功能的按鈕
            privileges: Object,         // 使用者的權限
            query: Object,              // Default 的搜尋條件
            showBackButton: { type: Boolean, default: true },       // 要不要顯示 "返回" 按鈕
            cookieQueryFields: { type: Array, default: () => [] },  // 在這個要顯示資料時, 要從 cookie 裡看有沒有值, 如果有, 要把 cookie 的值一起做搜尋 
            pageText: String,           // 顯示在頁數旁邊的字
        },
        async created() {
            
            if (this.controllerName) {
                api = new ApiService(this.controllerName)
            }
            else {
                api = new ApiService(this.name)
            }

            // 把 tableFuncs 和 moreTableFuncs 合在一起
            for (const func of this.tableFuncs) {

                if (func == "copyNew") {
                    this.allTableFuncs.push(
                        {
                            icon: "IcnCopyNew.png", tooltip: i18n.t('form.copyNew'), name: func
                        });
                }
                else if (func == "detail") {
                    this.allTableFuncs.push(
                        {
                            icon: "IcnGresses.png", tooltip: i18n.t('form.detail'), name: func
                        });
                }
                else if (func == "rec") {
                    this.allTableFuncs.push(
                        {
                            icon: "IcnGresses.png", tooltip: i18n.t('form.inspect'), name: func
                        });
                }
                else if (func == "edit") {
                    this.allTableFuncs.push(
                        {
                            icon: "IcnEdit.png", tooltip: i18n.t('form.modify'), name: func
                        });
                }
                else if (func == "delete") {
                    this.allTableFuncs.push(
                        {
                            icon: "IcnTrash.png", tooltip: i18n.t('form.delete'), name: func
                        });
                }
            }

            // MoreTableFuncs 如果沒有權限就不顯示
            for (const func of this.moreTableFuncs) {
               // if (func["key"] && this.$root.$data.store.hasPrivilege(func["key"], "Read")) {
                    this.allTableFuncs.push(func);
               // }
            }

            if (this.allTableFuncs.length > 5) {
                this.allTableFuncsMore = this.allTableFuncs.slice(4, this.allTableFuncs.length);
                this.allTableFuncs = this.allTableFuncs.slice(0, 4);
            }

            this.getAll()
        },
        methods: {
            /*
             *  從後台讀取要顯示的資料
             */
            async getAll() {
                this.qryModel = JSON.parse(JSON.stringify(this.$route.query))
                var sort = ''
                if (this.sortBy) {
                    sort += this.sortBy
                    if (this.sortDesc) {
                        sort += ' desc'
                    } else {
                        sort += ' asc'
                    }
                }
                
                //const routeName = Array.isArray(this.$route.name) ? this.$route.name[0] : this.$route.name;

                // 有 querystring 就不用 default 的搜尋條件
                if (Object.keys(this.$route.query).length === 0 && this.query ) {
                    for (const key in this.query) {
                        const queryValue = this.query[key];

                        if (queryValue instanceof Array) {
                            this.qryModel[key] = this.query[key].join(',');
                        }
                        else {
                            this.qryModel[key] = this.query[key];
                        }
                    }
                }

                // 看要不要從 cookie 加搜尋條件
                for (let i = 0; i < this.cookieQueryFields.length; i++) {
                    
                    let cookieKey = this.cookieQueryFields[i];

                    // 搜尋條件己經有了就不用再加了
                    if (!(cookieKey in this.qryModel)) {
                        let cookieValue = this.$cookies.get(cookieKey);

                        if (cookieValue) {
                            this.qryModel[cookieKey] = cookieValue
                        }
                    }
                    
                }

                // 把搜尋條件存到 cookie, 有些返回功能需要
                cm.setRouteQuery(this.$route.name, JSON.stringify(this.$route.query));

                this.qryModel['Sort'] = sort
                this.qryModel['MaxResultCount'] = 10

                // Language
                this.qryModel["Language"] = i18n.locale;
                this.loading = true

                try {
                    var res = await api.getAll(JSON.parse(JSON.stringify(this.qryModel)))
                    this.records = res["Data"]
                    this.pageSize = res["PageSize"]
                    this.totalPages = res["TotalPages"]
                    this.numberOfRecords = res["NumberOfRecords"].toLocaleString('en-US', { maximumFractionDigits: 0 });
                    this.currentPage = res["PageNumber"]

                    // 編號
                    for (let i = 0; i < this.records.length; i++) {
                        this.records[i]['No'] = (i + 1) + (this.currentPage - 1) * 10;
                    }

                } finally {
                    this.loading = false
                }
            },
            /*
             *  刪除資料
             *  @param {number} id 這筆資料的 id
             */
            async deleteRecord(id) {
                if (confirm(i18n.t('form.confirmDelete') + '?')) {
                    try {
                        await api.delete(id);
                    }
                    catch (err) {
                        alert(i18n.t('errorMessage.dbDeleteError'));
                    }
                    await this.getAll()
                }
            },
            /*
             *  建到拷貝新增畫面的 route
             *  @param {string} name ins 畫面的名稱
             *  @param {number} id 這筆資料的 id
             */
            getCopyNewRouterLink(name, id) {
                var routerName = this.$root.$data.store.getRouteName(name, "ins");
                var result = { name: routerName, query: { id: id, action:'C' } }
                
                if (this.parent && Object.keys(this.parent).length > 0) {
                    result['query'][this.parent.key] = this.qryModel[this.parent.key]
                }
                return result
            },
            /*
             *  建到新增修改畫面的 route
             *  @param {string} name ins 畫面的名稱
             *  @param {number} id 這筆資料的 id
             */
            getUpdRouterLink(name, id) {
                var routerName = this.$root.$data.store.getRouteName(name, "ins");
                var result = { name: routerName, query: { id: id, action:'U' } }
                if (this.parent && Object.keys(this.parent).length > 0) {
                    result['query'][this.parent.key] = this.qryModel[this.parent.key]
                }
                return result
            },
            /*
             *  建到 rec 畫面的 route
             *  @param {string} name rec 畫面的名稱
             *  @param {number} id 這筆資料的 id
             */
            getRecRouterLink(name, id) {
                var routerName = this.$root.$data.store.getRouteName(name, "rec");
                var result = { name: routerName, query: { id: id } }
                if (this.parent && Object.keys(this.parent).length > 0) {
                    result['query'][this.parent.key] = this.qryModel[this.parent.key]
                }
                
                return result
            },
            /*
             *  建到子畫面的 route
             *  @param {string} childrenName 子畫面的名稱
             *  @param {string} childrenKey 子畫面 key 的名稱
             *  @param {number} id 子畫面 key id
             */
            getDetailRouterLink(childrenName, childrenKey, id) {
                var routerName = this.$root.$data.store.getRouteName(childrenName, "lst");
                var result = { name: routerName, query: { [`${childrenKey}`]: id } }

                return result
            },
            /*
             *  建取消的 route
             */
            getCancelRouterLink() {
                var routerName = this.$root.$data.store.getRouteName(this.parent.name, "lst");
                var result = { name: routerName }
                result['query'] = {};

                if (this.parent.backIncludeQuery) {
                    for (const queryKey of this.parent.backIncludeQuery) {
                        result['query'][queryKey] = this.qryModel[queryKey];
                    }
                }

                let cookieQuery = cm.getRouteQuery(routerName);
                if (cookieQuery) {
                    for (const key in cookieQuery) {

                        if (cookieQuery[key]) {
                            result['query'][key] = cookieQuery[key];
                        }
                    }
                }

                return result;
            },
            /*
             *  更多功能按鈕 fire 的 event
             *  @param {object} funcInfo 功能按鈕的資訊
             *  @param {number} id 這筆資料的 id
             *  @param {object} rowItem 這筆資料
             */
            moreFuncClick(funcInfo, id, rowItem) {

                if (funcInfo["type"] == "route") {
                    // 要把 Key 存到 cookie 裡
                    if (funcInfo["cookie"]) {
                        this.$cookies.set(funcInfo["key"], id);
                    }

                    var routerName = this.$root.$data.store.getRouteName(funcInfo['name']);
                    var queryString = { [`${funcInfo['key']}`]: id }
                    // 要加到 queryString 裡的
                    if (funcInfo.queryString) {
                        for (const qsKey of funcInfo.queryString) {
                            if (rowItem[qsKey]) {
                                queryString[qsKey] = rowItem[qsKey];
                            }
                        }
                    }

                    var result = { name: routerName }
                    result['query'] = queryString

                    this.$router.push(result);
                }
                else if (funcInfo["type"] == "callback") {
                    // Find the record and pass it back
                    var record = this.records.filter(x => x[this.pkColumn] == id);
                    if (record.length > 0) {
                        this.$emit(funcInfo["name"], id, record[0]);

                        this.$emit('callback', funcInfo["name"], id, record[0]);
                    }
                }
            },
            /*
             *  跳頁的 event
             *  @param {number} newPage 新的頁數
             */
            pageChangeHandler(newPage) {

                if (newPage < 1)
                    return;
                if (newPage > this.totalPages)
                    return;

                var result = { name: this.$route.name }
                this.qryModel['PageNumber'] = newPage
                result['query'] = this.qryModel

                this.$router.push(result, () => { })
            },
            /*
             *  Toggle 更多功能的 menu
             *  @param {object} item 這筆資料
             */
            toggleMenu(item) {
                $("#" + item).slideToggle("slow");
            },
            /*
             *  把日期從 GMT 轉到 Local
             *  @param {date} d 要轉換的日期
             */
            formatDate(d) {

                const localDate = gmtToLocal(d);
                if (localDate) {
                    return localDate.split('T')[0];
                }

                return null;
            },
            /*
             *  把日期和時間從 GMT 轉到 Local
             *  @param {date} d 要轉換的日期
             */
            formatDatetime(d) {

                const localDate = gmtToLocal(d);
                if (localDate) {
                    return localDate.replace('T', ' ');
                }

                return null;
            },
            /*
             *  傳回要顯示的燈
             *  @param {object} item 這筆資料
             */
            getActivityStatusLight(item) {
                if (item['ActivityStatus'] == "2") {
                    if (!item['ActivityStartDate']) {
                        return "assets/icons/IcnRball.png";
                    }

                    let startDate = new Date(item['ActivityStartDate']);
                    let currentDate = new Date();
                    let dayDiff = (currentDate - startDate) / 86400000;

                    if (dayDiff < 3) {
                        return "assets/icons/IcnGball.png"
                    }
                    else if (dayDiff < 7) {
                        return "assets/icons/IcnYball.png"
                    }

                    return "assets/icons/IcnRball.png";
                }

                return "";
            },
            /*
             *  傳回要顯示的燈
             *  @param {object} item 這筆資料
             */
            getActivityLogLight(item) {
                if (item["ActivityStage"] == "9.成大OK" && item["ActivityStatus"] == 0) {
                    return "assets/icons/IcnGball.png";
                }
                return "";
            },
            gmtToLocal
        },
        watch: {
            '$route.params': 'getAll',
            'sortBy': 'getAll',
            'sortDesc': 'getAll'
        }
    }

</script>