<template>
    <cmpRec v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    /*{ "key": "CourseName", "label": i18n.t('component.Mission.CourseName.label') },*/
                    { "key": "MissionCode", "label": i18n.t('component.Mission.MissionCode.label') },
                    { "key": "MissionName", "label": i18n.t('component.Mission.MissionName.label') },
                    { "key": "MissionParameter", "label": i18n.t('component.Mission.MissionParameter.label'), input: { type: "textarea", style: "width:400px;word-wrap:break-word;" } },
                ]
            },
            header() {
                return i18n.t('component.Mission.recHeader')
            },
            parent() {
                return {
                    "name": "Course",
                    "key": "CourseId",
                    "dataHeaders": [
                        { "key": "CourseCode", "label": i18n.t('component.Course.CourseCode.label') },
                        { "key": "CourseName"},
                        { "key": "CourseType", "label": i18n.t('component.Course.CourseType.label') },
                    ]
                }
            }
        },
        data() {

            return {
                name: "Mission",
                pkColumn: "MissionId"
            };
        }
    }
</script>