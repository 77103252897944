<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "ProgId", "label": i18n.t('component.Menu.ProgId.label'), input: { type: "text" } },
                    { "key": "Param", "label": i18n.t('component.Menu.Param.label'), input: { type: "text" } },
                    { "key": "MenuName", "label": i18n.t('component.Menu.MenuName.label'), input: { type: "text" } },
                    { "key": "MenuDisplay", "label": i18n.t('component.Menu.MenuDisplay.label'), input: { type: "text" } },
                    { "key": "MenuIntro", "label": i18n.t('component.Menu.MenuIntro.label'), input: { type: "text" } },
                    { "key": "MenuIndex", "label": i18n.t('component.Menu.MenuIndex.label'), input: { type: "number" } },
                    { "key": "MenuLevel", "label": i18n.t('component.Menu.MenuLevel.label'), input: { type: "number" } },
                    { "key": "MenuSort", "label": i18n.t('component.Menu.MenuSort.label'), input: { type: "number" } },
                    { "key": "MenuType", "label": i18n.t('component.Menu.MenuType.label'), input: { type: "number" } },
                    { "key": "MenuDisabled", "label": i18n.t('component.Menu.MenuDisabled.label'), input: { type: "number" } }
                ]
            },
            header() {
                return i18n.t('component.Menu.insHeader')
            }
        },
        data() {

            return {
                name: "Menu",
                pkColumn: "MenuId",
                parent: {}
            };
        }
    }
</script>