<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "RoleName", "label": i18n.t('component.Role.RoleName.label'), input: { type: "text" } },
                    { "key": "RoleDisplayName", "label": i18n.t('component.Role.RoleDisplayName.label'), input: { type: "text" } },
                ]
            },
            header() {
                return i18n.t('component.Role.insHeader')
            }
        },
        data() {

            return {
                name: "Role",
                pkColumn: "RoleId",
                parent: {}
            };
        }
    }
</script>