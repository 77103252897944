<template>
    <cmpRec v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    { "key": "CodeNo", "label": i18n.t('component.CodeDetail.CodeNo.label') },
                    { "key": "CodeDisplay", "label": i18n.t('component.CodeDetail.CodeDisplay.label') },
                    { "key": "Description", "label": i18n.t('component.CodeDetail.Description.label') }
                ]
            },
            header() {
                return i18n.t('component.CodeDetail.recHeader')
            }
        },
        data() {

            return {
                name: "CodeDetail",
                pkColumn: "CodeDetailId",
                parent: {
                    name: "Code",
                    key: "CodeId"
                }
            };
        }
    }
</script>