<template>
    <cmpTableWithMenu v-bind:name="name"
                v-bind:pkColumn="pkColumn"
                v-bind:fields="fields"
                v-bind:qryFields="qryFields"
                v-bind:children="children"
                v-bind:parent="parent"
                v-bind:header="header"
                v-bind:tableFuncs="tableFuncs"></cmpTableWithMenu>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    { "key": "CodeNo", "label": i18n.t('component.CodeDetail.CodeNo.label'), sortable: true },
                    { "key": "CodeDisplay", "label": i18n.t('component.CodeDetail.CodeDisplay.label'), sortable: true },
                    { "key": "Description", "label": i18n.t('component.CodeDetail.Description.label'), sortable: true }
                ]
            },
            qryFields() {
                return [
                    { "key": "CodeDetailId", "label": i18n.t('component.CodeDetail.CodeDetailId.label'), input: { type: "text" } },
                    { "key": "CodeNo", "label": i18n.t('component.CodeDetail.CodeNo.label'), input: { type: "text" } },
                    { "key": "CodeDisplay", "label": i18n.t('component.CodeDetail.CodeDisplay.label'), input: { type: "text" } }
                ]
            }
            ,
            header() {
                return i18n.t('component.CodeDetail.lstHeader')
            }
        },
        data() {

            return {
                name: "CodeDetail",
                pkColumn: "CodeDetailId",
                children: {},
                parent: {
                    name: "Code",
                    key: "CodeId"
                },
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete']
            };
        }
    }
</script>