<template>
    <cmpTableWithMenu v-bind:name="name"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:header="header"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "MenuId", "label": i18n.t('component.Menu.MenuId.label'), sortable: true },
                    { "key": "ProgId", "label": i18n.t('component.Menu.ProgId.label'), sortable: true },
                    { "key": "MenuName", "label": i18n.t('component.Menu.MenuName.label'), sortable: true },
                    { "key": "MenuDisplay", "label": i18n.t('component.Menu.MenuDisplay.label'), sortable: true },
                    { "key": "MenuIndex", "label": i18n.t('component.Menu.MenuIndex.label'), sortable: true },
                    { "key": "MenuLevel", "label": i18n.t('component.Menu.MenuLevel.label'), sortable: true },
                    { "key": "MenuSort", "label": i18n.t('component.Menu.MenuSort.label'), sortable: true },
                    { "key": "MenuDisabled", "label": i18n.t('component.Menu.MenuDisabled.label'), sortable: true },
                ]
            },
            qryFields() {
                return [
                    { "key": "MenuId", "label": i18n.t('component.Menu.MenuId.label'), input: { type: "text" } },
                    { "key": "ProgId", "label": i18n.t('component.Menu.ProgId.label'), input: { type: "text" } },
                    { "key": "MenuName", "label": i18n.t('component.Menu.MenuName.label'), input: { type: "text" } },
                    { "key": "MenuDisplay", "label": i18n.t('component.Menu.MenuDisplay.label'), input: { type: "text" } },
                    { "key": "MenuIndex", "label": i18n.t('component.Menu.MenuIndex.label'), input: { type: "text" } },
                    { "key": "MenuLevel", "label": i18n.t('component.Menu.MenuLevel.label'), input: { type: "text" } }
                ]
            }
            ,
            header() {
                return i18n.t('component.Menu.lstHeader')
            }
        },
        data() {

            return {
                name: "Menu",
                pkColumn: "MenuId",
                children: {},
                parent: {},
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete'],
                moreTableFuncs: []
            };
        }
    }
</script>