<!-- 顯示一個上傳檔案的 component -->
<template>
    <b-modal id="importFile">
        <template #modal-title>
            {{ $t('form.uploadFile') }}
        </template>
        <div class="d-block text-center">
            <form id="uploadForm" enctype="multipart/form-data">
                <input type="file" id="fileUpload" name="file" accept=".xls, .xlsx, .png, .gif, .jpg" multiple>
            </form>
        </div>
        <template #modal-footer>
            <button type="button" class="btn btn-secondary" @click.prevent="submit()">{{ $t('form.submit') }}</button>
            <button type="button" class="btn btn-secondary" @click.prevent="cancel()">{{ $t('form.goback') }}</button>
        </template>
    </b-modal>
</template>

<script>

    export default {
        methods: {
            /*
             *  顯示這個 component
             */
            show() {
                this.$bvModal.show('importFile')
            },
            /*
             *  確認上傳
             */
            submit() {
                var formData = new FormData();
                var files = document.querySelector('#fileUpload').files;
                for (var i = 0; i < files.length; i++) {
                    formData.append("file", files[i]);
                }

                this.$emit("submit", formData);
            },
            /*
             *  取消上傳，隱藏這個 component
             */
            cancel() {
                var file = document.querySelector('#fileUpload');
                file.value = ''
                this.$bvModal.hide('importFile')
            }
            
        }
    }

</script>