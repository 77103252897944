<!-- 顯示一個支援輸入多筆資料的 table，可以新增或移除 rows -->
<template>
    <div>
        <table border="0" align="left" class="table-multirow">
            <tr v-for="(m, index) in model" :key="index">
                <td align="left" v-for="f in fields" :key="f.key">
                    <input v-if="f.input.type == 'text'" type="text" v-model="m[f.key]" v-bind:id="`${id}_${f.key}`" />
                    <input v-if="f.input.type == 'readonly'" type="text" v-model="m[f.key]" readonly v-bind:id="`${id}_${f.key}`" />
                    <input v-else-if="f.input.type == 'number'" type="number" v-model="m[f.key]" v-bind:id="`${id}_${f.key}`" />
                    <input v-else-if="f.input.type == 'password'" type="password" v-model="m[f.key]" v-bind:id="`${id}_${f.key}`" />
                    <input v-else-if="f.input.type == 'boolean'" type="checkbox" v-model="m[f.key]" v-bind:id="`${id}_${f.key}`" />
                    <textarea v-else-if="f.input.type == 'textarea'" v-model="m[f.key]" v-bind:id="`${id}_${f.key}`" />

                    <cmpConfirmPassword v-else-if="f.input.type == 'confirmPassword'" v-model="m[f.key]" v-bind:id="`${id}_${f.key}`">
                    </cmpConfirmPassword>

                    <input v-else-if="f.input.type == 'date'" type="date" v-model="m[f.key]" v-bind:id="`${id}_${f.key}`" />

                    <input v-else-if="f.input.type == 'datetime'" type="datetime-local" v-model="m[f.key]" v-bind:id="`${id}_${f.key}`" />

                    <cmpSelect v-else-if="f.input.type == 'select'" v-model="m[f.key]" v-bind:id="`${id}_${f.key}`"
                               v-bind:options="formBind[f.key]"
                               v-bind:isMultiple="false">
                    </cmpSelect>


                    <cmpSelect v-else-if="f.input.type == 'selectmulti'" v-model="m[f.key]" v-bind:id="`${id}_${f.key}`"
                               v-bind:options="formBind[f.key]"
                               v-bind:isMultiple="true">
                    </cmpSelect>

                    <b-form-radio-group v-else-if="f.input.type == 'radio'" v-bind:id="`${id}_${f.key}`"
                                        v-model="m[f.key]"
                                        :options="formBind[f.key]"
                                        :name="f.key">
                    </b-form-radio-group>

                    <b-form-checkbox-group v-else-if="f.input.type == 'checkbox'" v-bind:id="`${id}_${f.key}`"
                                           v-model="m[f.key]"
                                           :options="formBind[f.key]"
                                           :name="f.key">
                    </b-form-checkbox-group>

                    <cmpTextboxSearch v-else-if="f.input.type == 'textboxSearch'"
                                      v-model="m[f.key]"
                                      v-bind:id="`${id}_${f.key}`"
                                      v-bind:idField="f.input.idField"
                                      v-bind:objectName="f.input.objectName"
                                      v-bind:header="f.label"
                                      v-bind:qryFields="f.input.qryFields"
                                      v-bind:displayFields="f.input.displayFields"
                                      v-bind:displayField="f.input.displayField"
                                      v-bind:displayFieldValue="m[f.input.editDisplayField]"
                                      v-bind:formFilterFields="f.input.formFilterFields"
                                      v-on:searchResultValue="m[f.input.editDisplayField] = $event"
                                      v-bind:ref="`${f.key}_${index}`"
                                      @onSearch="textboxSearchOnSearch(`${f.key}_${index}`, $event)">
                    </cmpTextboxSearch>

                </td>
                <td>
                    <a href="#" v-bind:id="`${id}_delete_${index}`" @click.prevent="deleteRow(index)"><img src="@/assets/icons/IcnTrash.png" style="height:30px" /></a>
                    <a v-if="model && index == model.length - 1" v-bind:id="`${id}_add_${index}`" href="#" @click.prevent="addRow"><img src="@/assets/icons/IcnAdd.png" style="height:30px" /></a>
                </td>
            </tr>
            <tr v-if="model && model.length == 0">
                <td colspan="2" align="center">
                    <div v-if="model && model.length == 0"><a href="#" v-bind:id="`${id}_${index}`" @click.prevent="addRow"><img src="@/assets/icons/IcnAdd.png" /></a></div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTextboxSearch from "@/components/common/cmpTextboxSearch"
    import cmpSelect from "@/components/common/cmpSelect"
    import cmpConfirmPassword from "@/components/common/cmpConfirmPassword"
    import ApiService from '@/ApiService';
    var api = undefined

    export default {
        components: {
            cmpTextboxSearch,
            cmpSelect,
            cmpConfirmPassword
        },
        data() {
            return {
                model: [],      // bind 到這個 component 的 object
                formBind: {}    // bind 到各別 input 的值，例如 combobox, checkbox, radio button
            };
        },
        props: {
            name: String,       // table 的名字, 也是 Controller 的名字
            fields: Array,      // 可以輸入的欄位
            value: null,        // 這個 component 的 value
            formFilterFields: { type: Array, default: () => [] }, // 搜尋時要從 parent form 取得的欄位,
            id: String          // 要 bind 到 <input> 的 id
        },
        watch: {
            model: {
                handler: function (newValue) {
                    this.$emit('input', newValue);
                },
                deep: true
            },
            value() {
                this.model = this.value;
            },
        },
        async created() {
            api = new ApiService(this.name)

            for (let f of this.$props.fields) {
                if (['select', 'radio', 'checkbox', 'selectmulti'].includes(f.input.type)) {
                    var res = await this.getSelectOptions(f.input.objectName, f.input.idField, f.input.displayField, f.input.filterField, f.input.filterValue)

                    this.$set(this.formBind, f.key, res)

                    if (f.input.type == 'checkbox') {
                        if (!this.model[f.key]) {
                            this.model[f.key] = []
                        } else {
                            this.model[f.key] = this.model[f.key].split(',')
                        }
                    }

                }
            }

            if (this.model && this.model.length == 0) {
                this.addRow();
            }

        },
        methods: {
            /*
             *  從後台讀取資料
             *  @param {number} id 要讀取的 id
             */
            async get(id) {
                this.loading = true

                try {
                    this.model = await api.get(id, i18n.locale)

                    this.model['id'] = id;

                } finally {
                    this.loading = false
                }
            },
            /*
             *  從後台讀取像 combobox, radiobutton 選項的值
             *  @param {string} objectName 要從資料庫讀取選項的 table 名稱
             *  @param {string} idField ID 欄位的名稱
             *  @param {string} displayField 要顯示欄位的名稱
             *  @param {string} filterField 要搜尋的欄位名稱
             *  @param {string} filterValue 要搜尋的欄位的值
             */
            async getSelectOptions(objectName, idField, displayField, filterField, filterValue) {
                var data = {}
                data[filterField] = filterValue
                data['language'] = i18n.locale;

                var res = await api.getSelectOptions(objectName, data);

                var result = res.Data.map(function (row) {
                    return {
                        value: row[idField], text: row[displayField]
                    }
                });

                return result;
            },
            /*
             *  新增一行到這個 table
             */
            addRow() {
                var newRow = {};

                for (let f of this.$props.fields) {
                    newRow[f.key] = null;
                }

                this.model.push(newRow);
            },
            /*
             *  從 table 移除一行
             *  @param {number} rowIndex 要移除那行的 index
             */
            deleteRow(rowIndex) {
                this.$delete(this.model, rowIndex);
            },
            /*
             *  textboxSearch 需要用其他的 form field 做一些 filter, filter 的邏輯放在 parent,
             *  parent 跑完 filter 的邏輯要 call textboxSearchParent 把要 filter 的再傳回這個 module
             *  @param {string} caller 這個 textboxSearch 欄位的名稱
             *  @param {array} searchFields 要用來 filter 的欄位名稱
             */
            textboxSearchOnSearch(caller, searchFields) {
                this.$emit('onSearch', { caller: caller, searchFields: searchFields });
            },
            /*
             *  parent 接到 textboxSearchOnSearch callback，做了一些 filter 後 call 進來的
             *  @param {string} caller 這個 textboxSearch 欄位的名稱
             *  @param {array} searchTerms 要搜尋的 keywords
             */
            parentSearch(caller, searchTerms) {
                this.$refs[caller][0].parentSearch(searchTerms);
            }
        }
    }
</script>