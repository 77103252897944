<template>
    <cmpRec v-bind:name="name" v-bind:pkColumn="pkColumn" v-bind:fields="fields" v-bind:parent="parent"
        v-bind:header="header"></cmpRec>
</template>

<script>
import i18n from '@/i18n'
import cmpRec from "@/components/common/cmpRec"

export default {
    components: {
        cmpRec
    },
    computed: {
        fields() {
            return [
                { "key": "ActivityId", "label": i18n.t('component.ActivityLog.ActivityId.label') },
                { "key": "ActivityTitle", "label": i18n.t('component.ActivityLog.ActivityTitle.label') },
                { "key": "LogTime", "label": i18n.t('component.ActivityLog.LogTime.label') },
                { "key": "RespondentName", "label": i18n.t('component.ActivityLog.RespondentName.label') },
                { "key": "MotionDataId", "label": i18n.t('component.ActivityLog.MotionDataId.label') },
                { "key": "ActivityLogStageName", "label": i18n.t('component.ActivityLog.ActivityLogStage.label') },
                { "key": "ActivityLogStatusName", "label": i18n.t('component.ActivityLog.ActivityLogStatus.label') },
                { "key": "DeviceId", "label": i18n.t('component.ActivityLog.DeviceId.label') },
            ]
        },
        header() {
            return i18n.t('component.ActivityLog.recHeader')
        }
    },
    data() {

        return {
            name: "ActivityLog",
            pkColumn: "ActivityLogId",
            parent: { "name": "Activity", "key": "ActivityId" },
        };
    }
}
</script>