<!-- 顯示單筆資料的 form，只能 readonly -->
<template>
    <cmpRec v-bind:name="name"
            v-bind:pkColumn="pkColumn"
            v-bind:fields="fields"
            v-bind:parent="parent"
            v-bind:header="header"></cmpRec>
</template>

<script>
    //多國語言library
    import i18n from '@/i18n'
    // 匯入cmpRec component
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            //單筆檢視畫面欄位
            fields() {
                return [
                    //key: 欄位名稱, label:欄位說明
                    { "key": "CourseName", "label": i18n.t('component.Activity.CourseName.label') },
                    { "key": "ProjectStageName", "label": i18n.t('component.Activity.ProjectStage.label') },
                    { "key": "ActivityTitle", "label": i18n.t('component.Activity.ActivityTitle.label') },
                    { "key": "ActivityDesc", "label": i18n.t('component.Activity.ActivityDesc.label') },
                    { "key": "ActivityTypeName", "label": i18n.t('component.Activity.ActivityType.label') },
                    //key: 欄位名稱, label:欄位說明, input type:datetime
                    { "key": "ActivityStartDate", "label": i18n.t('component.Activity.ActivityStartDate.label'), input: { type: "datetime" } },
                    { "key": "ActivityPeriod", "label": i18n.t('component.Activity.ActivityPeriod.label') },
                    { "key": "ActivityEndDate", "label": i18n.t('component.Activity.ActivityEndDate.label'), input: { type: "datetime" } },
                    { "key": "ActivityStatusName", "label": i18n.t('component.Activity.ActivityStatus.label') },
                    { "key": "RespondentName", "label": i18n.t('component.Activity.RespondentName.label') },
                    { "key": "ModeratorName", "label": i18n.t('component.Activity.ModeratorName.label') },
                    { "key": "Memo", "label": i18n.t('component.Activity.Memo.label') },
                ]
            },
            header() {
                //返回畫面header 路徑
                return i18n.t('component.Activity.recHeader')
            }
        },
        data() {

            return {
                // 資料庫 table 的名稱
                name: "Activity",
                // 資料庫 table p-key
                pkColumn: "ActivityId",
                //參考到的上層畫面 用法可參考CodeDetail
                parent: {}
            };
        }
    }
</script>