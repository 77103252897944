<template>
    <cmpRec v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    { "key": "Name", "label": i18n.t('component.Code.Name.label') },
                    { "key": "DisplayName", "label": i18n.t('component.Code.DisplayName.label') },
                    { "key": "Description", "label": i18n.t('component.Code.Description.label') },
                    { "key": "Type", "label": i18n.t('component.Code.Type.label') },
                    { "key": "Language", "label": i18n.t('component.Code.Language.label') }
                ]
            },
            header() {
                return i18n.t('component.Code.recHeader')
            }
        },
        data() {

            return {
                name: "Code",
                pkColumn: "CodeId",
                parent: {}
            };
        }
    }
</script>