<template>
    <cmpCreate :name="name"
               :subPath="subPath"
               :pkColumn="pkColumn"
               :fields="fields"
               :parent="parent"
               :header="header"
               :goBackAfterCreate="goBackAfterCreate"
               v-model="formData"
               ref="cmpCreate">
    </cmpCreate>
</template>

<script>
    import cmpCreate from "@/components/common/cmpCreate";
    import i18n from '@/i18n';
    import ApiService from '@/ApiService';

    export default {
        components: {
            cmpCreate
        },
        data() {
            return {
                name: "Tenant",
                subPath: "Privilege",
                pkColumn: "privilegeId",
                parent: {},
                goBackAfterCreate: false,
                formData: {
                    SiteId: '',
                    ObjectName: '',
                    RoleName: '',
                    Privilege: ''
                }
            };
        },
        computed: {
            fields() {
                return [
                    { key: "SiteId", label: i18n.t('Tenant.Controller.SiteId.label'), input: { type: "selectmulti", objectName: "Tenant/Privilege/SiteId", idField: "SiteId", displayField: ["SiteId"] }, required: true },
                    { key: "ObjectName", label: i18n.t('Tenant.Controller.ControllerName.label'), input: { type: "text" }, required: true },
                    { key: "RoleName", label: i18n.t('Tenant.Controller.RoleName.label'), input: { type: "selectmulti", objectName: "Tenant/Role/RoleName", idField: "RoleName", displayField: ["RoleName"] }, required: true },
                    { key: "Privilege", label: i18n.t('Tenant.Controller.Privilege.label'), input: { type: "text" }, required: true },
                ];
            },
            header() {
                return i18n.t('Tenant.Controller.Header');
            }
        },
        watch: {
            'formData.SiteId': async function (newSiteId) {
                if (newSiteId && newSiteId.length > 0) {
                    const siteIdString = newSiteId.join(','); // �N SiteId array �γr���걵���r��
                    const res = await this.getOptions("Tenant/Role/RoleName", { SiteId: siteIdString });
                    const result = res.Data.map(row => ({ value: row['RoleName'], text: row['RoleName'] }));
                    this.formData.RoleName = ''; // Clear the existing value before updating options
                    this.$refs.cmpCreate.formBind.RoleName = result;
                }
            }
        },
        methods: {
            getOptions(objectName, data) {
                const api = new ApiService(objectName);
                return api.getSelectOptions(objectName, data);
            }
        }
    };
</script>
