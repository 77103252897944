<template>
    <cmpTableWithMenu v-bind:name="name"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:header="header"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    { "key": "MemberId", "label": i18n.t('component.User.MemberId.label'), sortable: true },
                    //{ "key": "Account", "label": i18n.t('component.User.Account.label'), sortable: true },
                    { "key": "Name", "label": i18n.t('component.User.Name.label'), sortable: true },
                    { "key": "UnitName", "label": i18n.t('component.User.UnitName.label'), sortable: true },
                    { "key": "Birthday", "label": i18n.t('component.User.Birthday.label'), sortable: true, type: "date" },
                    { "key": "GenderName", "label": i18n.t('component.User.GenderName.label'), sortable: true },
                    { "key": "PhoneNumber", "label": i18n.t('component.User.PhoneNumber.label'), sortable: true },
                    //{ "key": "ProjectCode", "label": i18n.t('component.User.ProjectCode.label'), sortable: true },
                ]
            },
            qryFields() {
                return [
                    { "key": "UnitId", "label": i18n.t('component.User.UnitId.label'), input: { type: "select", objectName: "Unit", idField: "UnitId", displayField: "UnitName" } },
                    { "key": "MemberId", "label": i18n.t('component.User.MemberId.label'), input: { type: "text" } },
                    { "key": "Name", "label": i18n.t('component.User.Name.label'), input: { type: "text" } },
                    { "key": "PhoneNumber", "label": i18n.t('component.User.PhoneNumber.label'), input: { type: "text" } },
                 //   { "key": "ProjectCode", "label": i18n.t('component.User.ProjectCode.label'), input: { type: "text" } },
                ]
            }
            ,
            header() {
                return i18n.t('component.User.lstHeader')
            }
        },
        data() {

            return {
                name: "User",
                pkColumn: "UserId",
                children: {},
                parent: {},
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete'],
                moreTableFuncs: []
            };
        }
    }
</script>