import i18n from '@/i18n'
import { codeName } from "@/util/constant"

export default {
    computed: {
        fields() {
            return [
                { "key": "MemberId", "label": i18n.t('component.User.MemberId.label'), input: { type: "text" }, required: true },
                //{ "key": "Account", "label": i18n.t('component.User.Account.label'), input: { type: "text" }, required: true },
                //{ "key": "Password", "label": i18n.t('component.User.Password.label'), input: { type: "confirmPassword" } },
                { "key": "Name", "label": i18n.t('component.User.Name.label'), input: { type: "text" } },
                { "key": "Code", "label": i18n.t('component.User.Code.label'), input: { type: "text" } },
                { "key": "Title", "label": i18n.t('component.User.Title.label'), input: { type: "text" } },
                { "key": "Birthday", "label": i18n.t('component.User.Birthday.label'), input: { type: "date" } },
                { "key": "Gender", "label": i18n.t('component.User.Gender.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeDisplay", displayField: "CodeDisplay", filterField: "CodeName", filterValue: codeName.Gender } },
                { "key": "VideoUrl", "label": i18n.t('component.User.VideoUrl.label'), input: { type: "text" } },
                { "key": "Email", "label": i18n.t('component.User.Email.label'), input: { type: "text", format: "email" } },
                { "key": "Ethnic", "label": i18n.t('component.User.Ethnic.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeDisplay", displayField: "CodeDisplay", filterField: "CodeName", filterValue: codeName.Ethnic } },
                { "key": "UnitId", "label": i18n.t('component.User.UnitId.label'), input: { type: "textboxSearch", objectName: "Unit", idField: "UnitId", displayField: "UnitName", editDisplayField: "UnitName", qryFields: ["UnitName"], displayFields: ["UnitId", "UnitName"] }, required: true },
                { "key": "Father", "label": i18n.t('component.User.Father.label'), input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", editDisplayField: "FatherName", qryFields: ["Name"], displayFields: ["UserId", "Name"] } },
                { "key": "Mother", "label": i18n.t('component.User.Mother.label'), input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", editDisplayField: "MotherName", qryFields: ["Name"], displayFields: ["UserId", "Name"] } },
                { "key": "CountryCode", "label": i18n.t('component.User.CountryCode.label'), input: { type: "text" } },
                { "key": "ZipCode", "label": i18n.t('component.User.ZipCode.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeDisplay", displayField: "CodeDisplay", filterField: "CodeName", filterValue: codeName.ZipCode } },
                { "key": "Address", "label": i18n.t('component.User.Address.label'), input: { type: "text" } },
                { "key": "ZipCode1", "label": i18n.t('component.User.ZipCode1.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeDisplay", displayField: "CodeDisplay", filterField: "CodeName", filterValue: codeName.ZipCode } },
                { "key": "Address1", "label": i18n.t('component.User.Address1.label'), input: { type: "text" } },
                { "key": "ProjectRole", "label": i18n.t('component.User.ProjectRole.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeDisplay", displayField: "CodeDisplay", filterField: "CodeName", filterValue: codeName.ProjectRole } },
                { "key": "PhoneNumber1", "label": i18n.t('component.User.PhoneNumber1.label'), input: { type: "text" } },
                { "key": "PhoneNumber2", "label": i18n.t('component.User.PhoneNumber2.label'), input: { type: "text" } },
                { "key": "PhoneNumber", "label": i18n.t('component.User.PhoneNumber.label'), input: { type: "text" } },
                { "key": "BankCode", "label": i18n.t('component.User.BankCode.label'), input: { type: "text" } },
                { "key": "BankAccount", "label": i18n.t('component.User.BankAccount.label'), input: { type: "text" } },
                //{ "key": "ProjectCode", "label": i18n.t('component.User.UserId.label'), input: { type: "text" }, required: true },
                { "key": "Memo", "label": i18n.t('component.User.Memo.label'), input: { type: "text" } }
            ]
        },
        header() {
            return i18n.t('component.User.insHeader')
        }
    },
    data() {

        return {
            name: "User",
            pkColumn: "UserId",
            parent: {}
        };
    }
}
