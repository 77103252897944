<template>
    <cmpRec v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    { "key": "CourseName", "label": i18n.t('component.Course.BasicMotion.label') },
                    { "key": "CourseCode", "label": i18n.t('component.Course.CourseCode.label') },
                    { "key": "CourseType", "label": i18n.t('component.Course.CourseType.label') },
                    { "key": "CourseParameter", "label": i18n.t('component.Course.CourseParameter.label'), input: { type: "textarea", style: "width:400px" } },
                    { "key": "CourseReportFormat", "label": i18n.t('component.Course.CourseReportFormat.label'), input: { type: "textarea", style: "width:400px" } },
                    { "key": "CourseDesc", "label": i18n.t('component.Course.CourseDesc.label'), input: { type: "textarea", style: "width:400px" } },
                    { "key": "CourseDeviceManage", "label": i18n.t('component.Course.CourseDevice.label'), input: { type: "textarea", style: "width:400px; height: 150px" } },
                ]
            },
            header() {
                return i18n.t('component.Course.recHeader')
            }
        },
        data() {

            return {
                name: "Course",
                pkColumn: "CourseId",
                parent: {}
            };
        }
    }
</script>