<!-- 顯示一個密碼和確認密碼的 component -->
<template>
    <span>
        <input type="password" v-model="password" v-bind:id="`${id}1`" />
        {{ $t('form.confirm') }}{{label}} <input type="password" v-model="confirmPassword" v-bind:id="`${id}2`" />
    </span>
</template>

<script>
    export default {
        data() {
            return {
                password: '',       // bind 到 password
                confirmPassword: '' // bind 到 confirmPassword
            };
        },
        props: {
            value: String,  // 這個 component 的 value
            label: String,  // 顯示在 confirm Password 前的文字
            id: String      // 要 bind 到 <input> 的 id
        },
        watch: {
            value() {
                this.password = this.value;
            },
            password() {
                this.checkPassword();
            },
            confirmPassword() {
                this.checkPassword();
            }
        },
        methods: {
            /*
             *  callback 到上層
             */
            checkPassword() {
                this.$emit('input', this.password);
                
            },
            /*
             *  驗證密碼一樣
             */
            validate() {
                return this.password == this.confirmPassword;
            }
        },
    }
</script>