<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"
                   v-bind:query="query"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"
    //import { codeName } from "@/util/constant"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    //{ "key": "MemberId", "label": "人員編號", input: { type: "text" }, required:true },
                    { "key": "Account", "label": i18n.t('component.User.Account.label'), input: { type: "text" } },
                    { "key": "OldPassword", "label": i18n.t('component.User.OldPassword.label'), input: { type: "password" }, required: true },
                    { "key": "Password", "label": i18n.t('component.User.Password.label'), input: { type: "confirmPassword" }, required: true },
                    { "key": "FormType", "label": "", input: { type: "hidden" }, default: "current" },

                   // { "key": "Name", "label": "姓名", input: { type: "text" } },
                    //{ "key": "Code", "label": "身分證字號",  input: { type: "text" } },
                    //{ "key": "Title", "label": "職稱", input: { type: "text" } },
                    //{ "key": "Birthday", "label": "生日", input: { type: "date" } },
                   // { "key": "Gender", "label": "性別", input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "Codename", filterValue: codeName.Gender } },
                    //{ "key": "VideoUrl", "label": "個人會議網址", input: { type: "text" } },
                    //{ "key": "Email", "label": "Email", input: { type: "text", format: "email" } },
                    //{ "key": "Ethnic", "label": "族群", input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.Ethnic } },
                    //{ "key": "UnitId", "label": "單位ID", input: { type: "textboxSearch", objectName: "Unit", idField: "UnitId", displayField: "UnitName", editDisplayField: "UnitName", qryFields: ["UnitName"], displayFields: ["UnitId", "UnitName"] }, required: true },
                    //{ "key": "Father", "label": "父親", input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", editDisplayField: "FatherName", qryFields: ["MemberIdOr", "NameOr"], displayFields: ["MemberId", "Name"] } },
                    //{ "key": "Mother", "label": "母親", input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", editDisplayField: "MotherName", qryFields: ["MemberIdOr", "NameOr"], displayFields: ["MemberId", "Name"] } },
                    //{ "key": "CountryCode", "label": "	國別", input: { type: "text" } },
                    //{ "key": "ZipCode", "label": "郵區", input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.ZipCode } },
                    //{ "key": "Address", "label": "地址", input: { type: "text" } },
                    //{ "key": "ZipCode1", "label": "通訊郵區", input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.ZipCode } },
                    //{ "key": "Address1", "label": "通訊地址", input: { type: "text" } },
                    //{ "key": "ProjectRole", "label": "專業別", input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.ProjectRole } },
                    //{ "key": "PhoneNumber1", "label": "公司電話", input: { type: "text" } },
                    //{ "key": "PhoneNumber2", "label": "住宅電話", input: { type: "text" } },
                    //{ "key": "PhoneNumber", "label": "手機", input: { type: "text" } },
                    //{ "key": "BankCode", "label": "銀行ATM代號", input: { type: "text" } },
                    //{ "key": "BankAccount", "label": "銀行帳號", input: { type: "text" } },
                    //{ "key": "ProjectCode", "label": "專案代號", input: { type: "text" }, required: true },
                    //{ "key": "Memo", "label": "備註", input: { type: "text" } }
                ]
            },
            header() {
                return i18n.t('component.User.insCurrentHeader')
            }
        },
        data() {
            return {
                name: "Login",
                pkColumn: "LoginId",
                query: '',
                parent: {}
            };
        },
        created() {
            var queryParam = { current: true, action: 'U' };
            this.query = queryParam;
        }
    }
</script>