<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import cmpCreate from "@/components/common/cmpCreate"
    import i18n from '@/i18n'

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "Name", "label": i18n.t('component.Code.Name.label'), input: { type: "text" }, required: true },
                    { "key": "DisplayName", "label": i18n.t('component.Code.DisplayName.label'), input: { type: "text" }, required: true },
                    { "key": "Description", "label": i18n.t('component.Code.Description.label'), input: { type: "text" } },
                ]
            },
            header() {
                return i18n.t('component.Code.insHeader')
            }
        },
        data() {

            return {
                name: "Code",
                pkColumn: "CodeId",
                parent: {}
            };
        }
    }
</script>