<template>
    <div style="text-align: center">
        <cmpLoading v-if="loading" :top="20"></cmpLoading>

        <div id="webpath">{{ $t("Tenant.BackupRestore.Header") }}</div>

        <div>
            <table border="0" align="center" class="table2">
                <tr>
                    <td colspan="2" align="center">
                        <br />
                        <div class="btn9" @click.prevent="download">{{ $t("Tenant.BackupRestore.Download.label") }}</div>
                        <div class="btn9" @click="$refs.uploadFileComponent.show()">{{ $t("Tenant.BackupRestore.Upload.label") }}</div>
                        <div class="btn9" @click.prevent="onCancel">{{ $t("Tenant.BackupRestore.GoBack.label") }}</div>
                    </td>
                </tr>
            </table>
        </div>

        <cmpUploadFile ref="uploadFileComponent" @submit="upload"></cmpUploadFile>
    </div>
</template>

<script>
    import cmpLoading from "@/components/common/cmpLoading";
    import cmpUploadFile from "@/components/common/cmpUploadFile";
    import ApiService from "@/ApiService";
    import i18n from '@/i18n';

    export default {
        components: {
            cmpLoading,
            cmpUploadFile
        },
        data() {
            return {
                uploadText: "",
                uploadContent: [],
                loading: false,
                errorMsg: "",
                qryModel: {} // bind to query string object
            };
        },
        created() {
            this.qryModel = { ...this.$route.query };
            this.api = new ApiService("BackupRestore");
        },
        methods: {
            async download() {
                try {
                    const actions = [
                        'exportActivity',
                        'exportCodeDetail',
                        'exportCourse',
                        'exportMission',
                        'exportMotionData',
                        'exportUser'
                    ];

                    await actions.forEach(action => {
                        this.api.exportExcel(this.qryModel, action, action.replace('export', ''));
                    });

                    alert(i18n.t('message.DownloadSuccess'));
                } catch (error) {
                    console.error(error);
                    alert(i18n.t('message.DownloadFailed'));
                }
            },
            async upload(formData) {
                try {
                    this.errorMsg = "";
                    const result = await this.api.uploadFile(formData);
                    this.$refs.uploadFileComponent.cancel();

                    const messageKey = result ? 'message.importSuccess' : 'message.importFailed';
                    alert(i18n.t(messageKey));

                } catch (err) {
                    alert(i18n.t('message.importFailed'));
                }
            },
            onCancel() {
                const routerName = this.$root.$data.store.getRouteName("loggedinHome");
                this.$router.push({ name: routerName });
            }
        }
    };
</script>
