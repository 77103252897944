<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"
    import { codeName } from "@/util/constant"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "CourseName", "label": i18n.t('component.Course.CourseName.label'), input: { type: "text" }, required: true },
                    { "key": "CourseCode", "label": i18n.t('component.Course.CourseCode.label'), input: { type: "text" }, required: true },
                    { "key": "CourseType", "label": i18n.t('component.Course.CourseType.label'), input: { type: "text" }, required: true },
                    { "key": "CourseParameter", "label": i18n.t('component.Course.CourseParameter.label'), input: { type: "textarea", style: "width:400px; height: 150px" } },
                    { "key": "CourseReportFormat", "label": i18n.t('component.Course.CourseReportFormat.label'), input: { type: "textarea", style: "width:400px; height: 150px" } },
                    { "key": "CourseDesc", "label": i18n.t('component.Course.CourseDesc.label'), input: { type: "textarea", style: "width:400px; height: 150px" } },
                    { "key": "CourseDeviceManage", "label": i18n.t('component.Course.CourseDevice.label'), input: { type: "textarea", style: "width:400px; height: 150px" } },
                ]
            },
            header() {
                return i18n.t('component.Course.insHeader')
            }
        },
        data() {

            return {
                name: "Course",
                pkColumn: "CourseId",
                parent: {}
            };
        }
    }
</script>