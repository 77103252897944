<template>
    <div>
        <cmpLoading v-if="loading" v-bind:top="20"></cmpLoading>
        <div id="wapper_public">
            <!--top區段-->
            <div id="top_public">
                <div id="logo"><router-link :to="{name: 'public_home'}"><img :src="require(`@/assets/images/${siteId}/Logo.png`)" /></router-link></div>
                <div id="logomb"><router-link :to="{name: 'public_home'}"><img :src="require(`@/assets/images/${siteId}/Logo.png`)" height="30" width="30" /></router-link></div>
                <div id="search">
                    <table border="0" cellspacing="2" cellpadding="2">
                        <tr>
                            <td width="160"><input type="search" size="24" v-bind:placeholder="$t('form.searchTerm')"></td>
                            <td width="543" align="left"><img src="@/assets/icons/IcnSearchButton.png"></td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="marqee">
                                    <ul>
                                        <li>{{marqee}}</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>

                    </table>
                </div>
                <div id="info">

                    <div class="tooltip_public">
                        <a href="#" class="login"><img src="@/assets/icons/IcnMember.png" width="45" @click.prevent="toggleLoginForm()"></a>
                        <span class="tooltiptext_public">{{ $t('form.member') }}</span>
                    </div>

                    <div class="tooltip_public">
                        <a href="#"><img src="@/assets/icons/IcnLang.png" width="45"></a>
                        <span class="tooltiptext_public">
                            <ul class="ul-no-style">
                                <li class="pointer" @click.prevent="changeLocale('en-us')">en-us</li>
                                <li class="pointer" @click.prevent="changeLocale('zh-tw')">zh-tw</li>
                                <li class="pointer" @click.prevent="changeLocale('zh-cn')">zh-cn</li>
                            </ul>
                        </span>
                    </div>

                    <div class="tooltip_public">
                        <router-link :to="{name: 'public_home'}"><img src="@/assets/icons/IcnHome.png" width="45"></router-link>
                        <span class="tooltiptext_public">{{ $t('form.home') }}</span>
                    </div>
                </div>
                <div id="menu_public">
                    <span class="menutext">
                        <span v-for="m in menuItems" v-bind:key="m.MenuId">
                            <router-link :to="m.ProgId">{{m.MenuDisplay}}</router-link><span class="menutext_line"> | </span>
                        </span>
                    </span>
                </div>
                <div id="openmbmenu">
                    <a href="#" class="login" @click.prevent="toggleLoginForm()"><img src="@/assets/icons/IcnMbMember.png"></a>
                    <!--<a href="#" @click.prevent="changeLocale()"><img src="@/assets/icons/IcnMbLan.png"></a>-->
                    <a href="#" data-toggle="dropdown" aria-expanded="false" data-disabled="true"
                           >
                            <img src="@/assets/icons/IcnMbLan.png">
                    </a>
                    <div class="dropdown-menu dropdown-menu-right menubox"
                            style="background-color:#479573; margin-top: 15px; margin-left:100px">
                        <ul class="menubox" style="list-style-type: none; color: white; cursor: pointer; background-color:#479573; width:70px">
                            <li @click.prevent="changeLocale('en-us')">en-us</li>
                            <li @click.prevent="changeLocale('zh-tw')">zh-tw</li>
                        </ul>
                    </div>
                    <a href="#"><img src="@/assets/icons/IcnHome.png"></a>
                    <a href="#"><img src="@/assets/icons/IcnMbMenu.png" id="mbmenu_icon" v-on:click="openMbMenu()"></a>
                </div>
            </div>
            <!--content區段-->
            <hr id="tophr_public">

            <router-view></router-view>

            <cmpFooter cssClass="public"></cmpFooter>

            <!--管理popup區段-->
            <div id="popup_login" v-show="showLogin">
                <div v-show="loginPage == 1">
                    {{ $t('login.manageLogin') }}<br><hr>
                    {{ $t('login.username') }}：
                    <input type="text" v-model="username" id="account" size="12"><br>
                    {{ $t('login.password') }}：<input type="password" v-model="password" size="12">
                    <p class=white_linktext></p>
                    <div class="btn9" @click.prevent="login">✔ {{ $t('form.submit') }}</div>
                    <div class="btn9" @click.prevent="toggleLoginForm()">✘ {{ $t('form.cancel') }}</div>
                </div>
                <div v-show="loginPage==2">
                    {{ $t('login.selectRole') }}
                    <br /><hr>
                    <cmpSelect v-model="selectedRole"
                               v-bind:options="rolesOption"
                               v-bind:isMultiple="false">
                    </cmpSelect>
                    <br /><br />
                    <div class="btn9" @click.prevent="chooseRole()">✔ {{ $t('form.submit') }}</div>
                    <div class="btn9" @click.prevent="toggleLoginForm()">✘ {{ $t('form.cancel') }}</div>
                </div>
            </div>

        </div>
        <div id="mbmenu_public">
            <div id="closembmenu" v-on:click="closeMbMenu()">
                <img src="@/assets/icons/IcnMbMenuClose.png" id="mbmenuclose_icon">
            </div>
            <span class="white_linktext">
                <span v-for="m in menuItems" v-bind:key="m.MenuId">
                    <router-link :to="m.ProgId">{{m.MenuDisplay}}</router-link><br><hr>
                </span>
                <div style="padding-bottom: 80px;"></div>
            </span>
        </div>
    </div>
</template>

<script>
    import i18n from '@/i18n'
    import cmpFooter from '@/components/common/cmpFooter';
    import cmpLoading from "@/components/common/cmpLoading"
    import cmpSelect from "@/components/common/cmpSelect"
    import ApiService from '@/ApiService';

    export default {
        components: {
            cmpLoading,
            cmpFooter,
            cmpSelect
        },
        data() {
            return {
                menuItems: [],
                siteId: "",
                loading: false,
                showLogin: false,
                loginPage: 1,
                username: "",
                password: "",
                selectedRole: "",
                rolesOption: [],
                marqee: ''
            };
        },
        async created() {
            this.siteId = await this.$root.$data.store.getSiteId();

            // Load 跑馬燈
            let requestData = { language: i18n.locale };
            const api = new ApiService('public');
            const res = await api.getAll(requestData, 'marqee');

            if (res.Marqee) {
                this.marqee = res.Marqee;
            }
        },
        mounted() {
            //this.$root.$data.store.logout();
            this.loadMenu();
        },
        methods: {
            toggleLoginForm() {
                this.showLogin = !this.showLogin;

                if (!this.showLogin) {
                    this.resetLoginForm();
                }
            },
            getBackgroundImage() {
                return this.$root.$data.store.getImagePath();
            },
            async loadMenu() {
                let requestData = { language: i18n.locale };
                const api = new ApiService('public');
                this.menuItems = await api.getAll(requestData, 'menu');
            },
            openMbMenu() {
                $("#mbmenu_public").animate({
                    top: '0px',
                    opacity: '1',
                });
            },
            closeMbMenu() {
                $("#mbmenu_public").animate({
                    top: '-1500px',
                    opacity: '0',
                });
            },
            async login() {
                try {
                    var cred = {
                        "username": this.username,
                        "password": this.password
                    }
                    this.loading = true;

                    const apiAuth = new ApiService('authenticate');
                    await apiAuth.login(cred)

                    const apiUserRole = new ApiService("UserRole");
                    var res = await apiUserRole.getAll(null, "current");
                    var result = res.map(function (row) {
                        return {
                            value: row["RoleName"], text: row["RoleName"]
                        }
                    });

                    this.rolesOption = result;
                    this.loading = false;

                    // 選取 role
                    if (result.length > 1) {
                        this.loginPage = 2;
                    }
                    else {
                        await this.getProfile();
                        let routerName = this.$root.$data.store.getRouteName("loggedinHome");
                        this.$router.push({ name: routerName })
                    }
                }
                catch {
                    alert('登入失敗');
                    this.loading = false;
                }
            },
            async chooseRole() {
                this.loading = true;
                const apiProfile = new ApiService("Profile");
                let requestData = {
                    "Role": this.selectedRole
                };

                const res = await apiProfile.post(requestData, "selectrole");
                this.$root.$data.store.setLocalStorage("token", res.token);
                this.$root.$data.store.setLocalStorage("tokenExpiration", Date.parse(res.expiration));

                await this.getProfile();

                let routerName = this.$root.$data.store.getRouteName("loggedinHome");

                this.$router.push({ name: routerName })
            },
            async getProfile() {
                const profileApi = new ApiService('profile');
                var profile = await profileApi.getProfile();

                if (profile && profile.length > 0) {
                    this.$root.$data.store.setProfile(profile[0]);
                }
            },
            resetLoginForm() {
                this.loading = false;
                this.loginPage = 1;
                this.showLogin = false;
            },
            changeLocale(newLocal) {
                this.$root.$data.store.changeLocale(newLocal);

                var result = { name: this.$route.name, params: { locale: i18n.locale }, query: this.$route.query }

                this.$router.push(result);
            }
        }

    }
</script>