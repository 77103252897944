<template>
    <cmpCreate ref="cmpCreate"
               v-bind:name="name"
               v-bind:pkColumn="pkColumn"
               v-bind:fields="fields"
               v-bind:parent="parent"
               v-bind:header="header"
               ></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"    
    import { codeName } from "@/util/constant"//�`��libaray


    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "ActivityTitle", "label": i18n.t('component.ActivityLog.ActivityTitle.label'), input: { type: "readonly" } },
                    { "key": "RespondentName", "label": i18n.t('component.ActivityLog.RespondentName.label'), input: { type: "readonly" } },
                    { "key": "MotionDataId", "label": i18n.t('component.ActivityLog.MotionDataId.label'), input: { type: "readonly" } },
                    { "key": "LogTime", "label": i18n.t('component.ActivityLog.LogTime.label'), input: { type: "datetime" }, required: true },
                    { "key": "ActivityLogStage", "label": i18n.t('component.ActivityLog.ActivityLogStage.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeNo", "CodeDisplay"], filterField: "CodeName", filterValue: codeName.ActivityLogStage }, required: true }
                ]
            },
            header() {
                return i18n.t('component.ActivityLog.insHeader')
            }
        },
        data() {

            return {
                name: "ActivityLog",
                pkColumn: "ActivityLogId",
                parent: {"name": "Activity", "key": "ActivityId" },
            };
        }
    }
</script>