<template>
    <cmpTableWithMenu v-bind:name="name"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:header="header"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    { "key": "UnitCode", "label": i18n.t('component.Unit.UnitCode.label'), sortable: true },
                    { "key": "UnitName", "label": i18n.t('component.Unit.UnitName.label'), sortable: true },
                    { "key": "UnitBriefName", "label": i18n.t('component.Unit.UnitBriefName.label'), sortable: true },
                    { "key": "Director", "label": i18n.t('component.Unit.Director.label'), sortable: true },
                    { "key": "UnitEmail", "label": i18n.t('component.Unit.UnitEmail.label'), sortable: true },
                    { "key": "Phone", "label": i18n.t('component.Unit.Phone.label'), sortable: true },
                    { "key": "UnitZipDisplay", "label": i18n.t('component.Unit.UnitZip.label'), sortable: true },
                ]
            },
            qryFields() {
                return [
                    { "key": "UnitName", "label": i18n.t('component.Unit.UnitName.label'), input: { type: "text" } },
                    { "key": "UnitBriefName", "label": i18n.t('component.Unit.UnitBriefName.label'), input: { type: "text" } },
                    { "key": "UnitCode", "label": i18n.t('component.Unit.UnitCode.label'), input: { type: "text" } }
                ]
            }
            ,
            header() {
                return i18n.t('component.Unit.lstHeader')
            }
        },
        data() {

            return {
                name: "Unit",
                pkColumn: "UnitId",
                children: {},
                parent: {},
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete'],
                moreTableFuncs: []
            };
        }
    }
</script>