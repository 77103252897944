<template>
    <cmpRec v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    { "key": "UnitCode", "label": i18n.t('component.Unit.UnitCode.label') },
                    { "key": "UnitName", "label": i18n.t('component.Unit.UnitName.label') },
                    { "key": "UnitBriefName", "label": i18n.t('component.Unit.UnitBriefName.label') },
                    { "key": "UnitTaxNumber", "label": i18n.t('component.Unit.UnitTaxNumber.label') },
                    { "key": "UnitTypeDisplay", "label": i18n.t('component.Unit.UnitType.label') },
                    { "key": "Director", "label": i18n.t('component.Unit.Director.label') },
                    { "key": "UnitEmail", "label": i18n.t('component.Unit.UnitEmail.label') },
                    { "key": "Url", "label": i18n.t('component.Unit.Url.label') },
                    { "key": "ContactPerson", "label": i18n.t('component.Unit.ContactPerson.label') },
                    { "key": "ContactTitle", "label": i18n.t('component.Unit.ContactTitle.label') },
                    { "key": "Phone", "label": i18n.t('component.Unit.Phone.label') },
                    { "key": "Mobile", "label": i18n.t('component.Unit.Mobile.label') },
                    { "key": "UnitZipDisplay", "label": i18n.t('component.Unit.UnitZip.label') },
                    { "key": "UnitAddress", "label": i18n.t('component.Unit.UnitAddress.label') },
                    { "key": "UnitBankAtm", "label": i18n.t('component.Unit.UnitBankAtm.label') },
                    { "key": "UnitAccount", "label": i18n.t('component.Unit.UnitAccount.label') },
                    { "key": "UnitMemo", "label": i18n.t('component.Unit.UnitMemo.label') }
                ]
            },
            header() {
                return i18n.t('component.Unit.recHeader')
            }
        },
        data() {

            return {
                name: "Unit",
                pkColumn: "UnitId",
                parent: {}
            };
        }
    }
</script>