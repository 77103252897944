<template>
    <cmpTableWithMenu v-bind:name="name"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:header="header"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "RoleId", "label": i18n.t('component.Role.RoleId.label'), sortable: true },
                    { "key": "RoleName", "label": i18n.t('component.Role.RoleName.label'), sortable: true },
                    { "key": "RoleDisplayName", "label": i18n.t('component.Role.RoleDisplayName.label'), sortable: true },
                ]
            },
            qryFields() {
                return [
                    { "key": "RoleName", "label": i18n.t('component.Role.RoleName.label'), input: { type: "text" } },
                    { "key": "RoleDisplayName", "label": i18n.t('component.Role.RoleDisplayName.label'), input: { type: "text" } }
                ]
            }
            ,
            header() {
                return i18n.t('component.Role.lstHeader')
            }
        },
        data() {

            return {
                name: "Role",
                pkColumn: "RoleId",
                children: {},
                parent: {},
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete'],
                moreTableFuncs: []
            };
        }
    }
</script>