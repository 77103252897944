<!-- 用 table 的方式顯示多筆資料，加上一些功能按鈕 -->
<template>
    <div>
        <div style="text-align:center">
            <div id="cmpList_banner" v-if="showBanner" :style="{ backgroundImage: `url(${bannerImage})`}"></div>
            <div id="webpath">{{header}}</div>

            <div class="mb-1">
                <cmpMenuButton v-if="showAddButton" v-bind:label="$t('form.add')"
                               v-bind:imagePath="'assets/icons/IcnAdd.png'"
                               v-bind:hasPrivilege="this.privileges['Create']"
                               @click="$router.push(getInsRouterLink(name))">
                </cmpMenuButton>

                <cmpMenuButton v-bind:label="$t('form.search')"
                               v-bind:imagePath="'assets/icons/IcnSearch.png'"
                               v-bind:hasPrivilege="this.privileges['Read']"
                               v-bind:dataToggle="'collapse'"
                               v-bind:dataTarget="'#content_search_1'">
                </cmpMenuButton>

                <cmpMenuButton v-bind:label="$t('form.print')"
                               v-bind:imagePath="'assets/icons/IcnPrint.png'"
                               v-bind:hasPrivilege="this.privileges['Print']"
                               @click="print()">
                </cmpMenuButton>

                <cmpMenuButton v-bind:label="$t('form.import')"
                               v-bind:imagePath="'assets/icons/IcnUp.png'"
                               v-bind:hasPrivilege="this.privileges['Import']"
                               @click="$refs.uploadFileComponent.show()">
                </cmpMenuButton>

                <cmpMenuButton v-bind:label="$t('form.export')"
                               v-bind:imagePath="'assets/icons/IcnDown.png'"
                               v-bind:hasPrivilege="this.privileges['Export']"
                               @click="exportRecords()">
                </cmpMenuButton>

                <cmpMenuButton v-if="showDeleteButton" v-bind:label="$t('form.delete')"
                               v-bind:imagePath="'assets/icons/IcnTrash.png'"
                               v-bind:hasPrivilege="this.privileges['Delete']"
                               @click="deleteRecords()">
                </cmpMenuButton>

            </div>

            <div class="collapse" id="content_search_1">
                <cmpSearch @submit="searchSubmit"
                           @cancel="searchCancel"
                           v-bind:qryFields="qryFields"
                           v-bind:id="name">
                </cmpSearch>
            </div>

        </div>

        <!-- Modal -->
        <cmpUploadFile ref="uploadFileComponent" @submit="importRecords">
        </cmpUploadFile>

        <slot></slot>

        <br /><br />
        <cmpListTable v-bind:name="name"
                      v-bind:controllerName="controllerName"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"
                      v-bind:privileges="privileges"
                      v-bind:query="query"
                      v-bind:showBackButton="showBackButton"
                      v-bind:cookieQueryFields="cookieQueryFields"
                      v-bind:pageText="pageText"
                      @callback="tableCallback"></cmpListTable>

    </div>
</template>
<style>

    .sr-only {
        display: none;
        width: 0px
    }
    #cmpList_banner {
        margin-bottom: 5px;
        width: 100%;
        background-size: 100% 100%;
        height: 150px
    }
</style>

<script>
    import ApiService from '@/ApiService';
    import cmpSearch from "@/components/common/cmpSearch"
    import cmpUploadFile from "@/components/common/cmpUploadFile"
    import cmpListTable from "@/components/common/cmpListTable"
    import cmpMenuButton from "@/components/common/cmpMenuButton"
    import i18n from '@/i18n'

    import $ from 'jquery'
    var api = undefined
    export default {
        components: {
            cmpSearch,
            cmpUploadFile,
            cmpListTable,
            cmpMenuButton
        },
        data() {
            return {
                loading: false, // 是不是正在從後台讀取資料
                qryModel: {},   // bind 到 query string 的 object
                privileges: {}  // 使用者的權限
            };
        },
        props: {
            name: String,       // 資料庫 table 的名稱，也是 Controller 的名字
            controllerName: { type: String, default: "" },  // 要 override 這個畫面要 call 的 Controller 的名字
            pkColumn: String,   // 資料庫 table PK 的名稱
            fields: Array,      // 要顯示在 table 的欄位
            qryFields: Array,   // 要顯示在搜尋畫面的欄位
            children: Object,   // 子畫面的 information
            header: String,     // 顯示的標題
            parent: Object,     // 母畫面的 information
            tableFuncs: Array,  // 要顯示的功能按鈕
            bannerImage: String,     // banner 位置
            moreTableFuncs: { type: Array, default: () => [] },    // 更多功能的按鈕
            query: Object,    // Default 的搜尋條件
            showBanner: { type: Boolean, default: false }, //  要不要顯示 "Banner"
            showBackButton: { type: Boolean, default: true }, //  要不要顯示 "返回" 按鈕
            showAddButton: { type: Boolean, default: true }, //  要不要顯示 "新增" 按鈕,
            showDeleteButton: { type: Boolean, default: false }, //  要不要顯示 "刪除" 按鈕,
            insQueryFields: { type: Array, default: () => [] }, // 從這個畫面到新增畫面時, 要傳過去的 querystring
            cookieQueryFields: { type: Array, default: () => [] }, // 在這個要顯示資料時, 要從 cookie 裡看有沒有值, 如果有, 要把 cookie 的值一起做搜尋
            pageText: String, // 顯示在頁數旁邊的字
        },
        async created() {
            api = new ApiService(this.name)
            this.qryModel = JSON.parse(JSON.stringify(this.$route.query))

            // Get privilege
            const p = this.$root.$data.store.getPrivilege(this.name);

            if (p && p.length > 0) {
                this.privileges['Create'] = p[0]['Create'];
                this.privileges['Delete'] = p[0]['Delete'];
                this.privileges['Export'] = p[0]['Export'];
                this.privileges['Import'] = p[0]['Import'];
                this.privileges['Print'] = p[0]['Print'];
                this.privileges['Read'] = p[0]['Read'];
                this.privileges['Update'] = p[0]['Update'];
            }
        },
        methods: {
            /*
             *  列印
             */
            print() {
                this.$emit("print", this.qryModel);
            },
            /*
             *  匯入資料
             *  @param {array} formData 要匯入的資料
             */
            async importRecords(formData) {
                try {
                    let result = await api.uploadFile(formData);
                    this.$refs.uploadFileComponent.cancel()

                    if (result === false) {
                        alert(i18n.t('message.importFailed'));
                    }
                    else {
                        alert(i18n.t('message.importSuccess'));
                    }

                }
                catch (err) {
                    alert(i18n.t('message.importFailed'));
                }


            },
            /*
             *  匯出資料
             */
            exportRecords() {
                //this.qryModel = JSON.parse(JSON.stringify(this.$route.query))
                api.exportExcel(JSON.parse(JSON.stringify(this.qryModel)))
            },
            /*
             *  刪除資料
             */
            deleteRecords() {
                this.$emit("deleteRecords", this.qryModel);
            },
            /*
             *  建到新增畫面的 route
             *  @param {string} name 主要 route 的名稱
             */
            getInsRouterLink(name) {
                var routerName = this.$root.$data.store.getRouteName(name, "ins");

                var result = { name: routerName, query: { action: 'C' } }
                if (this.parent && Object.keys(this.parent).length > 0) {
                    result['query'][this.parent.key] = this.qryModel[this.parent.key]
                }


                // 要傳到新增畫面的值
                for (var i = 0; i < this.insQueryFields.length; i++) {
                    if (this.insQueryFields[i] in this.qryModel) {
                        result['query'][this.insQueryFields[i]] = this.qryModel[this.insQueryFields[i]]
                    }
                }

                return result
            },
            /*
             *  執行搜尋
             *  @param {object} queryModel 要搜尋的條件
             */
            searchSubmit(queryModel) {
                // 存Parent ID
                if (this.parent && this.parent.key in this.$route.query) {
                    queryModel[this.parent.key] = this.$route.query[this.parent.key]
                }
                
                var result = { name: this.$route.name }
                this.qryModel = queryModel
                result['query'] = this.qryModel
                this.$router.push(result).catch(() => { });
            },
            /*
             *  取消搜尋
             */
            searchCancel() {
                $('#content_search_1').collapse('hide');
            },
            /*
             *  cmpListTable 的 Callback 
             *  @param {string} callbackName callback 的名稱
             *  @param {number} id Trigger callback 的這筆資料的 id
             *  @param {object} record Trigger callback 的這筆資料
             */
            tableCallback(callbackName, id, record) {
                this.$emit(callbackName, id, record);
            }
        }
    }
</script>
