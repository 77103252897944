<!-- 顥示一個 form 做搜尋的畫面 -->
<template>
    <div id="contenttitle_text">
        <table border="0" align="center" cellpadding="5" cellspacing="5">
            <tbody>
                <tr>
                    <td colspan="7" align="center">{{ $t('form.search') }}</td>
                </tr>
                <tr v-for="child in qryFields" :key="child.key">
                    <td align="left">{{child.label}} <span v-if="child.required">*</span></td>
                    <td colspan="5" align="left" valign="middle">
                        <input v-if="!child.input || child.input.type == 'text'" type="text" v-bind:id="`qry${child.key}`" v-model="qryModel[child.key]">
                        <input v-else-if="!child.input || child.input.type == 'number'" type="number" v-bind:id="`qry${child.key}`" v-model="qryModel[child.key]">

                        <cmpSelect v-else-if="child.input.type == 'select'" v-model="qryModel[child.key]" v-bind:name="child.key"
                                   v-bind:options="formBind[child.key]" v-bind:key="child.key" v-bind:id="`qry${child.key}`"
                                   v-bind:filterValue="selectFilter[child.key]"
                                   v-on:selectedValue="$set(selectedValue, child.key, $event)">
                        </cmpSelect>

                        <cmpSelect v-else-if="child.input.type == 'selectmulti'" v-model="qryModel[child.key]" v-bind:id="`qry${child.key}`"
                                   v-bind:options="formBind[child.key]"
                                   v-bind:isMultiple="true">
                        </cmpSelect>

                        <span v-else-if="child.input.type == 'date'">
                            <input type="date" v-bind:id="`qry${child.key}Min`" v-model="qryModel[child.key + 'Min']" /> &nbsp;&nbsp;
                            <input type="date" v-bind:id="`qry${child.key}Max`" v-model="qryModel[child.key + 'Max']" />
                        </span>

                        <span v-else-if="child.input.type == 'datetime'">
                            <input type="datetime-local" v-bind:id="`qry${child.key}Min`" v-model="qryModel[child.key + 'Min']" /> &nbsp;&nbsp;
                            <input type="datetime-local" v-bind:id="`qry${child.key}Max`" v-model="qryModel[child.key + 'Max']" />
                        </span>

                        <cmpTextboxSearch v-else-if="child.input.type == 'textboxSearch'"
                                          v-model="qryModel[child.key]"
                                          v-bind:id="`qry${child.key}`"
                                          v-bind:idField="child.input.idField"
                                          v-bind:objectName="child.input.objectName"
                                          v-bind:header="child.label"
                                          v-bind:qryFields="child.input.qryFields"
                                          v-bind:displayFields="child.input.displayFields"
                                          v-bind:displayField="child.input.displayField"
                                          v-bind:displayFieldValue="qryModel[child.input.editDisplayField]"
                                          v-bind:ref="child.key">
                        </cmpTextboxSearch>

                        <label class="error-label" v-if="errorMessage[child.key]">{{ errorMessage[child.key] }}</label>
                    </td>
                </tr>
                <tr>
                    <td colspan="7" align="center">
                        <div class="btn9" id="qrySubmit" @click.prevent="submit()"><font color="white">✔ {{ $t('form.submit') }}</font></div>
                        <div class="btn9" id="qryCancel" @click.prevent="cancel()">✘ {{ $t('form.goBack') }}</div>
                        <div class="btn9" id="qryClearSearch" @click.prevent="clearSearch()">⟳ {{ $t('form.reset') }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <div class="contentsearch_submit"></div>
    </div>
</template>

<script>
    import cmpSelect from "@/components/common/cmpSelect"
    import cmpTextboxSearch from "@/components/common/cmpTextboxSearch"
    import ApiService from '@/ApiService';
    var api = new ApiService('search')
    import { localToGmt } from '@/util/dateutil';
    import { validateEmail } from '@/util/validator';
    import i18n from '@/i18n'
    import $ from 'jquery'

    export default {
        components: {
            cmpSelect, cmpTextboxSearch
        },
        data() {
            return {
                qryModel: {},       // bind 到這個 form 的 Object
                formBind: {},       // bind 到各別 input 的值，例如 combobox, checkbox, radio button
                selectFilter: {},   // 存要傳給 combobox 需要 filter 的值
                selectedValue: {},  // 存所有 combobox 目前被選取的 option
                errorMessage: {},   // 每個欄位的錯誤訊息
            };
        },
        props: {
            qryFields: Array,   // 要顥式的欄位
            id: String          // bind 要 <input> 的 id
        },
        watch: {
            selectedValue: {
                handler: function (newValue) {

                    for (let f of this.$props.qryFields) {
                        if (['select', 'radio', 'checkbox', 'selectmulti'].includes(f.input.type)) {

                            // Check if needs to filter
                            if (f.input.parent) {
                                if (f.input.parent in newValue) {
                                    this.$set(this.selectFilter, f.key, newValue[f.input.parent]['pk']);
                                }
                                else {
                                    this.$set(this.selectFilter, f.key, null);
                                }
                            }
                        }
                    }
                },
                deep: true
            }

        },
        async created() {
            for (let f of this.$props.qryFields) {
                if (['select', 'radio', 'checkbox', 'selectmulti'].includes(f.input.type)) {
                    let res = null;
                    if (f.input.objectName == "Custom") {
                        res = f.input.customValue;
                    }
                    else {
                        res = await this.getSelectOptions(f.input.objectName, f.input.idField, f.input.displayField, f.input.filterField, f.input.filterValue, f.input.pkField)
                    }

                    this.$nextTick(() => {
                        this.$set(this.formBind, f.key, res);
                    });

                    // Check if needs to filter
                    if (f.input.parent) {
                        this.$set(this.selectFilter, f.key, null);
                    }
                }

                if (f.defaultValue) {
                    this.$set(this.qryModel, f.key, f.defaultValue)
                } else {
                    this.qryModel[f.key] = null
                }
            }
        },
        methods: {
            /*
             *  執行搜尋
             */
            submit() {
                // 驗證 form
                if (!this.validate())
                    return;

                var result = {};

                for (const key in this.qryModel) {
                    if (this.qryModel[key]) {

                        const queryFieldKey = key.replace("Min", "").replace("Max", "");
                        const queryField = this.$props.qryFields.filter(t => t.key == queryFieldKey && ['date'].includes(t.input.type));

                        // 是日期, 改成 gmt
                        if (queryField.length > 0) {
                            let gmtDateString = localToGmt(this.qryModel[key]);
                            result[key] = gmtDateString;
                        }
                        else {
                            result[key] = this.qryModel[key];
                        }
                    }
                }

                this.$emit("submit", result);
            },
            /*
             *  取消搜尋
             */
            cancel() {
                this.$emit("cancel");
            },
            /*
             *  清除整個 form 的值
             */
            clearSearch() {
                this.selectFilter = {};
                this.selectedValue = {};

                // 設 combobox 的值
                for (const key in this.qryModel) {
                    this.qryModel[key] = null;
                    $("#" + key).val(null);
                }

                for (let f of this.$props.qryFields) {
                    if (['textboxSearch'].includes(f.input.type)) {
                        this.$refs[f.key][0].clearSearch();
                    }
                }

            },
            /*
             *  從後台讀取像 combobox, radiobutton 選項的值
             *  @param {string} objectName 要從資料庫讀取選項的 table 名稱
             *  @param {string} idField ID 欄位的名稱
             *  @param {string} displayField 要顥式欄位的名稱
             *  @param {string} filterField 要搜尋的欄位名稱
             *  @param {string} filterValue 要搜尋的欄位的值
             *  @param {string} pkField 資料 PK 欄位的名稱
             */
            async getSelectOptions(objectName, idField, displayField, filterField, filterValue, pkField) {
                var data = {}

                if (filterField && filterValue)
                    data[filterField] = filterValue

                data['language'] = i18n.locale;

                var res = await api.getSelectOptions(objectName, data);

                var result = res.Data.reduce((unique, row) => {

                    let rowText = "";
                    if (Array.isArray(displayField)) {
                        for (let v of displayField) {
                            rowText = rowText + " " + row[v];
                        }
                    }
                    else {
                        rowText = row[displayField];
                    }

                    // 只加沒有重複的
                    if (!unique.some(obj => obj.text === rowText)) {

                        var val = {
                            value: row[idField], text: rowText
                        };
                        if (pkField && row.hasOwnProperty(pkField)) {
                            val['pk'] = row[pkField];
                        }

                        unique.push(val);
                    }
                    return unique;
                }, []);


                //return [{ value: '', text: '請選擇' }].concat(result);
                return result;
            },
            /*
             *  驗證這個 form，有錯誤時顥式錯誤訊息
             */
            validate() {
                this.errorMessage = {};
                for (let f of this.$props.qryFields) {
                    if (f.required) {
                        if (!this.qryModel[f.key]) {
                            let errorMsg = i18n.t('errorMessage.requiredField').replace("@col@", f.label);
                            this.$set(this.errorMessage, f.key, errorMsg)
                        }
                    }
                    else if (f.input.format == "email") {
                        if (this.qryModel[f.key] && !validateEmail(this.qryModel[f.key])) {
                            let errorMsg = i18n.t('errorMessage.emailFormat').replace("@col@", f.label);
                            this.$set(this.errorMessage, f.key, errorMsg)
                        }
                    }
                }

                return Object.keys(this.errorMessage).length == 0;
            }
        }
    }

</script>