
const formType = {
    PDMS: "PDMS-2SUM",
    CDIIT: "CDIITSUM",
    MOTION: "復健評估",
    RBMForm:"RBM",
}

const userRole = {
    PARENT: "Parent"
};

const codeName = {
    ActivityStatus: "ActivityStatus",
    ActivityType: "ActivityType",
    ActivityVenue: "ActivityVenue",
    BasicMovement: "BasicMovement",
    ClassType: "ClassType",
    CourseType: "CourseType",
    DiagnoseID: "DiagnoseID",
    DisabilityClass: "DisabilityClass",
    DisabilityDegree: "DisabilityDegree",
    Economic: "Economic",
    Ethnic: "Ethnic",
    Gender: "Gender",
    MemberAttribute: "MemberAttribute",
    MemberRole: "MemberRole",
    OtherTreatment: "OtherTreatment",
    ProjectFamily: "ProjectFamily",
    ProjectRole: "ProjectRole",
    ProjectSource: "ProjectSource",
    ProjectStage: "ProjectStage",
    QuestionType: "QuestionType",
    RecActiveStatus: "RecActiveStatus",
    SensorPosition: "SensorPosition",
    SevereIllnessCard: "SevereIllnessCard",
    SubSiteID: "SubSiteID",
    UnitType: "UnitType",
    ZipCode: "ZipCode",
    DocType: "DocType",
    ActivityLogStage: "ActivityLogStage",

};

export { formType, userRole, codeName };