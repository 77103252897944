<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() { 
                return [
                    {
                        "key": "UserId", "label": i18n.t('component.Login.UserId.label'), input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", editDisplayField: "UserName", qryFields: ["MemberIdOr", "NameOr"], displayFields: ["MemberId", "Name"] }
                        , hideIfQuery: true
                    },
                    { "key": "UserName", "label": i18n.t('component.Login.UserName.label'), input: { type: "readonly", objectName: "User", idField: "UserId", displayField: "UserName", modelField: "UserId", responseField: "UserId" } },
                    { "key": "Account", "label": i18n.t('component.Login.Account.label'), input: { type: "text" } },
                    { "key": "Password", "label": i18n.t('component.Login.Password.label'), input: { type: "confirmPassword" }, required: true },
                    { "key": "Roles", "label": i18n.t('component.Login.Roles.label'), input: { type: "selectmulti", objectName: "Role", idField: "RoleId", displayField: ["RoleDisplayName"] } }
                ]
            },
            header() {
                return i18n.t('component.Login.insHeader')
            }
        },
        data() {

            return {
                name: "Login",
                pkColumn: "LoginId",
                parent: {}
            };
        }
    }
</script>