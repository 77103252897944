<template>
    <div style="margin:0 40px 0 40px">
        <br />
        <div id="webpath"><h4>隱私權聲明</h4></div>

        本公司十分重視用戶的隱私權，為保障您的權益， 請您詳閱下列隱私權保護政策內容：

        <h5>一、隱私權保護政策適用範圍</h5>
        隱私權保護政策內容，包括本公司如何處理下列資料：
        <ol>
            <li>使用者本服務及於本網站上新增/查詢/異動資訊等時，所蒐集到的個人資料及其他資訊。</li>
            <li>合作伙伴(包括但不限於商業組織、政府機關等)、關係企業與本公司合作時分享的任何個人資料及其他資訊。</li>
        </ol>
        <h5>二、資料之蒐集</h5>
        此資料的蒐集目的係為確認在本公司網站取得資訊的使用者身份及提供各項服務之用，其範圍如下：
        本公司在您使用本網站服務、瀏覽本網站網頁。 本公司會保留您所提供的上述資料，也會保留您上網瀏覽或查詢時，在系統上產生的相關記錄，包括IP位址、使用時間、瀏覽器、瀏覽及點選紀錄等。其他內容請見下列個資告知事項。

        <h5>個資告知事項</h5>
        為確保您的權益，請詳閱以下個人資料保護法告知事項：
        <ol>
            <li>非公務機關名稱：台灣大哥大股份有限公司。</li>
            <li>蒐集之目的：統計與研究分析</li>
            <li>個人資料之類別：依主管機關公告之個人資料類別（包括但不限於載於各項申請文件之客戶、其代理人及法定代理人之個人資料等）。</li>
            <li>
                個人資料利用之期間、地區、對象及方式：
                <ol>
                    <li>期間：台灣大哥大營運期間內。</li>
                    <li>地區：中華民國境內、境外（主管機關禁止者不在此限）。</li>
                    <li>對象：台灣大哥大、受台灣大哥大委託或與台灣大哥大有合作或業務往來之台灣大哥大關係企業及合作廠商。</li>
                    <li>方式：符合法令規定範圍之利用。</li>
                </ol>
            </li>
            <li>蒐集並儲存個人敏感性資料，個人資料包含以下: 姓名、帳號、密碼、生日、身高、體重、性別</li>
            <li>因應操作者使用上的辨識，個人敏感性資料將不會遮蔽顯示</li>
            <li>客戶得行使個人資料保護法第三條規定之請求查詢、製給複製本、補充或更正、停止蒐集、處理及利用、刪除等權利，其行使方式及收費方式依法令及台灣大哥大相關規定。台灣大哥大得依個人資料保護法第10條、第11條規定，執行業務所必須及法定保存期間等考量，決定是否接受申請。</li>

        </ol>

        <h5>三、資料之保護</h5>
        本公司會將您的個人資料完整儲存於我們的資料儲存系統中，並以嚴密的保護措施防止未經授權人員之接觸。本公司的人員均接受過完整之資訊保密教育，充分瞭解用戶資料之保密為基本責任，如有違反保密義務者，將受相關法律及公司內部規定之處分。 為了保護您個人資料之完整及安全，保存您個人資料之資料處理系統均已受妥善的維護，並符合相關主管機關嚴格之要求，以保障您的個人資料不會被不當取得或破壞。 如因業務需要有必要委託第三者提供服務時，本公司亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。

        <h5>四、資料之使用</h5>
        本公司對於您個人資料之使用，僅在蒐集之特定目的及相關法令規定之範圍內為之。 除非經由您的同意或其他法令之特別規定，本網站絕不會將您的個人資料揭露於第三人或使用於蒐集目的以外之其他用途。
        司法單位/政府機關因公眾安全依法要求本公司提供特定個人資料時，本公司將依司法單位/政府機關合法正式的程序，以及對本公司所有使用者安全考量下做可能必要的配合。

        <h5>五、連結網頁之使用</h5>
        本網站的網頁可能提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但本網站並不保護您於該連結網站中的隱私權。

        <h5>六、個人資料之修正</h5>
        當您發現個人資料須修改時，經過身分確認無誤後，您可透過本網站提供之服務管道進行修改、更正以確保自身權益。修正與刪除個人資料作業流程如下。
        <ol>
            <li>確認身分。</li>
            <li>更正或刪除個人資料，本公司於三十日內完成審查與回覆；若需延長則不得逾三十日，並以書面通知原因。</li>
            <li>拒絕請求時，本公司確認原因後通知說明。</li>
            <li>設置客服專線，提供您處理個人資料相關服務、申訴或諮詢。</li>
        </ol>

        <h5>七、Cookie之使用</h5>
        為提供您更完善的個人化服務，本網站可能會使用Cookie以紀錄及分析使用者行為，此系統能夠辨識使用者，例如依您偏好的特定種類資料執行不同動作。

        <h5>八、隱私權保護政策之修正</h5>
        本公司隱私權保護政策將因應需求隨時進行修正，以落實保障使用者隱私權之立意。修正後的條款將刊登於本網站上。

        <br /><br />

        <div v-if="showBackButton" style="text-align:center; margin:0 auto">
            <div class="btn9" @click.prevent="goBack()">✘ 返回</div>
        </div>

        <br /><br />

    </div>
</template>

<style>
    h5 {
        margin-top: 15px;
        margin-bottom: 20px;
    }
</style>

<script>
    export default {
        data() {
            return {
                backgroundImagePath: ""
            };
        },
        computed: {
            showBackButton: function () {
                return !this.$route.name.includes("NoMenu");
            }
        },
        async created() {
            this.backgroundImagePath = '/images/background_home_admin.jpg';
        },
        methods: {
            goBack() {
                var routerName = this.$root.$data.store.getRouteName("loggedinHome");
                this.$router.push({ name: routerName })
            }
        }

    }
</script>
