<!-- 一個用畫片當按鈕的 component -->
<template>
    <div v-bind:class="hasPrivilege ? 'tooltip' : 'no-tooltip'">
        <a @click.prevent="clicked()" style="cursor: pointer" v-bind:class="!hasPrivilege && 'disabled-link'">
            <img v-bind:id="id" :src="require(`@/${imagePath}`)" v-bind:data-toggle="dataToggle ? dataToggle : false"
                v-bind:data-target="dataTarget ? dataTarget : false" />
        </a>
        <span v-if="label" v-bind:class="hasPrivilege ? 'tooltiptext' : 'd-none'">{{ label }}</span>
    </div>
</template>

<script>
export default {
    props: {
        label: String,          // 顯示在 tooltip 的文字
        imagePath: String,      // 按鈕畫片檔的路徑
        hasPrivilege: Boolean,  // 有沒有權限按這個按鈕
        dataToggle: String,     // bootstrap 的 data-toggle 的值
        dataTarget: String,      // bootstrap 的 data-target 的值     
        id: {
            type: String,
            default: null,
        },
    },
    methods: {
        /*
         *  按按鈕 call 的 method
         */
        clicked() {
            if (this.hasPrivilege) {
                this.$emit('click');
            }
        }
    }
}
</script>