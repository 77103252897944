<template>
    <div class="mb-5 col-11 col-xl-5">
        <cmpChart v-bind:chart-type="'ImageUrl'" v-bind:image-src="dynamicImageUrl"> </cmpChart>
    </div>
</template>

<script>
import cmpChart from '../common/cmpChart.vue';

export default {
    components: {
        cmpChart,
    },
    data() {
        return {
            brainScore: 0,
            brainPictureCondition1: 0,
            brainPictureCondition2: 0

        };
    },
    props: {
        motionDataId: {
            type: Number,
            default: null
        },
        chartObj: {
            type: Array,
            default: () => []
        },
        features: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        dynamicImageUrl() {
            const baseImageUrl = `${process.env.VUE_APP_S3PUBLIC}/vrca/`;
            const condition1 = this.brainPictureCondition1 === 0 ? '0' : '1';
            const condition2 = this.brainPictureCondition2 === 0 ? '0' : '1';
            return `${baseImageUrl}${condition1}${condition2}.jpg`;
        }
    },
    methods: {
        async compare(array1) {
            let count = 0;
            array1.forEach(function (item) {
                const featureValue = item.featureValue;
                const featureAvgValue = item.featureAvgValue;
                if (featureValue > featureAvgValue) {
                    count++;
                }
            });
            console.log(this.features);

            this.brainScore = this.features.SSVEP_SNR;
            this.brainPictureCondition1 = count >= 4 ? 1 : 0;
            this.brainPictureCondition2 = this.brainScore <= 4.8 ? 1 : 0;
        },

    },
    created() {
        this.compare(this.chartObj);
    },
}
</script>