<!-- 用 table 顯示從後台讀取的資料 -->
<template>
    <div>
        <b-table :items="searchResult"
                 :fields="displayFieldsLabels"
                 select-mode="single"
                 responsive="md"
                 ref="resultTable"
                 selectable
                 borderless
                 @row-selected="onRowSelected">
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                </template>
            </template>
        </b-table>
    </div>
</template>

<style>
    .hidden_header {
        display: none;
    }
</style>

<script>
    import i18n from '@/i18n'
    import ApiService from '@/ApiService';

    //var api = undefined;

    export default {
        data() {
            return {
                searchResult: [],   // 全部從後台讀取的資料
                selectedRow: []     // 選取的資料
            };
        },
        props: {
            objectName: String,     // 要搜尋的資料庫 table 的名稱
            header: String,         // 標題（目前沒顯示）
            qryFields: Array,       // 要用來搜尋的欄位名稱
            displayFields: Array    // 要顯示在 table 的欄位名稱
        },
        computed: {
            api: function () {
                return new ApiService(this.objectName)
            },
            displayFieldsLabels: function () {
                let labels = [];

                for (let i = 0; i < this.displayFields.length; i++) {
                    let labelName = `component.${this.objectName}.${this.displayFields[i]}.label`;
                    labels.push({
                        key: this.displayFields[i],
                        label: i18n.t(labelName)
                    });
                    //labels.push(this.displayFields[i]);
                }

                return labels;
            }
        },
        methods: {
            /*
             *  執行搜尋
             *  @param {string} searchTerm 搜尋的 keyword
             *  @param {array} 其他要用來搜尋的 keywords
             */
            async search(searchTerm, otherSearchList) {
                var result = {};

                for (const val of this.qryFields) {
                    result[val] = searchTerm;
                }

                if (otherSearchList) {
                    for (var key in otherSearchList) {
                        result[key] = otherSearchList[key];
                    }
                }


                result['language'] = i18n.locale;

                var res = await this.api.getAll(result)
                this.searchResult = res["Data"];

            },
            /*
             *  在 table 選取資料後的 event
             *  @param {array} items 從 table 選取的資料
             */
            onRowSelected(items) {
                this.selected = items
                this.submit();
            },
            /*
             *  搜尋後 fire 一個 callback
             */
            submit() {
                this.searchResult = [];
                this.$emit("submit", this.selected);

            }
        }
    }

</script>