<!-- 用 table 的方式顯示多筆活動資料，並顯示CRUDIEP等功能按鈕 -->
<template>
    <cmpTableWithMenu v-bind:name="name"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:header="header"
                      v-bind:showBanner="showBanner"
                      v-bind:bannerImage="bannerImage"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    //多國語言library
    import i18n from '@/i18n'
    import { codeName, formType } from "@/util/constant"
    // 匯入cmpTableWithMenu component
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            //多筆畫面欄位
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    //key: 欄位名稱, label:欄位說明 sortable:是否可以SORT的欄位
                    { "key": "RespondentName", "label": i18n.t('component.Activity.RespondentName.label'), sortable: true },
                    { "key": "ActivityTitle", "label": i18n.t('component.Activity.ActivityTitle.label'), sortable: true },
                    { "key": "CourseName", "label": i18n.t('component.Activity.CourseName.label'), sortable: true },
                    { "key": "ActivityStatusName", "label": i18n.t('component.Activity.ActivityStatus.label'), sortable: true },
                    { "key": "ActivityTypeName", "label": i18n.t('component.Activity.ActivityType.label'), sortable: true },
                    { "key": "ProjectStageName", "label": i18n.t('component.Activity.ProjectStage.label'), sortable: true },
                    //key: 欄位名稱, label:欄位說明, input 輸入欄位 type:datetime
                    { "key": "ActivityStartDate", "label": i18n.t('component.Activity.ActivityStartDate.label'), sortable: true, type: "datetime" },
                    //{ "key": "ActivityPeriod", "label": i18n.t('component.Activity.ActivityPeriod.label'), sortable: true },
                    { "key": "ActivityEndDate", "label": i18n.t('component.Activity.ActivityEndDate.label'), sortable: true, type: "datetime" },
                    { "key": "ModeratorName", "label": i18n.t('component.Activity.ModeratorName.label'), sortable: true },
                ]
            },
            //按搜尋按鍵,會出現的畫面欄位
            qryFields() {
                return [
                    //{ "key": "RespondentId", "label": i18n.t('component.Activity.RespondentName.label'), input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", pkField: "UserId", qryFields: ["MemberIdOr", "NameOr"], displayFields: ["MemberId", "Name"] } },
                    { "key": "Respondent", "label": i18n.t('component.Activity.RespondentName.label'), input: { type: "text" } },
                    { "key": "ActivityTitle", "label": i18n.t('component.Activity.ActivityTitle.label'), input: { type: "text" } },
                    { "key": "ProjectStage", "label": i18n.t('component.Activity.ProjectStage.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: "CodeDisplay", filterField: "CodeName", filterValue: codeName.ProjectStage } },
                    { "key": "ActivityType", "label": i18n.t('component.Activity.ActivityType.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: "CodeDisplay", filterField: "CodeName", filterValue: codeName.ActivityType } },
                    { "key": "ActivityStatus", "label": i18n.t('component.Activity.ActivityStatus.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: "CodeDisplay", filterField: "CodeName", filterValue: codeName.ActivityStatus } },
                    { "key": "ModeratorId", "label": i18n.t('component.Activity.ModeratorName.label'), input: { type: "textboxSearch", objectName: "User", idField: "UserId", displayField: "Name", pkField: "UserId", qryFields: ["MemberIdOr", "NameOr"], displayFields: ["MemberId", "Name"] } },
                    { "key": "ActivityStartDate", "label": i18n.t('component.Activity.ActivityStartDate.label'), input: { type: "date" } }
                ]
            }
            ,
            header() {
                //畫面Header 路徑
                return i18n.t('component.Activity.lstHeader')
            },
            bannerImage() {
                //畫面Header 路徑
                return '/images/ActivityBanner.jpg';
            },
            moreTableFuncs() {
                //按下... 出現的panel 的按鍵
                return [
                    //call lstMotionData
                    { key: "BtnActivityMotionData", icon: "IcnGo.png", tooltip: i18n.t('component.Activity.ToolTip.MotionData'), function: { type: 'route', name: "lstMotionData", key: "ActivityId" } },
                    //call lstActivityParticipant
                    { key: "BtnActivityActivityParticipant", icon: "IcnTeam.png", tooltip: i18n.t('component.Activity.ToolTip.ActivityParticipant'), function: { type: 'route', name: "lstActivityParticipant", key: "ActivityId" } },
                    { key: "BtnActivityLog", icon: "IcnTeam.png", tooltip: i18n.t('component.Activity.ToolTip.ActivityLog'), function: { type: 'route', name: "lstActivityLog", key: "ActivityId" } },
                ]
            }
        },
        data() {

            return {
                name: "Activity",
                pkColumn: "ActivityId",
                showBanner: true,
                children: {},
                parent: {},
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete'],
            };
        }
    }
</script>