<template>
    <cmpConcussion  v-if="reportType=='Concussion'" v-bind:motionDataId="motionDataId" v-bind:chartObj ="chartObj" v-bind:features="features">
    </cmpConcussion>
</template>

<script>
import cmpConcussion from './cmpConcussion.vue'
export default {
    name: 'reportCustomizeContent',
    components: {
        cmpConcussion,
    },
    data() {
        return {
           
        };
    },
    props: {
        reportType: {
            type: String,
            default: ''
        },
        motionDataId:{
            type: Number,
            default: null
        },
        chartObj: {
            type: [],
            default: () => []
        },
        features: {
            type: {},
            default: () => {}
        },


    },
    created() {
    },


}
</script>