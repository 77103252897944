<template>
    <cmpCreate :name="name"
               :subPath="subPath"
               :pkColumn="pkColumn"
               :fields="fields"
               :parent="parent"
               :header="header"
               :goBackAfterCreate="goBackAfterCreate"
               v-model="formData"
               ref="cmpCreate">
    </cmpCreate>
</template>

<script>
    import cmpCreate from "@/components/common/cmpCreate";
    import i18n from '@/i18n';
    import ApiService from '@/ApiService';

    export default {
        components: {
            cmpCreate
        },
        data() {
            return {
                name: "Tenant",
                subPath: "Code",
                pkColumn: "CodeId",
                parent: {},
                goBackAfterCreate: false,
                formData: {
                    SampleSiteId: '',
                    Name: '',
                    AddSiteId: ''
                }
            };
        },
        computed: {
            fields() {
                return [
                    { key: "SampleSiteId", label: i18n.t('Tenant.Code.SampleSiteId.label'), input: { type: "select", objectName: "Tenant/Code/SiteId", idField: "SiteId", displayField: ["SiteId"] }, required: true },
                    { key: "Name", label: i18n.t('Tenant.Code.Name.label'), input: { type: "select", objectName: "Tenant/Code/Name", idField: "Name", displayField: ["Name"] }, required: true },
                    { key: "AddSiteId", label: i18n.t('Tenant.Code.AddSiteId.label'), input: { type: "selectmulti", objectName: "Tenant/Code/SiteId", idField: "SiteId", displayField: ["SiteId"] }, required: true },
                ];
            },
            header() {
                return i18n.t('Tenant.Code.Header');
            }
        },
        watch: {
            'formData.SampleSiteId': async function (newSampleSiteId) {
                if (newSampleSiteId) {
                    const res = await this.getOptions("Tenant/Code/Name", { SampleSiteId: newSampleSiteId });
                    const result = res.Data.map(row => ({ value: row['Name'], text: row['Name'] }));
                    this.formData.Name = ''; // Clear the existing value before updating options
                    this.$refs.cmpCreate.formBind.Name = result;
                }
            }
        },
        methods: {
            getOptions(objectName, data) {
                const api = new ApiService(objectName);
                return api.getSelectOptions(objectName, data);
            }
        }
    };
</script>
