<template>
    <div id="content_public">

        <div class="mt-3 row justify-content-center">
            <select v-model="selectedFunction">
                <option v-for="func in functions" :value="func.value" v-bind:key="func.text">{{ func.text }}</option>
            </select>
        </div>

        <div class="mt-3 row justify-content-center">
            <input v-model.lazy="search" placeholder="search">
            <button v-on:click="runSelectedFunction()">search</button>
        </div>

        <div v-if="selectedFunction == 'getAdhdFeature'" class="mt-3 row justify-content-center">
            <select v-model="selectedMotionDataId">
                <option v-for="item in model" :value="item.MotionDataId" v-bind:key="item.MotionDataId">{{
                    item.CourseType + " : " + formatDatetime(item.LastModificationTime)
                }}</option>
            </select>
            <select v-model="selectedType" disabled readonly>
                <option v-for="typeName in adhdType" :value="typeName.value" v-bind:key="typeName.text">{{
                    typeName.text
                }}</option>
            </select>
        </div>

        <div class="mt-3 row justify-content-center">
            <p class="text-danger">{{ errorMsg }}</p>
        </div>

        <div class="row justify-content-center">
            <template v-if="chartObj.length > 0">
                <div class="mb-5 col-11 col-xl-5" v-for="(item, index) in chartObj" :key="index">
                    <cmpChart v-bind:chart-type="item.chartType" v-bind:chartData="item.chartData"
                        v-bind:chart-options="item.chartOptions">
                    </cmpChart>
                </div>
            </template>
            <div class="mb-5 col-11 col-xl-5" v-if="aiScore != -1">
                <cmpAIScore v-bind:score="aiScore" v-bind:title="'ADHD AI Score'"
                    v-bind:text="'This is the probability of having ADHD'"></cmpAIScore>
            </div>
        </div>
        <br>
    </div>
</template>


<script>
import CmpAIScore from '../components/common/cmpAIScore.vue';
import cmpChart from '../components/common/cmpChart.vue';
import ApiService from '@/ApiService';
import { gmtToLocal } from '@/util/dateutil';


export default {
    components: {
        cmpChart,
        CmpAIScore
    },
    watch: {
        selectedMotionDataId: {
            handler: function (newValue) {
                if (this.selectedFunction == 'getAdhdFeature') {
                    this.selectedType = this.adhdType.find(obj => obj.text === this.model.find(item => item.MotionDataId == this.selectedMotionDataId).CourseType).value;
                    this.createAdhdFeature();
                }
                else {
                    this.createAdhdFeatureHistory();
                }
            },
        },
        selectedFunction: {
            handler: function (newValue) {
                this.runSelectedFunction();
            },
        },
        selectedType: {
            handler: function (newValue) {
                if (this.selectedFunction == 'getAdhdFeature') {
                    this.createAdhdFeature();
                }
                else {
                    this.createAdhdFeatureHistory();
                }
            },
        }
    },
    data() {
        return {
            selectedMotionDataId: -1,
            selectedFunction: 'getAdhdFeature',
            selectedType: 'C_',
            functions: [
                { text: 'ADHD', value: 'getAdhdFeature' },
                { text: 'ADHD History', value: 'getAdhdFeatureHistory' }
            ],
            adhdType: [
                { text: 'CPT', value: 'C_' },
                { text: 'AUDIO', value: 'A_' },
                { text: 'STROOP', value: 'S_' },
                { text: 'WCST', value: 'W_' },
            ],
            errorMsg: "",
            aiScore: -1,//-1是代表初始值，正常情況不會小於0，現階段相同的activityid aiScore都一樣
            search: "",
            model: {},
            features: {},
            valuePRs: {},
            goldenSamples: {},//紀錄平均值
            chartObj: [],
            dateList: [],
            chartColor: ['rgba(244, 67, 54,0.7)', 'rgba(255, 160, 0,0.7)', 'rgba(205, 220, 57,0.7)', 'rgba(0, 150, 136,0.7)', 'rgba(3, 169, 244,0.7)', 'rgba(63, 81, 181,0.7)', 'rgba(103, 58, 183,0.7)', 'rgba(96, 125, 139,0.7)'],
            groupLabels: ['Eye Movement - FocusRate', 'Eye Movement - EyeOpenness_mean', 'Eye Movement - EyePathLength', 'Task Performance - Accuracy', 'Task Performance - ReactiveTime', 'Task Performance - OmissionError', 'Head Rotation - HeadAmplitude', 'Electroencephalography(EEG) - Pz', 'Electroencephalography(EEG) - FCz', 'Electroencephalography(EEG) - FC3', 'Electroencephalography(EEG) - Oz'],
            featureLabels: [['Inf_FocusRate', 'NoInf_FocusRate', 'All_FocusRate'],
            ['Inf_LeftEyeOpenness_mean', 'Inf_RightEyeOpenness_mean', 'NoInf_LeftEyeOpenness_mean', 'NoInf_RightEyeOpenness_mean', 'All_LeftEyeOpenness_mean', 'All_RightEyeOpenness_mean'],
            ['Inf_LeftEyePathLength', 'Inf_RightEyePathLength', 'NoInf_LeftEyePathLength', 'NoInf_RightEyePathLength', 'All_LeftEyePathLength', 'All_RightEyePathLength'],
            ['Inf_Accuracy', 'NoInf_Accuracy', 'All_Accuracy'],
            ['Inf_ReactiveTime', 'NoInf_ReactiveTime', 'All_ReactiveTime'],
            ['Inf_OmissionError', 'Inf_CommissionError', 'NoInf_OmissionError', 'NoInf_CommissionError', 'All_OmissionError', 'All_CommissionError'],
            ['Inf_HeadAmplitude', 'NoInf_HeadAmplitude', 'All_HeadAmplitude'],
            ['Pz_delta', 'Pz_theta', 'Pz_alpha', 'Pz_beta', 'Pz_gamma'],
            ['FCz_delta', 'FCz_theta', 'FCz_alpha', 'FCz_beta', 'FCz_gamma'],
            ['FC3_delta', 'FC3_theta', 'FC3_alpha', 'FC3_beta', 'FC3_gamma'],
            ['Oz_delta', 'Oz_theta', 'Oz_alpha', 'Oz_beta', 'Oz_gamma']]
        };
    },
    methods:
    {
        initData() {
            this.aiScore = -1;
            this.errorMsg = "";
            this.chartObj = [];
            this.features = {};
            this.valuePRs = {};
        },
        runSelectedFunction() {
            this[this.selectedFunction]();
        },
        formatDatetime(d) {
            const localDate = gmtToLocal(d);
            if (localDate) {
                return localDate.replace('T', ' ');
            }

            return null;
        },
        async getAdhdFeature() {
            try {
                //呼叫api讀取資料
                //呼叫api讀取資料
                let api = new ApiService("Motiondata");
                const requestData =
                {
                    MemberId: this.search,
                    CourseName: "ADHD"
                }
                this.model = await api.getAll(requestData, 'feature_value/member_id');
                this.model = this.model.filter(item => {
                    return item.FeatureValue != null;
                });
                this.model.forEach((item) => {
                    item.FeatureValue = JSON.parse(item.FeatureValue);
                });
                this.model.forEach((item) => {
                    item.ModelOutput = JSON.parse(item.ModelOutput);
                });
                this.model = this.model.filter(item => {
                    return this.adhdType.some(adhdItem => adhdItem.text === item.CourseType);
                });
                console.log(this.model)
                this.selectedMotionDataId = this.model[0].MotionDataId;
                this.createAdhdFeature();

            } catch (error) {
                console.log(error);
                this.initData();
                this.errorMsg = "Can't find this data";
            }
        },
        async createAdhdFeature() {
            try {
                this.initData();
                let motiondata = this.model.find(item => item.MotionDataId == this.selectedMotionDataId);
                if (motiondata.ModelOutput && "ADHDAC_Score" in motiondata.ModelOutput.ModelOutput) {
                    this.aiScore = parseInt(motiondata.ModelOutput.ModelOutput["ADHDAC_Score"]);
                } else {
                    this.aiScore = -1;
                }
                let api = new ApiService("GoldenSampleFeature");
                this.goldenSamples = await api.getAll({ CouredId: motiondata.CouredId });
                //處理成可以用featureName當作key值呼叫的格式
                this.goldenSamples = this.goldenSamples.Data.reduce((acc, { FeatureFieldName, FeatureValue, FeatureFieldValueAbnormal, FeatureFieldValueNormal, FeatureFieldSorting }) => {
                    acc[FeatureFieldName] = { FeatureValue, FeatureFieldValueAbnormal, FeatureFieldValueNormal, FeatureFieldSorting };
                    return acc;
                }, {});
                this.valuePRs = motiondata.FeatureValue.FeatureName.reduce((obj, key, index) => {
                    obj[key] = motiondata.FeatureValue.FeaturePR[index];
                    return obj;
                }, {});
                this.features = motiondata.FeatureValue.FeatureName.reduce((obj, key, index) => {
                    obj[key] = motiondata.FeatureValue.FeatureValue[index];
                    return obj;
                }, {});

                //生成adhd ChartObj的資料，一組最多6個(版面及配色原因)
                //const chartType = 'Radar';//雷讀圖選項
                const chartType = 'Bar';
                const backgroundColor = this.chartColor[0];
                const label = 'Case';
                const averageLabel = 'Average';
                const averageBackgroundColor = this.chartColor[1];

                //生成chartData
                const chartData = await this.featureLabels.map((labelsArray) => {
                    return {
                        labels: labelsArray.map(label => {
                            return [this?.valuePRs[this.selectedType + label] ? "PR : " + this.valuePRs[this.selectedType + label] : "PR : Not calculated", label];
                        }),
                        datasets: [
                            {
                                data: labelsArray.map(label => {
                                    //如果腦電特徵沒資料時根據正常人平均生出假資料
                                    if (['Pz_delta', 'Pz_theta', 'Pz_alpha', 'Pz_beta', 'Pz_gamma', 'FCz_delta', 'FCz_theta', 'FCz_alpha', 'FCz_beta', 'FCz_gamma',
                                        'FC3_delta', 'FC3_theta', 'FC3_alpha', 'FC3_beta', 'FC3_gamma', 'Oz_delta', 'Oz_theta', 'Oz_alpha', 'Oz_beta', 'Oz_gamma'].includes(label)) {
                                        return this?.features[this.selectedType + label] ? this.features[this.selectedType + label] : this.goldenSamples[this.selectedType + label]?.FeatureFieldValueNormal * 1.05;
                                    }
                                    else {
                                        return this?.features[this.selectedType + label] ? this.features[this.selectedType + label] : 0;
                                    }
                                })
                            },
                            {
                                data: labelsArray.map(label => {
                                    return this.goldenSamples[this.selectedType + label]?.FeatureFieldValueNormal ? this.goldenSamples[this.selectedType + label].FeatureFieldValueNormal : 0;
                                })
                            }
                        ],

                    };
                });

                //生成ChartObj的完整格式
                this.chartObj = chartData.map((data, i) => ({
                    chartType,
                    chartData: {
                        labels: data.labels,
                        datasets: [
                            {
                                label,
                                backgroundColor,//更改背景顏色
                                // backgroundColor: 'rgba(244, 67, 54,0.3)', //雷達圖選項
                                // borderColor:backgroundColor,
                                data: data.datasets[0].data
                            },
                            {
                                label: averageLabel,
                                backgroundColor: averageBackgroundColor,
                                // backgroundColor: 'rgba(255, 160, 0,0.3)',//雷達圖選項
                                // borderColor:averageBackgroundColor,
                                data: data.datasets[1].data
                            }
                        ]
                    },
                    chartOptions: {
                        plugins: {
                            //此title是每組圖表的
                            title: {
                                display: true,
                                text: this.groupLabels[i]
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        //indexAxis: 'y', //是否水平顯示
                        scales: {//如要顯示雷達圖需註解scales{}
                            x: {
                                ticks: {
                                    // 把過長的字元簡化
                                    callback: function (value, index, ticks) {
                                        var characterLimit = 10;
                                        var lable = Object.assign([], this.getLabelForValue(value));
                                        if (lable[1].length >= characterLimit && ticks.length > 3) {
                                            lable[1] = lable[1].slice(0, lable[1].length).substring(0, characterLimit - 1).trim() + '...';
                                            return lable;
                                        }
                                        return lable;
                                    }
                                }
                            }
                        }
                    }
                }));

            } catch (error) {
                console.log(error);
                this.initData();
                this.errorMsg = "Can't find this data";
            }
        },
        async getAdhdFeatureHistory() {
            try {
                //呼叫api讀取資料
                let api = new ApiService("Motiondata");
                const requestData =
                {
                    MemberId: this.search,
                    CourseName: "ADHD"
                }
                this.model = await api.getAll(requestData, 'feature_value/member_id');
                this.model = this.model.filter(item => {
                    return item.FeatureValue != null;
                });
                this.model.forEach((item) => {
                    item.FeatureValue = JSON.parse(item.FeatureValue);
                });

                this.createAdhdFeatureHistory();

            } catch (error) {
                console.log(error);
                this.initData();
                this.errorMsg = "Can't find this data";

            }

        },
        createAdhdFeatureHistory() {
            try {
                this.initData();
                //處理資料格式
                this.dateList = [];
                this.model.forEach((item) => {
                    if (item.CourseType == this.adhdType.find(option => option.value == this.selectedType).text) {
                        this.dateList.push(item.StartingTime ? item.StartingTime : '2023/01/01');//data是null時2023/01/01為暫時預設
                        item.FeatureValue.FeatureName.forEach((feature, i) => {
                            if (!this.features[feature]) {
                                this.features[feature] = [item.FeatureValue.FeatureValue[i]];
                                //this.valuePRs[feature] = [item.featureValue.FeaturePR[i]];//歷次report部分暫時不需使用PR
                            } else {
                                this.features[feature].push(item.FeatureValue.FeatureValue[i]);
                                //this.valuePRs[feature].push(item.featureValue.FeaturePR[i]);//歷次report部分暫時不需使用PR
                            }
                        });
                    }

                });
                //生成歷次adhd ChartObj的資料
                const chartType = 'Line';

                //生成chartData
                const chartData = this.featureLabels.map(labels => {
                    return {
                        datasets: labels.map(label => {
                            return {
                                label,
                                data: this?.features[this.selectedType + label] ? this.features[this.selectedType + label] : 0
                            }
                        })
                    };
                });

                //設定chartData feature的顏色
                chartData.map(data => {
                    data.datasets.forEach((item, i) => {
                        item['borderColor'] = this.chartColor[i];
                        item['backgroundColor'] = this.chartColor[i];
                    })
                })
                //生成ChartObj的完整格式
                this.chartObj = chartData.map((data, i) => ({
                    chartType,
                    chartData: {
                        labels: this.dateList,
                        datasets: data.datasets
                    },
                    chartOptions: {
                        plugins: {
                            //此title是每組圖表的
                            title: {
                                display: true,
                                text: this.groupLabels[i]
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        //indexAxis: 'y', //是否水平顯示
                        scales: {
                            x: {
                                ticks: {
                                    //把時間格式縮減
                                    callback: function (value, index, ticks) {
                                        var characterLimit = 11;
                                        var lable = this.getLabelForValue(value);

                                        if (lable.length >= characterLimit && ticks.length > 0) {
                                            lable = lable.slice(0, lable.length).substring(0, characterLimit - 1).trim() + '...';
                                            return lable;
                                        }
                                        return lable;
                                    }
                                }
                            }
                        }
                    }
                }));

            } catch (error) {
                console.log(error);
                this.initData();
                this.errorMsg = "Can't find this data";

            }

        }


    }

}
</script>

