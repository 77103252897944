<template>
    <cmpTableWithMenu v-bind:name="name"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:header="header"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    { "key": "DeviceInventoryId", "label": i18n.t('component.DeviceInventory.DeviceInventoryId.label'), sortable: true },
                    { "key": "InventoryName", "label": i18n.t('component.DeviceInventory.InventoryName.label'), sortable: true },
                    { "key": "InventoryNumber", "label": i18n.t('component.DeviceInventory.InventoryNumber.label'), sortable: true },
                    { "key": "Depositary", "label": i18n.t('component.DeviceInventory.Depositary.label'), sortable: true },
                    { "key": "InventoryLocation", "label": i18n.t('component.DeviceInventory.InventoryLocation.label'), sortable: true },
                    { "key": "UnitName", "label": i18n.t('component.DeviceInventory.UnitName.label'), sortable: true },                   
                ]
            },
            qryFields() {
                return [
                    { "key": "InventoryName", "label": i18n.t('component.DeviceInventory.InventoryName.label'), input: { type: "text" } },
                    { "key": "Depositary", "label": i18n.t('component.DeviceInventory.Depositary.label'), input: { type: "text" } },
                    { "key": "InventoryLocation", "label": i18n.t('component.DeviceInventory.InventoryLocation.label'), input: { type: "text" } },
                ]
            }
            ,
            
            header() {
                return i18n.t('component.DeviceInventory.lstHeader')
            }
            
        },
        data() {

            return {
                name: "DeviceInventory",
                pkColumn: "DeviceInventoryId",
                children: {},
                parent: {},
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete']
            };
        }
    }
</script>