<template>
    <cmpRec v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    { "key": "MenuId", "label": "No." },
                    { "key": "ProgId", "label": i18n.t('component.Menu.ProgId.label') },
                    { "key": "Param", "label": i18n.t('component.Menu.Param.label') },
                    { "key": "MenuName", "label": i18n.t('component.Menu.MenuName.label') },
                    { "key": "MenuDisplay", "label": i18n.t('component.Menu.MenuDisplay.label') },
                    { "key": "MenuIndex", "label": i18n.t('component.Menu.MenuIndex.label') },
                    { "key": "MenuLevel", "label": i18n.t('component.Menu.MenuLevel.label') },
                    { "key": "MenuSort", "label": i18n.t('component.Menu.MenuSort.label') },
                    { "key": "MenuType", "label": i18n.t('component.Menu.MenuType.label') },
                    { "key": "MenuDisabled", "label": i18n.t('component.Menu.MenuDisabled.label') },
                ]
            },
            header() {
                return i18n.t('component.Menu.recHeader')
            }
        },
        data() {

            return {
                name: "Menu",
                pkColumn: "MenuId",
                parent: {}
            };
        }
    }
</script>