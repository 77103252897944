<template>
    <cmpCreate :name="name"
               :subPath="subPath"
               :pkColumn="pkColumn"
               :fields="fields"
               :parent="parent"
               :header="header"
               :goBackAfterCreate="goBackAfterCreate">
    </cmpCreate>
</template>

<script>
    import cmpCreate from "@/components/common/cmpCreate";
    import i18n from '@/i18n';

    export default {
        components: {
            cmpCreate
        },
        data() {
            return {
                name: "Tenant",
                subPath: "Site",
                pkColumn: "Id",
                parent: {},
                goBackAfterCreate: false
            };
        },
        computed: {
            fields() {
                return [
                    { key: "SampleSiteId", label: i18n.t('Tenant.Site.SampleSiteId.label'), input: { type: "label", default: "Admin" } },
                    { key: "SiteId", label: i18n.t('Tenant.Site.SiteId.label'), input: { type: "text" }, required: true },
                    { key: "UnitCode", label: i18n.t('Tenant.Site.UnitCode.label'), input: { type: "text" }, required: true },
                    { key: "UnitName", label: i18n.t('Tenant.Site.UnitName.label'), input: { type: "text" }, required: true },
                    { key: "Account", label: i18n.t('Tenant.Site.Account.label'), input: { type: "text" }, required: true },
                    { key: "Password", label: i18n.t('Tenant.Site.Password.label'), input: { type: "text" }, required: true },
                    { key: "ProjectCodePrefix", label: i18n.t('Tenant.Site.ProjectCodePrefix.label'), input: { type: "text" }, required: true },
                ];
            },
            header() {
                return i18n.t('Tenant.Site.Header');
            }
        }
    };
</script>
