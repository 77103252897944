<template>
    <div id="content_public"> <!--選擇病症 ex:asd,adhd.concussion-->
        <div class="mt-3 row justify-content-center">
            <div class="select-container">
                <select v-model="selectedCourse">
                    <option disabled value="">請選擇病症</option>
                    <option v-for="c in course" :value="c.name" :key="c.name">{{ c.name }}</option>
                </select>
            </div>
            <div class="select-container">
                <select v-model="selectedGame">
                    <option  disabled value="">請選擇遊戲種類</option>
                    <option v-for="game in gameList" :value="game" :key="game">{{ game }}</option>
                </select>
            </div>
            <div class="select-container">
                <select v-model="selectedReportType">
                    <option  disabled value="">請選擇報告種類</option>
                    <option v-for="report in reportTypeList" :value="report" :key="report">{{ report }}</option>
                </select>
            </div>
            <div class="select-container">
                <input v-model.lazy="searchMemberId" placeholder="search">
                <button v-on:click="search">search</button>
            </div>
        </div>

        <!--目前有三種report的種類getFeature是一般報告,getFeatureForPicoReport是簡易報告,另外一種getFeatureHistory是歷史特徵直不需要選則時間所以沒有在v-else-->
        <div v-if="selectedReportType == 'simple' && reportFormat != '' && searchMemberId != ''"
            class="mt-3 row justify-content-center">
            <select v-model="selectedMotionDataId" @change="createFeature()">
                <option v-for="item in userData" :value="item.MotionDataId" v-bind:key="item.MotionDataId">{{
                    item.CourseType + " : " + formatDatetime(item.StartingTime)
                }}</option>
            </select>
        </div>

        <!--
        <div v-else-if="selectedReportType == 'getFeatureForPicoReport'" class="mt-3 row justify-content-center">
            <select v-model="selectedActivityId">
                <option v-for="item in model" :value="item[0].ActivityId" v-bind:key="item[0].ActivityId">
                    {{
                        "ActivityID:" + item[0].ActivityId
                    }}
                </option>
            </select>
        </div>
        -->
        <div class="mt-3 row justify-content-center">
            <p class="text-danger">{{ errorMsg }}</p>
        </div>
        <div v-if="searchMemberId != ''">
            <cmpChart v-bind:chart-type="'Table'" v-bind:rowData="this.userInfo">
            </cmpChart>
        </div>


        <!--這段是報告的結果顯示-->
        <div class="row justify-content-center">
            <template v-if="chartObj.length > 0">
                <div class="mb-5 col-11 col-xl-4" v-for="(item, index) in chartObj" :key="index">
                    <cmpChart v-bind:chart-type="item.chartType" v-bind:chartData="item.chartData"
                        v-bind:chart-options="item.chartOptions" v-bind:featureName="item.featureName"
                        v-bind:featureValue="item.featureValue" v-bind:feature-avg-value="item.featureAvgValue"
                        v-bind:prValue="item.featurePr">
                    </cmpChart>
                </div>
            </template>
            <div class="mb-5 col-11 col-xl-5" v-if="possibility != -1">
                <cmpAIScore v-bind:score="possibility" v-bind:title="'Probability'"
                    v-bind:text="`This is the probability of having ${selectedCourse}`"></cmpAIScore>
            </div>
        </div>
        <!--這段是圖片的顯示如果有需要-->
        <div class="row justify-content-center">
            <template v-if="pictureObj.length > 0">
                <div class="mb-5 col-11 col-xl-5" v-for="(item, index) in pictureObj" :key="index">
                    <cmpChart v-bind:chart-type="'ImageUrl'" v-bind:image-src="item.src"
                        v-bind:feature-name="Object.keys(pictureEx)[index]">
                    </cmpChart>
                </div>
            </template>
        </div>
        <div v-if="chartObj.length > 0">
            <reportCustomizeContent v-bind:motionDataId="selectedMotionDataId" v-bind:reportType="selectedCourse"
                v-bind:chartObj="chartObj" v-bind:features="features"></reportCustomizeContent>

        </div>

        <div v-if="modelTablesCol.length > 0">
            <cmpChart v-bind:chart-type="'Table'" v-bind:rowData="modelTablesRow"
                v-bind:columnDefs="modelTablesCol">
            </cmpChart>
        </div>
        <br>

    </div>
</template>
<style>
.select-container {
    display: inline-block;
    margin-right: 20px;
}
</style>

<script>
import CmpAIScore from '../components/common/cmpAIScore.vue';
import cmpChart from '../components/common/cmpChart.vue';
import reportCustomizeContent from '../components/report/reportCustomizeContent.vue';
import ApiService from '@/ApiService';
import { gmtToLocal } from '@/util/dateutil';

export default {
    components: {
        cmpChart,
        CmpAIScore,
        reportCustomizeContent
    },
    data() {
        return {
            selectedActivityId: -1,
            selectedMotionDataId: -1,
            course: [ ],
            gameList:[],
            reportTypeList:[],
            selectedReportType: "",     // 這是default 選擇最常見的報表模式
            selectedCourse:"",
            selectedGame: "",
            errorMsg: "",
            possibility: -1,//-1是代表初始值，正常情況不會小於0，現階段相同的activityid possibility都一樣
            searchMemberId: "",
            userData: {},
            reportFormat: [],
            features: {},
            featurePrefix:"",
            valuePRs: {},
            goldenSamples: {},//紀錄平均值
            chartObj: [],
            dateList: [],
            chartType: [],
            groupName: [],
            chartColor: ['rgba(244, 67, 54,0.7)', 'rgba(255, 160, 0,0.7)', 'rgba(205, 220, 57,0.7)', 'rgba(0, 150, 136,0.7)', 'rgba(3, 169, 244,0.7)', 'rgba(63, 81, 181,0.7)', 'rgba(103, 58, 183,0.7)', 'rgba(96, 125, 139,0.7)'],
            groupLabels: [],
            featureLabels: [],
            pictureLabels: [],
            pictureObj: [],
            pictureEx: [],
            pictureBlob: [],
            pictureGroupLabels: [],
            userInfo: [],
            modelTablesCol: [],
            modelTablesRow: [],
            courseType: '',
            motionData: {}
        };
    },
    mounted() {
        this.getCourseList();
    },
 
    watch: {
        selectedCourse(newVal) {
            const selectedCourse = this.course.find(course => course.name === newVal);
            this.gameList = selectedCourse ? selectedCourse.gameList : [];
            this.selectedGame = this.gameList[0];
            this.reportTypeList = selectedCourse ? selectedCourse.reportType : [];
            this.selectedReportType = this.reportTypeList[0];
        }
    },
    methods:
    {
        async getCourseList()
        {
            const api = new ApiService("Course");
            this.course = (await api.getAll('', `?CourseName=onlineReport`)).Data[0].CourseReportFormat.courseList;
        },
        async search(){
            if (this.searchMemberId == "")
                return
            this.getMemberInfo(this.searchMemberId);
            await this.getCourseFormat();
            if(this.selectedReportType == "simple")
                this.getSimpleData();
            else if(this.selectedReportType == "history")
                this.getHistoryData();
          
        },
        initData() {
            this.possibility = -1;
            this.errorMsg = "";
            this.chartObj = [];
            this.features = {};
            this.valuePRs = {};
            this.pictureObj = [];
            this.modelTablesRow = [];
            this.modelTablesCol = [];
        },
        initLabel() {
            this.pictureGroupLabels = [];
            this.groupLabels = [];
            this.pictureLabels = [];
            this.pictureLabelsFixed = [];
            this.userInfo = [];
        },
       
       
        formatDatetime(d) {
            const localDate = gmtToLocal(d);
            if (localDate) {
                return localDate.replace('T', ' ');
            }

            return null;
        },
        
        async getCourseFormat() {
            try {
                let api = new ApiService("Course");
                this.reportFormat = (await api.getAll('', `?CourseName=${this.selectedCourse}-${this.selectedGame}`)).Data.map(course => course.CourseReportFormat);
                this.featurePrefix = this.reportFormat[0].Parameter.featurePrefix;
                this.groupLabels = this.reportFormat[0].Parameter.groupLabels;
                this.pictureGroupLabels = this.reportFormat[0].Parameter.pictureGroupLabels;
                this.pictureLabels = this.pictureGroupLabels.find(item => item.groupName === "database");
                this.pictureLabels = this.pictureLabels ? this.pictureLabels.pictureLabels : [];
            }
            catch (error) {
                console.log(error);
                this.initData();
                this.errorMsg = "Can't find this data";
                throw error;
            }
        },
        async getSimpleData() {
            try {
                let api = new ApiService("Motiondata");
                const requestData =
                {
                    MemberId: this.searchMemberId,
                    CourseName: this.selectedCourse,
                    CourseType: this.selectedGame
                }
                this.getLabels("simple");
                this.userData = await api.getAll(requestData, 'feature_value/member_id');
                this.selectedMotionDataId = this.userData[0].MotionDataId;
                await this.createFeature();

            } catch (error) {
                console.log(error);
                this.initData();
                this.errorMsg = "Can't find this data";
                throw error;
            }
        },
        async createFeature() {
            try {
                this.initData();
                await this.motionDataProcess();
                await this.graphProcess();

            } catch (error) {
                console.log(error);
                this.initData();
                this.errorMsg = "Can't find this data";
                throw error;
            }
        },
        async getHistoryData() {
            try {
                //呼叫api讀取資料
                let api = new ApiService("Motiondata");
                const requestData =
                {
                    MemberId: this.searchMemberId,
                    CourseName: this.selectedCourse,
                    CourseType: this.selectedGame

                }
                this.getLabels("history");
                this.userData = await api.getAll(requestData, 'feature_value/member_id');
                this.createFeatureHistory();

            } catch (error) {
                console.log(error);
                this.initData();
                this.errorMsg = "Can't find this data";
                throw error;

            }

        },
        createFeatureHistory() {
            try {
                this.initData();
                //處理資料格式
                this.dateList = [];
                this.userData.forEach((item) => {

                    this.dateList.push(item.StartingTime ? item.StartingTime : '2023/01/01');//data是null時2023/01/01為暫時預設
                    item.FeatureValue.FeatureName.forEach((feature, i) => {
                        if (!this.features[feature]) {
                            this.features[feature] = [item.FeatureValue.FeatureValue[i]];
                            //this.valuePRs[feature] = [item.featureValue.FeaturePR[i]];//歷次report部分暫時不需使用PR
                        } else {
                            this.features[feature].push(item.FeatureValue.FeatureValue[i]);
                            //this.valuePRs[feature].push(item.featureValue.FeaturePR[i]);//歷次report部分暫時不需使用PR
                        }
                    });


                });
                //生成歷次asd ChartObj的資料
                // const chartType = 'Line';

                //生成chartData
                const chartData = this.featureLabels.map(labels => {
                    return {
                        datasets: labels.map(label => {
                            return {
                                label,
                                data: this?.features[this.featurePrefix + label] ? this.features[this.featurePrefix + label] : 0
                            }
                        })
                    };
                });
                //設定chartData feature的顏色
                chartData.map(data => {
                    data.datasets.forEach((item, i) => {
                        item['borderColor'] = this.chartColor[i];
                        item['backgroundColor'] = this.chartColor[i];
                    })
                })
                //生成ChartObj的完整格式
                this.chartObj = chartData.map((data, i) => ({
                    chartType: this.chartType[i],
                    chartData: {
                        labels: this.dateList,
                        datasets: data.datasets
                    },
                    chartOptions: {
                        plugins: {
                            //此title是每組圖表的
                            title: {
                                display: true,
                                text: this.groupName[i]
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        //indexAxis: 'y', //是否水平顯示
                        scales: {
                            x: {
                                ticks: {
                                    //把時間格式縮減
                                    callback: function (value, index, ticks) {
                                        var characterLimit = 11;
                                        var label = this.getLabelForValue(value);

                                        if (label.length >= characterLimit && ticks.length > 0) {
                                            label = label.slice(0, label.length).substring(0, characterLimit - 1).trim() + '...';
                                            return label;
                                        }
                                        return label;
                                    }
                                }
                            }
                        }
                    }
                }));

            } catch (error) {
                console.log(error);
                this.initData();
                this.errorMsg = "Can't find this data";
                throw error;
            }

        },
        //重這裡開始是簡易報表
        // async getFeatureForPicoReport() {
        //     try {
        //         //呼叫api讀取資料
        //         //呼叫api讀取資料
        //         let api = new ApiService("Motiondata");
        //         const requestData =
        //         {
        //             MemberId: this.searchMemberId,
        //             CourseName: this.selectedCourse
        //         }
        //         this.getLabels("getFeatureForPicoReport");
        //         this.userData = await api.getAll(requestData, 'feature_value/member_id');
        //         const groupedModel = await this.groupBy(this.userData, "ActivityId");
        //         this.userData = groupedModel;
        //         this.selectedActivityId = this.userData[0][0].ActivityId;
        //         this.userData.forEach((innerArray) => {
        //             innerArray.forEach((item) => {
        //                 item.FeatureValue = JSON.parse(item.FeatureValue);
        //             })
        //         });
        //         this.createFeatureForPicoReport();

        //     } catch (error) {
        //         console.log(error);
        //         this.initData();
        //         this.errorMsg = "Can't find this data";
        //         throw error;
        //     }
        // },
        // async createFeatureForPicoReport() {
        //     try {
        //         this.initData();
        //         this.motionData = this.userData.find(item => item[0].ActivityId == this.selectedActivityId);
        //         //處理成可以用featureName當作key值呼叫的格式
        //         this.goldenSamples = await this.getGoldenSamples(this.motionData.CourseId);
        //         this.valuePRs = this.transformFeatures(this.motionData, "FeaturePR");
        //         this.features = this.transformFeatures(this.motionData, "FeatureValue");
        //         //生成adhd ChartObj的資料，一組最多6個(版面及配色原因)
        //         //const chartType = 'Radar';//雷讀圖選項
        //         const backgroundColor = this.chartColor[0];
        //         const label = 'Case';
        //         const averageLabel = 'Average';
        //         const averageBackgroundColor = this.chartColor[1];

        //         //生成chartData
        //         const chartData = await this.featureLabels.map((labelsArray) => {
        //             return {
        //                 labels: labelsArray.map(label => {
        //                     return [this?.valuePRs[this.featurePrefix + label] ? "" + this.valuePRs[this.featurePrefix + label] : "", label];
        //                 }),
        //                 datasets: [
        //                     {
        //                         data: labelsArray.map(label => {
        //                             //如果腦電特徵沒資料時根據正常人平均生出假資料
        //                             if (['Pz_delta', 'Pz_theta', 'Pz_alpha', 'Pz_beta', 'Pz_gamma', 'FCz_delta', 'FCz_theta', 'FCz_alpha', 'FCz_beta', 'FCz_gamma',
        //                                 'FC3_delta', 'FC3_theta', 'FC3_alpha', 'FC3_beta', 'FC3_gamma', 'Oz_delta', 'Oz_theta', 'Oz_alpha', 'Oz_beta', 'Oz_gamma'].includes(label)) {
        //                                 return this?.features[label] ? this.features[label] : this.goldenSamples[label]?.FeatureFieldValueNormal * 1.05;
        //                             }
        //                             else {
        //                                 return this?.features.map(row => { return row[label] ? row[label] : 0; });
        //                             }
        //                         })
        //                     },
        //                     {
        //                         data: labelsArray.map(label => {
        //                             return this.goldenSamples[label]?.FeatureFieldValueNormal ? this.goldenSamples[label].FeatureFieldValueNormal : 0;
        //                         })
        //                     }
        //                 ],

        //             };
        //         });
        //         //生成ChartObj的完整格式
        //         this.chartObj = chartData.map((data, i) => ({
        //             chartType: this.chartType[i],
        //             chartData: {
        //                 labels: data.labels,
        //                 datasets: [
        //                     {
        //                         label,
        //                         //backgroundColor,//更改背景顏色
        //                         backgroundColor: 'rgba(244, 67, 54,0.3)', //雷達圖選項
        //                         borderColor: backgroundColor,
        //                         data: data.datasets[0].data
        //                     },
        //                     {
        //                         label: averageLabel,
        //                         // backgroundColor: averageBackgroundColor,
        //                         backgroundColor: 'rgba(255, 160, 0,0.3)',//雷達圖選項
        //                         borderColor: averageBackgroundColor,
        //                         data: data.datasets[1].data
        //                     }
        //                 ]
        //             },
        //             chartOptions: {
        //                 plugins: {
        //                     //此title是每組圖表的
        //                     title: {
        //                         display: true,
        //                         text: this.groupName[i]
        //                     }
        //                 },
        //                 responsive: true,
        //                 maintainAspectRatio: false,
        //                 //indexAxis: 'y', //是否水平顯示
        //                 scales: {//如要顯示雷達圖需註解scales{}
        //                     x: {
        //                         ticks: {
        //                             // 把過長的字元簡化
        //                             callback: function (value, index, ticks) {
        //                                 var characterLimit = 10;
        //                                 var label = Object.assign([], this.getLabelForValue(value));
        //                                 if (label[1].length >= characterLimit && ticks.length > 3) {
        //                                     label[1] = label[1].slice(0, label[1].length).substring(0, characterLimit - 1).trim() + '...';
        //                                     return label;
        //                                 }
        //                                 return label;
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }));

        //     } catch (error) {
        //         console.log(error);
        //         this.initData();
        //         this.errorMsg = "Can't find this data";
        //         throw error;
        //     }
        // },
        // //結束


        async groupBy(array, key) {
            return Object.values(array.reduce((acc, obj) => {
                let keyValue = obj[key];
                if (!acc[keyValue]) {
                    acc[keyValue] = [];
                }
                acc[keyValue].push(obj);
                return acc;
            }, {}));
        },
        async getLabels(reportType) {
            this.featureLabels = this.groupLabels
                .filter(obj => obj.reportType === reportType)
                .map(obj => obj.featureLabels)
            this.groupName = this.groupLabels
                .filter(obj => obj.reportType === reportType)
                .map(obj => obj.groupName)
                .flat()
            this.chartType = this.groupLabels
                .filter(obj => obj.reportType === reportType)
                .map(obj => obj.chartObj.map(chart => chart.chartType))
                .flat();
        },
        async getGoldenSamples(courseId) {
            let api = new ApiService("GoldenSampleFeature");
            const requestData =
            {
                CourseName: this.selectedCourse
            }
            let goldenSamples = await api.getAll({ CourseId: courseId });
            goldenSamples = goldenSamples.Data.reduce((acc, { FeatureFieldName, FeatureValue, FeatureFieldValueAbnormal, FeatureFieldValueNormal, FeatureFieldSorting }) => {
                acc[FeatureFieldName] = { FeatureValue, FeatureFieldValueAbnormal, FeatureFieldValueNormal, FeatureFieldSorting };
                return acc;
            }, {});
            return goldenSamples;
        },
        transformFeatures(motionData, label) {
            if (Array.isArray(motionData) && motionData.length > 1) {
                return motionData.map((data) => {
                    return data.FeatureValue.FeatureName.reduce((obj, key, index) => {
                        obj[key] = data.FeatureValue[label][index];
                        return obj;
                    }, {});
                });
            } else {
                return motionData.FeatureValue.FeatureName.reduce((obj, key, index) => {
                    obj[key] = motionData.FeatureValue[label][index];
                    return obj;
                }, {});
            }
        },
        async getMemberInfo(memberID) {
            let api = new ApiService("User");
            let user = await api.getAll({ MemberId: memberID });
            this.userInfo = user.Data.map(item => {
                return {
                    name: item.Name,
                    birthday: item.Birthday,
                    gender: item.Gender
                };
            });
        },
        async modelTableProcess(modelOutput) {
            this.modelTablesCol =
                [
                    { headerName: 'ModelName', field: 'modelName', width: 350 },
                    { headerName: 'Result', field: 'result', cellStyle: this.getCellStyle, width: 260 },
                ];
            this.modelTablesRow = Object.keys(modelOutput).map(key => ({
                modelName: key,
                result: modelOutput[key],
            }));
        },
        modelPossibilityProcess() {
            if ("possibility" in this.motionData.ModelOutput) {
                this.possibility = parseInt(this.motionData.ModelOutput["Possibility"]);
                delete this.motionData.ModelOutput["Possibility"];
            }
        },
        getCellStyle(params) {
            if (params.value === "normal") {
                return { color: 'green' };
            } else {
                return { color: 'red' };
            }
        },
        async motionDataProcess() {
            console.log(this.userData)
            this.motionData = this.userData.find(item => item.MotionDataId == this.selectedMotionDataId);
            this.goldenSamples = await this.getGoldenSamples(this.motionData.CourseId);
            await this.modifyFeatureFormat();
            await this.ModelOutputProcess();
        },
        async graphProcess() {
            this.chartObj = await this.transformDataToChartObj(
                this.featureLabels,
                this.features,
                this.goldenSamples,
                this.valuePRs,
                this.featurePrefix
            );
            await this.transformChartObjToPicture();
        },
        async ModelOutputProcess() {
            if (this.motionData.ModelOutput != null) {
                this.modelPossibilityProcess();
                await this.modelTableProcess(this.motionData.ModelOutput);
            }

        },
        async modifyFeatureFormat() {
            this.valuePRs = this.transformFeatures(this.motionData, "FeaturePR");
            this.features = this.transformFeatures(this.motionData, "FeatureValue");
        },
        async transformChartObjToPicture() {
            if (this.pictureLabels !== "") {
                // Map blob names to values
                this.pictureBlob = Object.fromEntries(
                    this.motionData.FeatureValue.FeatureBlobName.map((key, index) => [
                        key,
                        this.motionData.FeatureValue.FeatureBlob[index]
                    ])
                );

                // Map picture labels to their corresponding URL
                this.pictureEx = Object.fromEntries(
                    this.pictureLabels.map(labels => [
                        labels[0],
                        this.motionData.FeatureValue.FeatureEx[
                        this.motionData.FeatureValue.FeatureExName.indexOf(labels[0])
                        ]
                    ])
                );

                // Create picture objects
                try {
                    this.pictureObj = this.pictureLabels.map(labelsArray => ({
                        src: this.pictureEx[labelsArray[0]] || 0
                    }));
                } catch (error) {
                    console.log(error);
                    this.initData();
                    this.errorMsg = "Can't find this data";
                    throw error;
                }
            }
        },

        async transformDataToChartObj(featureLabels, features, goldenSamples, valuePRs, featurePrefix) {
            const chartObjects = featureLabels.map((labelsArray, i) => {
                const chartData = {
                    labels: labelsArray.map(label => {
                        const prValue = valuePRs[featurePrefix + label];
                        const prText = prValue ? `PR : ${prValue}` : "PR : Not calculated";
                        return [prText, label];
                    }),
                    datasets: [
                        {
                            label: "Case",
                            backgroundColor: "rgba(244, 67, 54,0.7)",
                            data: labelsArray.map(label => {
                                const featureValue = features[featurePrefix + label];
                                return featureValue ? featureValue : goldenSamples[featurePrefix + label]?.FeatureFieldValueNormal * 1.05;
                            })
                        },
                        {
                            label: "Average",
                            backgroundColor: "rgba(255, 160, 0,0.7)",
                            data: labelsArray.map(label => {
                                return goldenSamples[featurePrefix + label]?.FeatureFieldValueNormal ? goldenSamples[featurePrefix + label].FeatureFieldValueNormal : 0;
                            })
                        }
                    ],
                };
                return {
                    chartData: chartData,
                    chartType: this.chartType[i],
                    featurePr: chartData.labels[0][0],
                    featureName: chartData.labels[0][1],
                    featureValue: chartData.datasets[0].data[0],
                    featureAvgValue: chartData.datasets[1].data[0],
                    chartOptions: {
                        plugins: {
                            title: {
                                display: true,
                                text: this.groupName[i]
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                ticks: {
                                    callback: function (value) {
                                        var characterLimit = 10;
                                        var label = Object.assign([], this.getLabelForValue(value));
                                        if (label[1].length >= characterLimit) {
                                            label[1] = label[1].slice(0, label[1].length).substring(0, characterLimit - 1).trim() + '...';
                                            return label;
                                        }
                                        return label;
                                    }
                                }
                            }
                        }
                    }
                };
            });
            return chartObjects;
        }
    }

}

</script>