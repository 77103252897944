<template>
    <div>
        <cmpCreate :name="menuName"
                   :subPath="menuSubPath"
                   :pkColumn="menuPkColumn"
                   :fields="menuFields"
                   :parent="menuParent"
                   :header="menuHeader"
                   :goBackAfterCreate="menuGoBackAfterCreate"
                   v-model="menuForm"
                   ref="menuCmpCreate">
        </cmpCreate>

        <cmpCreate :name="privilegeName"
                   :subPath="privilegeSubPath"
                   :pkColumn="privilegePkColumn"
                   :fields="privilegeFields"
                   :parent="privilegeParent"
                   :header="privilegeHeader"
                   :goBackAfterCreate="privilegeGoBackAfterCreate"
                   v-model="privilegeForm"
                   ref="privilegeCmpCreate">
        </cmpCreate>
    </div>
</template>

<script>
    import cmpCreate from "@/components/common/cmpCreate";
    import i18n from '@/i18n';
    import ApiService from '@/ApiService';

    export default {
        components: {
            cmpCreate
        },
        data() {
            return {
                menuName: "Tenant",
                menuSubPath: "Menu",
                menuPkColumn: "id",
                menuParent: {},
                menuGoBackAfterCreate: false,
                menuForm: {
                    SiteId: '',
                    ParentMenu: '',
                    MenuName: '',
                    ProgId: ''
                },
                privilegeName: "Tenant",
                privilegeSubPath: "Privilege",
                privilegePkColumn: "id",
                privilegeParent: {},
                privilegeGoBackAfterCreate: false,
                privilegeForm: {
                    SiteId: '',
                    MenuName: '',
                    RoleName: '',
                    Privilege: ''
                }
            };
        },
        computed: {
            menuFields() {
                return [
                    { key: "SiteId", label: i18n.t('Tenant.Menu.SiteId.label'), input: { type: "selectmulti", objectName: "Tenant/Menu/SiteId", idField: "SiteId", displayField: ["SiteId"] }, required: true },
                    { key: "ParentMenu", label: i18n.t('Tenant.Menu.ParentMenu.label'), input: { type: "select", objectName: "Tenant/Menu/MenuName", idField: "MenuName", displayField: ["MenuName"], filterField: "MenuLevel", filterValue: 1 }, required: true },
                    { key: "MenuName", label: i18n.t('Tenant.Menu.MenuName.label'), input: { type: "text" }, required: true },
                    { key: "ProgId", label: i18n.t('Tenant.Menu.ProgId.label'), input: { type: "text" }, required: true },
                ];
            },
            privilegeFields() {
                return [
                    { key: "SiteId", label: i18n.t('Tenant.Menu.SiteId.label'), input: { type: "selectmulti", objectName: "Tenant/Menu/SiteId", idField: "SiteId", displayField: ["SiteId"] }, required: true },
                    { key: "MenuName", label: i18n.t('Tenant.Menu.MenuName.label'), input: { type: "select", objectName: "Tenant/Menu/MenuName", idField: "MenuName", displayField: ["MenuName"] }, required: true },
                    { key: "RoleName", label: i18n.t('Tenant.Menu.RoleName.label'), input: { type: "selectmulti", objectName: "Tenant/Role/RoleName", idField: "RoleName", displayField: ["RoleName"] }, required: true },
                    { key: "Privilege", label: i18n.t('Tenant.Menu.Privilege.label'), input: { type: "text" }, required: true },
                ];
            },
            menuHeader() {
                return i18n.t('Tenant.Menu.Header');
            },
            privilegeHeader() {
                return i18n.t('Tenant.Menu.Privilege.label');
            }
        },
        watch: {
            'menuForm.SiteId': async function (newSiteId) {
                if (newSiteId && newSiteId.length > 0) {
                    const siteIdString = newSiteId.join(','); // �N SiteId array �γr���걵���r��
                    const res = await this.getOptions("Tenant/Menu/MenuName", { SiteId: siteIdString, MenuLevel: 1 });
                    const result = res.Data.map(row => ({ value: row['MenuName'], text: row['MenuName'] }));
                    this.menuForm.ParentMenu = ''; // Clear the existing value before updating options
                    this.$refs.menuCmpCreate.formBind.ParentMenu = result;
                }
            },
            'privilegeForm.SiteId': async function (newSiteId) {
                if (newSiteId && newSiteId.length > 0) {
                    const siteIdString = newSiteId.join(','); // �N SiteId array �γr���걵���r��
                    const menuName = await this.getOptions("Tenant/Menu/MenuName", { SiteId: siteIdString });
                    const menuResult = menuName.Data.map(row => ({ value: row['MenuName'], text: row['MenuName'] }));
                    this.privilegeForm.MenuName = ''; // Clear the existing value before updating options
                    this.$refs.privilegeCmpCreate.formBind.MenuName = menuResult;

                    const roleName = await this.getOptions("Tenant/Role/RoleName", { SiteId: siteIdString });
                    const roleResult = roleName.Data.map(row => ({ value: row['RoleName'], text: row['RoleName'] }));
                    this.privilegeForm.RoleName = ''; // Clear the existing value before updating options
                    this.$refs.privilegeCmpCreate.formBind.RoleName = roleResult;
                }
            }
        },
        methods: {
            getOptions(objectName, data) {
                const api = new ApiService(objectName);
                return api.getSelectOptions(objectName, data);
            }
        }
    };
</script>
