<template>
    <div style="text-align: center">
        <cmpLoading v-if="loading" v-bind:top="20"></cmpLoading>
        <div>
            <span class="pr-2 h3">{{ $t("SensorImpedance.Header.label") }}</span>
        </div>

        <div class="container">

            <div id ="ConnectServerErrorLog" style="text-align: center;margin-bottom:10px;color:red">
                <span v-if="deviceStatus.IsServerConnect === false">
                    {{ $t("SensorImpedance.ConnectServerErrorLog.label") }}
                </span>
            </div>
            <div class="row row-cols-2">
                <div class="col-sm-5" v-if="deviceEnable === 'true'">
                    <div class="row justify-content-center" style="color:red">
                        <span v-if="device.isDeviceConnect === 'error'">
                            {{ $t("SensorImpedance.ConnectDeviceErrorLog.label") }}
                        </span>
                    </div>
                    <div class="row justify-content-center align-items-center mb-3 mt-3">
                        <div class="col-sm-4">
                            <span class="pr-2 h3">{{ $t("SensorImpedance.Helmet.label") }}</span>
                            <span v-if="device.isDeviceConnect === true">
                                <img src="@/assets/images/connect-g.png" width="40" />
                            </span>
                            <span v-else>
                                <img src="@/assets/images/connect-r.png" width="40" />
                            </span>
                        </div>
                        <div class="col-sm-4">
                            <div class="btn9" style="width: 150px" v-on:click.prevent="onStartDeviceManager">
                                {{ $t("SensorImpedance.Connect.label") }}
                            </div>
                            <div class="btn9" style="width: 150px" v-on:click.prevent="onStopDeviceManager">
                                {{ $t("SensorImpedance.Disconnect.label") }}
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-sm-5" v-if="breathStrapEnable === 'true'">
                    <div class="row justify-content-center" style="color:red">
                        <span v-if="device.isBreathStrapConnected === 'error'">
                            {{ $t("SensorImpedance.ConnectBreathStrapErrorLog.label") }}
                        </span>
                        <span v-else-if="device.isBreathStrapConnected === 'Connecting'" style="color: blue">
                            {{ $t("SensorImpedance.Connecting.label") }}
                        </span>
                    </div>

                    <div class="row justify-content-center align-items-center mb-3 mt-3">
                        <div class="col-sm-4">
                            <span class="pr-2 h3">{{ $t("SensorImpedance.BreathingStrap.label") }}</span>
                            <span v-if="device.isBreathStrapConnected === true">
                                <img src="@/assets/images/connect-g.png" width="40" />
                            </span>
                            <span v-else>
                                <img src="@/assets/images/connect-r.png" width="40" />
                            </span>
                        </div>
                        <div class="col-sm-4">
                            <div class="btn9" style="width: 150px" v-on:click.prevent="onBreathStrapConnect">
                                {{ $t("SensorImpedance.Connect.label") }}
                            </div>
                            <div class="btn9" style="width: 150px" v-on:click.prevent="onBreathStrapDisconnect">
                                {{ $t("SensorImpedance.Disconnect.label") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5" v-if="eyeMovementEnable === 'true'">
                    <div class="row justify-content-center align-items-center mb-3 mt-3">
                        <div class="col-sm-4">
                            <span class="pr-2 h3">{{ $t("SensorImpedance.EyeMovement.label") }}</span>
                        </div>
                        <div class="col-sm-4">
                            <div class="btn9" style="width: 150px" v-on:click.prevent="onEyesTracking">
                                {{ $t("SensorImpedance.Edit.label") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5" v-if="ganglionEnable === 'true'">

                    <div class="row justify-content-center" style="color:red">
                        <span v-if="device.isGanglionConnected === 'error'">
                            {{ $t("SensorImpedance.ConnectEEGErrorLog.label") }}
                        </span>
                    </div>
                    <div class="row justify-content-center align-items-start mb-3 mt-3">
                        <div class="col-sm-4">
                            <span class="pr-2 h3">{{ $t("SensorImpedance.Ganglion.label") }}</span>
                            <span v-if="device.isGanglionConnected === true">
                                <img src="@/assets/images/connect-g.png" width="40" />
                            </span>
                            <span v-else>
                                <img src="@/assets/images/connect-r.png" width="40" />
                            </span>
                        </div>
                        <div class="col-sm-4">

                            <div class="btn9" style="width: 150px" v-on:click.prevent="onGanglionConnect">
                                {{ $t("SensorImpedance.Connect.label") }}
                            </div>

                            <div class="btn9" style="width: 150px" v-on:click.prevent="onGanglionDisconnect">
                                {{ $t("SensorImpedance.Disconnect.label") }}
                            </div>

                            <div class="btn9" style="width: 150px" v-on:click.prevent="onImpedanceOnAndOff">
                                {{ $t("SensorImpedance.Start.label") }}
                            </div>
                            <div>
                                <table border="0" align="center" class="table2">
                                    <tr v-for="s in device.ganglionImpedance" v-bind:key="s.name">
                                        <td align="left" valign="top">
                                            <span v-if="s.status === 'on' && device.isGanglionConnected === true">
                                                <img src="@/assets/images/brain-g.png" width="20" />
                                            </span>
                                            <span v-else> <!--s.status === 'off'-->
                                                <img src="@/assets/images/brain-r.png" width="20" />
                                            </span>
                                        </td>
                                        <td align="left">
                                            {{ s.name }}
                                        </td>
                                        <span>{{ s.data }}</span>
                                    </tr>
                                </table>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

            <div class="row justify-content-around">
                <div class="col-sm-5 mb-3">
                    <b-table striped hover :items="rightSensorTableRow" :fields="sensorTableCol"></b-table>
                </div>
                <div class="col-sm-5 mb-3">
                    <b-table striped hover :items="leftSensorTableRow" :fields="sensorTableCol"></b-table>
                </div>
            </div>

            <div class="row justify-content-around">
                <div class="btn9" v-on:click.prevent="onCancel">
                    {{ $t("SensorImpedance.GoBack.label") }}
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.badge-style {
    width: 10px;
}
</style>

<script>
import cmpLoading from "@/components/common/cmpLoading";
import i18n from "@/i18n";
import apiService from "@/ApiService";
import { communicateMixin } from "@/communicateService";

export default {
    data() {
        return {
            loading: false,
            showLogin: false,
            qryModel: {},
            qryCourseDeviceManage: {},

            deviceEnable: false,
            ganglionEnable: false,
            breathStrapEnable: false,
            eyeMovementEnable: false,
            device: {
                deviceId: null,
                device: null,
                batteryLevel: null,
                batteryStatus: null,
                lastCommandTimestamp: null,
                wifiSsid: null,
                isLeftControllerConnected: null,
                isRightControllerConnected: null,
                isDeviceConnect: false,         // 頭盔連線判斷:透過LastCommandTimestamp兩秒內是否有更新值 {true:連線中 , false:無連線 , error:連線異常}                        
                isGanglionConnected: false,     // 腦電連線判斷:收到IsGanglionConnected的Value值 {true:連線中 , false:無連線 , error:連線異常}
                ganglionImpedance: [],          //  GanglionImpedance - 新版 ; impedanceValues - 舊版
                isBreathStrapConnected: false,  // 呼吸綁帶連線判斷:收到IsBreathStrapConnected的Value值 {true:連線中 , false:無連線 , error:連線異常 , Connecting:連線中}
                breathValue: null
            },

            deviceCheckList: ["DeviceId", "Device", "BatteryLevel", "BatteryStatus", "LastCommandTimestamp", "WifiSSID",
                "IsLeftControllerConnected", "IsRightControllerConnected", "IsGanglionConnected", "Connection",
                "GanglionImpedance", "impedanceValues",
                "IsBreathStrapConnected", "BreathValue"
            ],
            model: {},
            sensorTableCol: [],
            sensorTableRow: [],

            rightSensorTableRow: [],
            leftSensorTableRow: [],
            deviceIntervalId: null,
            lastCommandTimestampTmp: null,

        };
    },
    mixins: [communicateMixin],
    components: {
        cmpLoading,
    },
    async created() {
        try {
            this.qryModel = JSON.parse(JSON.stringify(this.$route.query));
            await this.getCourseDeviceManage(this.qryModel.CourseId);
            this.initDeviceValue("All");
            this.initSensorTable();

        } catch (error) {

        }

    },
    async mounted() {
        setTimeout(() => {
            this.deviceStatus.IsServerConnect = !!this.deviceStatus.IsServerConnect;
            this.onStartDeviceManager();  // 畫面啟動後自動觸發頭盔連線
        }, 3000)
    },
    destroyed() {
        let message = {
            CourseType: "StopDeviceManager",
            DeviceId: this.qryModel.DeviceId,
        };
        this.publishMessage(this.qryModel.DeviceId, message);
        this.stopDeviceInterval();
        this.disconnectIot();
    },



    computed: {


    },


    watch: {
        //偵測如果設備斷線，自動清除畫面
        'device.isDeviceConnect'(newVal, oldVal) {
            if (newVal === false || newVal === "error") {
                console.log('isDeviceConnect:', newVal);
                this.clearDeviceValue("Device");
                this.device.isGanglionConnected = false;   // 頭盔斷線，其他裝置連同斷線
                this.device.isBreathStrapConnected = false;// 頭盔斷線，其他裝置連同斷線
                this.setSensorTableValue();
            }
        },

        'device.isGanglionConnected'(newVal, oldVal) {
            if (newVal === false || newVal === "error") {
                console.log('isGanglionConnected:', newVal);
                this.clearDeviceValue("Ganglion");
                this.setSensorTableValue();
            }
        },
        'device.isBreathStrapConnected'(newVal, oldVal) {
            if (newVal === false || newVal === "error") {
                console.log('isBreathStrapConnected:', newVal);
                this.clearDeviceValue("BreathStrap");
                this.setSensorTableValue();
            }
        }
    },


    methods: {
        messageProcessing(data) {
            console.log('received ', data);
            if (data.DeviceId == this.qryModel.DeviceId) {

                this.deviceCheckList.forEach(deviceString => {
                    if (deviceString in data) {
                        if (deviceString === "LastCommandTimestamp") {
                            if (this.device.lastCommandTimestamp !== data[deviceString]) {
                                this.device.isDeviceConnect = true;
                                this.device.lastCommandTimestamp = data[deviceString];
                                if (this.deviceIntervalId === null) {
                                    this.startDeviceInterval();
                                }
                            }
                        }
                        if (deviceString === "GanglionImpedance" || deviceString === "impedanceValues") {
                            this.analyzeGanglionImpedance(data[deviceString]);
                        }
                        else {
                            //因為Device拋送過來的字串第一個字母是大寫，為了js小駝峰式命名，因此將第一個字母轉小寫
                            this.device[deviceString.charAt(0).toLowerCase() + deviceString.slice(1)] = data[deviceString];
                        }
                    }
                });

                this.setSensorTableValue();
            }
        },

        startDeviceInterval() {
            this.deviceIntervalId = setInterval(() => { // 頭盔連線判斷邏輯，"LastCommandTimestamp"值2秒內有無持續更新
                if (this.device.lastCommandTimestamp !== this.lastCommandTimestamptmp) {
                    this.lastCommandTimestamptmp = this.device.lastCommandTimestamp;
                }
                else {
                    this.device.isDeviceConnect = false;
                    this.stopDeviceInterval();
                }
            }, 2000);
        },

        stopDeviceInterval() {
            clearInterval(this.deviceIntervalId);
            this.deviceIntervalId = null;
        },


        async onStartDeviceManager() {
            console.log(this.qryModel.DeviceId + ' : StartDeviceManager');
            let message = {
                CourseType: "StartDeviceManager",
                DeviceId: this.qryModel.DeviceId,
            };
            this.publishMessage(this.qryModel.DeviceId, message);
            this.subscribeIOTChannel(this.qryModel.DeviceId);
            setTimeout(() => {
                if (this.device.isDeviceConnect === false) {
                    this.device.isDeviceConnect = "error"
                }
            }, 5000);
        },

        async onGanglionConnect() {
            console.log(this.qryModel.DeviceId + ' : GanglionConnect');
            let message = {
                CourseType: "GanglionConnect",
                DeviceId: this.qryModel.DeviceId,
            };
            this.publishMessage(this.qryModel.DeviceId, message);

            setTimeout(() => {
                if (this.device.isGanglionConnected === false) {
                    this.device.isGanglionConnected = "error"
                }
            }, 5000);
        },

        async onBreathStrapConnect() {
            console.log(this.qryModel.DeviceId + ' : BreathStrapConnect');
            let message = {
                CourseType: "BreathStrapConnect",
                DeviceId: this.qryModel.DeviceId,
            };
            this.publishMessage(this.qryModel.DeviceId, message);

            this.device.isBreathStrapConnected = 'Connecting';

            setTimeout(() => {
                if (this.device.isBreathStrapConnected === 'Connecting') {
                    this.device.isBreathStrapConnected = "error"
                }
            }, 10000);
        },

        async onStopDeviceManager() {
            console.log(this.qryModel.DeviceId + ' : StopDeviceManager');
            let message = {
                CourseType: "StopDeviceManager",
                DeviceId: this.qryModel.DeviceId,
            };
            this.device.isDeviceConnect = false;
            this.publishMessage(this.qryModel.DeviceId, message);
        },

        async onGanglionDisconnect() {
            console.log(this.qryModel.DeviceId + ' : GanglionDisconnect');
            let message = {
                CourseType: "GanglionDisconnect",
                DeviceId: this.qryModel.DeviceId,
            };
            this.device.isGanglionConnected = false;
            this.publishMessage(this.qryModel.DeviceId, message);
        },

        async onBreathStrapDisconnect() {
            console.log(this.qryModel.DeviceId + ' : BreathStrapDisconnect');
            let message = {
                CourseType: "BreathStrapDisconnect",
                DeviceId: this.qryModel.DeviceId,
            };

            this.device.isBreathStrapConnected = false;
            this.publishMessage(this.qryModel.DeviceId, message);
        },

        async onImpedanceOnAndOff() {
            console.log(this.qryModel.DeviceId + ' : ImpedanceOnAndOff');
            let message = {
                CourseType: "ImpedanceOnAndOff",
                DeviceId: this.qryModel.DeviceId,
            };
            this.publishMessage(this.qryModel.DeviceId, message);
        },

        async onEyesTracking() {
            console.log(this.qryModel.DeviceId + ' : EyesTracking');
            let message = {
                CourseType: "EyesTracking",
                DeviceId: this.qryModel.DeviceId,
            };
            this.publishMessage(this.qryModel.DeviceId, message);
            this.stopDeviceInterval();  // 眼動校對會切換App，頭盔不會發送LastCommandTimestamp，導致認定頭盔斷線，先關閉Interval
        },

        async onCancel() {
            let routerName = this.$root.$data.store.getRouteName("lstMotionData");
            let queryString = {
                ActivityId: this.qryModel.ActivityId,
            };
            let result = { name: routerName };
            result["query"] = queryString;
            this.$router.push(result);
        },

        filterSensorTableRow(half) {
            var filterData = this.sensorTableRow.filter(item => item.visibleFlag === 'true');
            const startIndex = half === 'Right' ? 0 : Math.ceil(filterData.length / 2);
            const endIndex = half === 'Right' ? Math.ceil(filterData.length / 2) : filterData.length;
            return filterData.slice(startIndex, endIndex);
        },

        initSensorTable() {
            this.sensorTableCol =
                [
                    { key: 'deviceStatus', label: i18n.t('SensorImpedance.DeviceStatus.label') },
                    { key: 'deviceValue', label: i18n.t('SensorImpedance.DeviceValue.label') },
                ];
            this.sensorTableRow =
                [
                    { deviceStatus: i18n.t('SensorImpedance.DeviceID.label'), deviceValue: '', visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.DeviceModel.label'), deviceValue: '', visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.BatteryLevel.label'), deviceValue: '', visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.BatteryStatus.label'), deviceValue: '', visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.ConnectWifiName.label'), deviceValue: '', visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.RightHandleConnection.label'), deviceValue: '', visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.LeftHandleConnection.label'), deviceValue: '', visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.BreathValue.label'), deviceValue: '', visibleFlag: this.breathStrapEnable },
                ];

            this.rightSensorTableRow = this.filterSensorTableRow('Right');
            this.leftSensorTableRow = this.filterSensorTableRow('Left');

        },

        setSensorTableValue() {

            this.sensorTableRow =
                [
                    { deviceStatus: i18n.t('SensorImpedance.DeviceID.label'), deviceValue: this.device.deviceId, visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.DeviceModel.label'), deviceValue: this.device.device, visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.BatteryLevel.label'), deviceValue: this.device.batteryLevel === null ? this.device.batteryLevel : this.device.batteryLevel * 100 + '%', visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.BatteryStatus.label'), deviceValue: this.getBatteryStatus(this.device.batteryStatus), visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.ConnectWifiName.label'), deviceValue: this.device.wifiSSID, visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.RightHandleConnection.label'), deviceValue: this.device.isLeftControllerConnected, visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.LeftHandleConnection.label'), deviceValue: this.device.isRightControllerConnected, visibleFlag: this.deviceEnable },
                    { deviceStatus: i18n.t('SensorImpedance.BreathValue.label'), deviceValue: this.device.breathValue, visibleFlag: this.breathStrapEnable },
                ];

            this.rightSensorTableRow = this.filterSensorTableRow('Right');
            this.leftSensorTableRow = this.filterSensorTableRow('Left');
        },

        getBatteryStatus(batteryStatus) {
            const statusLabels = {
                0: 'Unknown',
                1: i18n.t('SensorImpedance.Charging.label'), // Charging
                2: i18n.t('SensorImpedance.Discharging.label'), // Discharging
                3: i18n.t('SensorImpedance.NotCharging.label'), // Not charging
                4: i18n.t('SensorImpedance.ChargingComplete.label') // Charging complete
            };

            if (batteryStatus === null || !(batteryStatus in statusLabels)) {
                return null; // Default value for unknown or null BatteryStatus
            }

            return `${batteryStatus}: ${statusLabels[batteryStatus]}`;
        },


        analyzeGanglionImpedance(data) {
            for (var i = 0; i < this.device.ganglionImpedance.length; i++) {
                this.device.ganglionImpedance[i].data = data[i];
                if (this.device.ganglionImpedance[i].data < 30) {
                    this.device.ganglionImpedance[i].status = "on";
                } else {
                    this.device.ganglionImpedance[i].status = "off";
                }
            }
        },

        async getCourseDeviceManage(courseId) {
            try {
                // Call API to fetch data
                let api = new apiService("Course");
                this.model = await api.get(courseId, i18n.locale);
                this.qryCourseDeviceManage = JSON.parse(this.model['CourseDeviceManage']);

                // If CourseDeviceManage config is not set, default to true
                if (this.qryCourseDeviceManage === null) {
                    this.deviceEnable = 'true';
                    this.ganglionEnable = 'true';
                    this.breathStrapEnable = 'true';
                    this.eyeMovementEnable = 'true';
                }

                for (const parameter of this.qryCourseDeviceManage.Parameter) {
                    this.deviceEnable = parameter.Device;
                    this.ganglionEnable = parameter.Ganglion;
                    this.breathStrapEnable = parameter.BreathStrap;
                    this.eyeMovementEnable = parameter.EyeMovement;
                }

            } catch (error) {
            }
        },

        initDeviceValue(...sensor) {

            if (sensor.includes("Device") || sensor.includes("All")) {
                this.device.isDeviceConnect = false;
                this.clearDeviceValue("Device");
            }

            if (sensor.includes("Ganglion") || sensor.includes("All")) {
                this.device.isGanglionConnected = false;
                this.clearDeviceValue("Ganglion");
            }

            if (sensor.includes("BreathStrap") || sensor.includes("All")) {
                this.device.isBreathStrapConnected = false;
                this.clearDeviceValue("BreathStrap");
            }
        },

        clearDeviceValue(sensor) {

            if (sensor.includes("Device")) {
                this.device.deviceId = null;
                this.device.device = null;
                this.device.batteryLevel = null;
                this.device.batteryStatus = null;
                this.device.lastCommandTimestamp = null;
                this.device.wifiSSID = null;
                this.device.isLeftControllerConnected = null;
                this.device.isRightControllerConnected = null;
            }

            if (sensor.includes("Ganglion")) {
                this.device.ganglionImpedance = [];
                this.device.ganglionImpedance.push({ name: "ch1", status: "off", data: 100 });
                this.device.ganglionImpedance.push({ name: "ch2", status: "off", data: 100 });
                this.device.ganglionImpedance.push({ name: "ch3", status: "off", data: 100 });
                this.device.ganglionImpedance.push({ name: "ch4", status: "off", data: 100 });
                this.device.ganglionImpedance.push({ name: "ref", status: "off", data: 100 });
            }

            if (sensor.includes("BreathStrap")) {
                this.device.breathValue = null;
            }
        },
    },
};
</script>