<template>
    <cmpTableWithMenu v-bind:name="name"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:header="header"
                      v-bind:showBanner="showBanner"
                      v-bind:bannerImage="bannerImage"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    { "key": "CourseName", "label": i18n.t('component.Course.CourseName.label'), sortable: true },
                    { "key": "CourseCode", "label": i18n.t('component.Course.CourseCode.label'), sortable: true },
                    { "key": "CourseType", "label": i18n.t('component.Course.CourseType.label'), sortable: true },
                    { "key": "CourseDesc", "label": i18n.t('component.Course.CourseDesc.label'), sortable: true },
                ]
            },
            qryFields() {
                return [
                    { "key": "CourseName", "label": i18n.t('component.Course.CourseName.label'), input: { type: "text" } },
                    { "key": "CourseType", "label": i18n.t('component.Course.CourseType.label'), input: { type: "text" } },
                    { "key": "CourseCode", "label": i18n.t('component.Course.CourseCode.label'), input: { type: "text" } },
                ]
            },
            bannerImage() {
                //畫面Header 路徑
                return '/images/CourseBanner.jpg';
            },
            header() {
                return i18n.t('component.Course.lstHeader')
            },
            moreTableFuncs() {
                return [
                    {
                        key: "BtnCourseMission", icon: "IcnGo.png", tooltip: i18n.t('component.Course.ToolTip.Mission'), function: { type: 'route', name: "lstMission", key: "CourseId" }
                    },
                    {
                        key: "BtnCourseGoldenSampleFeature", icon: "IcnCourse.png", tooltip: i18n.t('component.Course.ToolTip.GoldenSample'), function: { type: 'route', name: "lstGoldenSampleFeature", key: "CourseId" }
                    },
                ]
            }
        },
        data() {

            return {
                name: "Course",
                pkColumn: "CourseId",
                children: {},
                parent: {},
                showBanner: true,
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete']
            };
        }
    }
</script>