<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"
    import { codeName } from "@/util/constant"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "HardwareDeviceId", "label": i18n.t('component.DeviceTables.HardwareDeviceId.label'), input: { type: "text" }, required: true },
                    { "key": "Password", "label": i18n.t('component.DeviceTables.Password.label'), input: { type: "text" }, required: true },
                ]
            },
            header() {
                return i18n.t('component.DeviceTables.insHeader')
            }
        },
        data() {

            return {
                name: "DeviceTables",
                pkColumn: "DeviceTableId",
                parent: {}
            };
        }
    }
</script>