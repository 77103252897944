<template>
    <div id="contentPublic">
        <cmpLoading v-if="loading"></cmpLoading>
        <cmpSearch :key="pkColumn"
                   @submit="searchSubmit"
                   @cancel="searchCancel"
                   :qryFields="qryFields">
        </cmpSearch>

        <div v-if="errorMsg" class="mt-3 row justify-content-center">
            <p class="text-danger">{{ errorMsg }}</p>
        </div>

        <div v-if="tablesCol.length > 0" class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10">
                    <div class="ag-theme-alpine" style="height: 50%;">
                        <b-table responsive sticky-header outlined
                                 :items="tablesRow" :fields="tablesCol"
                                 class="w-100"
                                 head-variant="light"></b-table>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
    import cmpSearch from "@/components/common/cmpSearch";
    import cmpLoading from "@/components/common/cmpLoading";
    import ApiService from "@/ApiService";
    import i18n from "@/i18n";

    export default {
        components: {
            cmpSearch,
            cmpLoading
        },
        data() {
            return {
                name: "cloudWatchReport",
                pkColumn: "cloudWatchLog",
                header: "cloudWatchReport",
                loading: false,
                qryFields: [],
                functions: [],
                queryResult: [],
                characterLimit: 1000,
                errorMsg: "",
                tablesCol: [],
                tablesRow: []
            };
        },
        created() {
            this.runListFunction();
        },
        methods: {
            async searchSubmit(queryModel) {
                this.loading = true;
                this.resetData();
                this.runSelectedFunction(queryModel);
                this.loading = false;
            },
            searchCancel() {
                const routerName = this.$root.$data.store.getRouteName("loggedinHome");
                this.$router.push({ name: routerName });
            },
            resetData() {
                this.startDate = [];
                this.endDate = [];
                this.logLevel = "";
                this.statusCode = "";
                this.message = "";
                this.errorMsg = "";
                this.tablesRow = [];
                this.tablesCol = [];
            },
            initializeQryFields() {
                this.qryFields = [
                    { key: "logGroups", label: i18n.t('Tenant.CloudWatchLog.LogGroups.label'), input: { type: "select", objectName: "Custom", customValue: this.functions } },
                    { key: "searchDate", label: i18n.t('Tenant.CloudWatchLog.SearchDate.label'), input: { type: "datetime" } },
                    { key: "logLevel", label: i18n.t('Tenant.CloudWatchLog.LogLevel.label'), input: { type: "text" }, defaultValue: "ERROR" },
                    { key: "statusCode", label: i18n.t('Tenant.CloudWatchLog.StatusCode.label'), input: { type: "text" } },
                    { key: "message", label: i18n.t('Tenant.CloudWatchLog.Message.label'), input: { type: "text" } }
                ];
                this.pkColumn = "logGroups"; // Used to update cmpSearch
            },
            runSelectedFunction(query) {
                this.queryResult = query;
                this.getCloudWatchLog();
            },
            async runListFunction() {
                await this.getAllLogGroups();
                this.initializeQryFields();
            },
            async getCloudWatchLog() {
                try {
                    if (!this.queryResult['logGroups'] || this.queryResult['logGroups'] === "") throw new Error("logGroupName must be selected!");

                    const api = new ApiService("Admin");
                    const requestData = {
                        logGroupName: this.queryResult['logGroups'],
                        statusCode: this.queryResult['statusCode'],
                        logLevel: this.queryResult['logLevel'],
                        message: this.queryResult['message'],
                        startDate: this.queryResult['searchDateMin'],
                        endDate: this.queryResult['searchDateMax']
                    };

                    const log = await api.getAll(requestData, 'cloudWatchLog');
                    if (log.length === 0) throw new Error("log not found.");

                    await this.setTable(log);
                } catch (error) {
                    this.resetData();
                    this.errorMsg = `Error: ${error.message}`;
                }
            },
            async getAllLogGroups() {
                try {
                    const api = new ApiService("Admin");
                    const list = await api.getAll("", 'logGroups');
                    this.functions = list.map(item => ({ text: item, value: item }));
                } catch (error) {
                    this.resetData();
                    this.errorMsg = "Can't find LogGroups";
                }
            },
            async setTable(model) {
                try {
                    const parsedModel = model.map(JSON.parse);
                    if (!Array.isArray(parsedModel)) throw new Error('Parsed model is not an array.');

                    const keys = Object.keys(parsedModel[0]);
                    this.tablesCol = keys.map(key => ({ headerName: key, field: key }));

                    this.tablesRow = parsedModel.map(item => {
                        return keys.reduce((row, key) => {
                            row[key] = item[key] && typeof item[key] === 'string' && item[key].length > this.characterLimit
                                ? item[key].substring(0, this.characterLimit).trim() + '...' : item[key];
                            return row;
                        }, {});
                    });
                } catch (error) {
                    console.error('Error processing model:', error);
                }
            }
        }
    };
</script>
