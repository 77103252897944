<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "RoleId", "label": i18n.t('component.Privilege.RoleId.label'), input: { type: "textboxSearch", objectName: "Role", idField: "RoleId", displayField: "RoleName", editDisplayField: "RoleName", qryFields: ["RoleName"], displayFields: ["RoleId", "RoleName"] } },
                    { "key": "ObjectName", "label": i18n.t('component.Privilege.ObjectName.label'), input: { type: "text" } },
                    { "key": "ObjectId", "label": i18n.t('component.Privilege.ObjectId.label'), input: { type: "text" } },
                    { "key": "RightCreate", "label": i18n.t('component.Privilege.RightCreate.label'), input: { type: "boolean" } },
                    { "key": "RightUpdate", "label": i18n.t('component.Privilege.RightUpdate.label'), input: { type: "boolean" } },
                    { "key": "RightDelete", "label": i18n.t('component.Privilege.RightDelete.label'), input: { type: "boolean" } },
                    { "key": "RightRead", "label": i18n.t('component.Privilege.RightRead.label'), input: { type: "boolean" } },
                    { "key": "RightImport", "label": i18n.t('component.Privilege.RightImport.label'), input: { type: "boolean" } },
                    { "key": "RightExport", "label": i18n.t('component.Privilege.RightExport.label'), input: { type: "boolean" } },
                    { "key": "RightPrint", "label": i18n.t('component.Privilege.RightPrint.label'), input: { type: "boolean" } }
                ]
            },
            header() {
                return i18n.t('component.Privilege.insHeader')
            }
        },
        data() {

            return {
                name: "Privilege",
                pkColumn: "PrivilegeId",
                parent: {}
            };
        }
    }
</script>