<template>
    <cmpTableWithMenu v-bind:name="name" 
        v-bind:pkColumn="pkColumn"
        v-bind:fields="fields" 
        v-bind:qryFields="qryFields" 
        v-bind:parent="parent"
        v-bind:header="header"
        v-bind:tableFuncs="tableFuncs"></cmpTableWithMenu>
</template>

<script>
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"
    import i18n from '@/i18n'
    import { codeName } from "@/util/constant"//�`��libaray

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    { "key": "ActivityId", "label": i18n.t('component.ActivityLog.ActivityId.label'), sortable: true },
                    { "key": "ActivityTitle", "label": i18n.t('component.ActivityLog.ActivityTitle.label'), sortable: true },
                    { "key": "LogTime", "label": i18n.t('component.ActivityLog.LogTime.label'), sortable: true, type: "datetime" },
                    { "key": "RespondentName", "label": i18n.t('component.ActivityLog.RespondentName.label'), sortable: true },
                    { "key": "MotionDataId", "label": i18n.t('component.ActivityLog.MotionDataId.label'), sortable: true },
                    { "key": "ActivityLogStageName", "label": i18n.t('component.ActivityLog.ActivityLogStage.label'), sortable: true },
                    { "key": "StageType", "label": i18n.t('component.ActivityLog.StageType.label') },
                    { "key": "ActivityLogStatusName", "label": i18n.t('component.ActivityLog.ActivityLogStatus.label') },                    
                    { "key": "DeviceId", "label": i18n.t('component.ActivityLog.DeviceId.label'), sortable: true},

                ]
            },
            qryFields() {
                return [
                    { "key": "ActivityId", "label": i18n.t('component.ActivityLog.ActivityId.label'), input: { type: "text" } },                
                    { "key": "RespondentName", "label": i18n.t('component.ActivityLog.RespondentName.label'), input: { type: "text" } },
                    { "key": "MotionDataId", "label": i18n.t('component.ActivityLog.MotionDataId.label'), input: { type: "text" } },
                    { "key": "ActivityLogStage", "label": i18n.t('component.ActivityLog.ActivityLogStage.label'), input: { type: "select", objectName: "CodeDetail", idField: "CodeNo", displayField: ["CodeDisplay"], filterField: "CodeName", filterValue: codeName.ActivityLogStage } },

                ]
            },
            header() {
                return i18n.t('component.ActivityLog.lstHeader')
            },
            parent() {
                return {
                    "name": "Activity",
                    "key": "ActivityId",
                    "dataHeaders": [
                        { "key": "ActivityTitle", "label": i18n.t('component.ActivityLog.ActivityTitle.label') }
                    ]
                }
            }
        },
        data() {
            return {
                name: "ActivityLog",
                pkColumn: "ActivityLogId",
                children: {},
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete']
            };
        }
    }
</script>