<template>
    <cmpTableWithMenu v-bind:name="name"
                      v-bind:pkColumn="pkColumn"
                      v-bind:fields="fields"
                      v-bind:qryFields="qryFields"
                      v-bind:children="children"
                      v-bind:parent="parent"
                      v-bind:header="header"
                      v-bind:tableFuncs="tableFuncs"
                      v-bind:moreTableFuncs="moreTableFuncs"></cmpTableWithMenu>
</template>

<script>
    import i18n from '@/i18n'
    import cmpTableWithMenu from "@/components/common/cmpTableWithMenu"

    export default {
        components: {
            cmpTableWithMenu
        },
        computed: {
            fields() {
                return [
                    { "key": "Operation", "label": i18n.t('component.operation'), tdClass: 'text-center', thClass: 'text-center' },
                    { "key": "No", "label": "No." },
                    //{ "key": "CourseName", "label": i18n.t('component.Mission.CourseName.label'), sortable: true },
                    { "key": "MissionCode", "label": i18n.t('component.Mission.MissionCode.label'), sortable: true },
                    { "key": "MissionName", "label": i18n.t('component.Mission.MissionName.label'), sortable: true },
                    { "key": "MissionParameter", "label": i18n.t('component.Mission.MissionParameter.label'), sortable: true, style:"max-width:600px;word-wrap:break-word;"},
                ]
            },
            qryFields() {
                return [
                    { "key": "MissionCode", "label": i18n.t('component.Mission.MissionCode.label'), input: { type: "text" } },
                    { "key": "MissionName", "label": i18n.t('component.Mission.MissionName.label'), input: { type: "text" } },
                ]
            }
            ,
            header() {
                return i18n.t('component.Mission.lstHeader')
            },
            parent() {
                return {
                    "name": "Course",
                    "key": "CourseId",
                    "dataHeaders": [
                        { "key": "CourseCode", "label": i18n.t('component.Course.CourseCode.label') },
                        { "key": "CourseName" },
                        { "key": "CourseType", "label": i18n.t('component.Course.CourseType.label') },
                        { "key": "CourseTypeDescription" },
                    ]
                }
            }
        },
        data() {

            return {
                name: "Mission",
                pkColumn: "MissionId",
                children: {},
                tableFuncs: ['rec', 'copyNew', 'edit', 'delete'],
                moreTableFuncs: []
            };
        }
    }
</script>