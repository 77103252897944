<template>
    <div id="content_public_index" :style="{ backgroundImage: `url(${require('@/' + backgroundImagePath)})` }">

    </div>
</template>


<script>
    export default {
        data() {
            return {
                backgroundImagePath: ""
            };
        },
        async created() {
            let imagePath = await this.$root.$data.store.getImagePath();
            //let selectedRole = this.$root.$data.store.getSelectedRole();
            //this.backgroundImagePath = `${imagePath}/Home21-${selectedRole}.jpg`;
            this.backgroundImagePath = `${imagePath}/Home21.jpg`;

        }

    }
</script>
