<template>
    <cmpCreate v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpCreate>
</template>

<script>
    import i18n from '@/i18n'
    import cmpCreate from "@/components/common/cmpCreate"
    import { codeName } from "@/util/constant"

    export default {
        components: {
            cmpCreate
        },
        computed: {
            fields() {
                return [
                    { "key": "CourseId", "label": i18n.t('component.GoldenSampleFeature.CourseName.label'), input: { type: "textboxSearch", objectName: "Course", idField: "CourseId", displayField: "CourseName", editDisplayField: "CourseName", qryFields: ["CourseCodeOr", "CourseNameOr"], displayFields: ["CourseCode", "CourseName"] } },
                    { "key": "FeatureFieldName", "label": i18n.t('component.GoldenSampleFeature.FeatureFieldName.label'), input: { type: "text" }, required: true },
                    { "key": "FeatureFieldValue", "label": i18n.t('component.GoldenSampleFeature.FeatureFieldValue.label'), input: { type: "number" } },
                    { "key": "FeatureFieldValueNormal", "label": i18n.t('component.GoldenSampleFeature.FeatureFieldValueNormal.label'), input: { type: "number" } },
                    { "key": "FeatureFieldValueAbnormal", "label": i18n.t('component.GoldenSampleFeature.FeatureFieldValueAbnormal.label'), input: { type: "number" } },
                    { "key": "FeatureFieldSorting", "label": i18n.t('component.GoldenSampleFeature.FeatureFieldSorting.label'), input: { type: "number" } }
                ]
            },
            header() {
                return i18n.t('component.GoldenSampleFeature.insHeader')
            }
        },
        data() {

            return {
                name: "GoldenSampleFeature",
                pkColumn: "GoldenSampleFeatureId",
                parent: {}
            };
        }
    }
</script>