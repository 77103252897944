<template>
    <div style="width:80%; margin:0 auto; text-align:center">
        <div style="text-align:center">
            <div id="webpath">{{$t('component.ActivityParticipantMulti.insHeader')}}</div>
            <cmpLoading v-if="loading"></cmpLoading>

        </div>

        <br />
        <hr />
        <div style="text-align: center">
            <div style="display:inline-block;" class="align-top">
                <cmpEditTable v-bind:name="name" v-bind:id="`activity`"
                              v-bind:fields="fields"
                              v-bind:getAllUrlPath="getAllUrlPath"
                              v-bind:showHeader="false"
                              v-bind:showFooter="false"
                              ref="activityTable"></cmpEditTable>
            </div>
            <div style="display: inline-block; margin-left:100px" class="align-top">
                <cmpEditTable v-bind:name="missionName" v-bind:id="`mission`"
                              v-bind:fields="missionFields"
                              v-bind:getAllUrlPath="missionGetAllUrlPath"
                              v-bind:showHeader="false"
                              v-bind:showFooter="false"
                              ref="missionTable"></cmpEditTable>
            </div>
        </div>
      
        <br /><br />
        <br /><br />
        <div style="width:100%; text-align:center; display:inline-block">
            <div class="btn9" @click.prevent="submitForm">
                ✔ {{ $t('component.ActivityParticipantMulti.Submit.label') }}
            </div>
            <div class="btn9" v-if="parent && Object.keys(parent).length > 0" href="#" @click.prevent="$router.go(-1)">
                ✘ {{ $t('component.ActivityParticipantMulti.GoBack.label') }}
            </div>
        </div>
        <br /><br />
        <br /><br />
    </div>
</template>

<script>
    import i18n from '@/i18n'
    import cmpEditTable from "@/components/common/cmpEditTable"
    import ApiService from '@/ApiService';
    import cmpLoading from "@/components/common/cmpLoading"

    const apiActivity = new ApiService("Activity");

    export default {
        components: {
            cmpEditTable,
            cmpLoading
        },
        data() {

            return {
                fields: [
                    { "key": "UserName", "label": i18n.t('component.ActivityParticipantMulti.UserName.label'), input: { type: "readonly" } },
                    //{ "key": "DeviceId", "label": i18n.t('component.ActivityParticipantMulti.DeviceId.label'), input: { type: "text" } },
                    { "key": "DeviceId", "label": i18n.t('component.ActivityParticipantMulti.DeviceId.label'), input: { type: "select", objectName: "DeviceTables", idField: "DeviceId", displayField: ["DeviceId"] } },
                    { "key": "ActivityId", "label": "", input: { type: "hidden" } }
                ],
                getAllUrlPath: "multi",
                missionName: "Mission",
                missionGetAllUrlPath: "multi",
                missionFields: [
                    { "key": "MissionName", "label": i18n.t('component.ActivityParticipantMulti.Mission.label'), input: { type: "readonly" } },
                    { "key": "MissionId", "label": "", input: { type: "hidden" } }
                    //{ "key": "CourseId", "label": "", input: { type: "hidden" } }
                ],
                parent: { "name": "ActivityParticipant", "key": "ActivityId" },
                classModel: {},
                loading: false,
                name: "ActivityParticipant"
            };
        },
        async created() {
            this.getAll()
        },

        methods: {
            async getAll() {
                const activityId = this.$route.query.ActivityId;
                if (activityId) {
                    this.classModel = await apiActivity.get(activityId);
                }
            },
            async submitForm(event) {

                // Insert/Update ActivityParticipant and mission
                let actParticipants = this.$refs.activityTable.getSelected();
                let missions = this.$refs.missionTable.getSelected();

                let motionDataReq = [];
                let actParticipantReq = [];
                for (const ap of actParticipants) {
                    for (const m of missions) {
                        motionDataReq.push({
                            MissionId: m.MissionId,
                            UserId: ap.UserId,
                            CourseId: m.CourseId,
                            ActivityId: ap.ActivityId,
                            DeviceId: ap.DeviceId,
                            StartingTime: new Date(new Date().getTime())
                        });
                    }
                    

                    actParticipantReq.push({
                        Name: ap.Name,
                        SeatId: ap.SeatId,
                        ActivityId: ap.ActivityId,
                        UserId: ap.UserId

                    });
                }

                let request = {
                    TblactivityparticipantList: actParticipantReq,
                    TblmotiondataList: motionDataReq
                };
                
                event.target.classList.add('disabled-link');

                var api = new ApiService('ActivityParticipant');
                

                try {
                    await api.create(request, 'multinew')
                }
                finally {
                    event.target.classList.remove('disabled-link');
                }

                this.$router.go(-1);
            }

        }
    }
</script>