<template>
    <div id="content_public_index" :style="{ backgroundImage: `url(${require('@/' + backgroundImagePath)})` }">
        contact us
    </div>
</template>


<script>
    export default {
        data() {
            return {
                backgroundImagePath: ""
            };
        },
        async created() {
            let imagePath = await this.$root.$data.store.getImagePath();
            this.backgroundImagePath = `${imagePath}/Home111.jpg`;
        }

    }
</script>
